
import { Button, Table } from "reactstrap";
import { Link } from "react-router-dom";
import { monthList } from "constants/ticketConstants";
import React, { useState } from "react";
import Moment from "react-moment";
import EditDailyTransactionModal from "views/Modal/Daily/EditDailyTransactionModal";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function DailyCoinReceipt(props) {
    const [displayTransactionModal, setDisplayTransactionModal] = useState(false)
    const [dailyDate, setDailyDate] = useState(moment('YYYY-MM-DD'))
    const showEditTransactionModal = (date) => {
        setDisplayTransactionModal(true)
        setDailyDate(date)
    }
    const toggleEdit = () => {
        setDisplayTransactionModal(!displayTransactionModal);
    };
    console.log(props.missingDepositItems)
    return props.data.receipt.length > 0 ? (
        <>
            <div>
                <h4 style={{display:'inline'}}>Coin Receipt</h4> [
                <Link
                    to={{
                        pathname: "/portal/daily/historical-chart",
                        state: { type: "Receipt" }
                    }}
                >
                    Historical Chart
                </Link>
                ]
            </div>
            <Table className="daily-table" bordered striped responsive="sm">
                <thead>
                <tr>
                    <th>Date</th>
                    <th>#</th>
                    <th>Check</th>
                    <th>Cash</th>
                    <th>Total</th>
                   {/* <th>Profit</th> */}
                {(props.data.view.receipt === "day" && props.userType.toLowerCase() === "admin") && (
                    <>
                        <th>Change</th>
                        <th className="text-center">Change<br/>Ret.</th>
                        <th className="text-center">Dep.<br/>Done</th>
                        <th className="text-center" style={{width: '15%'}}>Dep.<br/>Date</th>
                    </>
                )}
                <th className="text-center"></th>
                </tr>
                </thead>
                <tbody>
                {props.data.receipt.map((tic, i) => {
                    let editDepositDateField = tic.editDepositDateField || false
                    let ticTimestamp = tic.timeStamp;
                    if (tic.type === "month") {
                    ticTimestamp = monthList.indexOf(ticTimestamp);
                    }
                    return (
                    <tr key={i}>
                        <td>
                        <a
                            href={
                            (tic.type === "year")
                            ? `/portal/daily/${tic.timeStamp}?dataType=receipt`
                            : (tic.type === "month")
                                ? `${props.location.pathname}/${monthList.indexOf(tic.timeStamp)}?dataType=receipt`
                                : `/portal/receipts?date=${props.year}/${monthList.indexOf(props.timeStamp)}/${tic.timeStamp}`}
                            className="delete-item">{ticTimestamp}</a>
                        </td>
                        <td>{tic.totalCount}</td>
                        <td className="text-center">${tic.checkAmount.toFixed(2).replace('.00', '')}</td>
                        <td className="text-center">${tic.cashAmount.toFixed(2).replace('.00', '')}</td>
                        <td className="text-center">
                            ${tic.amount.toFixed(2).replace('.00', '')}
                        </td>
                        {/* <td className="text-center">${tic.profit}</td> */}
                        {(tic.type === "day" && props.userType.toLowerCase() === "admin") && (
                            <>
                                <td className="text-center">${tic.change}</td>
                                <td style={{verticalAlign: 'middle'}} className="text-center">
                                <input
                                    id={"changeReturned" + i}
                                    type="checkbox"
                                    checked={tic.changeReturned}
                                    className="db-sized-checkbox"
                                    onChange={(e) => props.checkboxHandler(e, tic.dailyDate, "changeReturned", "receipt")}
                                />
                                <small className="ml-2"></small>
                                </td>
                                <td
                                    style={{verticalAlign: 'middle'}}
                                    className="text-center">
                                    <input
                                    id={"depositDone" + i}
                                    type="checkbox"
                                    checked={tic.depositDone}
                                    className="db-sized-checkbox"
                                    onChange={(e) => props.checkboxHandler(e, tic.dailyDate, "depositDone", "receipt")}
                                    />
                                    <small className="ml-2"></small>
                                </td>
                                <td
                                    style={{verticalAlign: 'middle'}}
                                    className="text-center">
                                        {tic.depositDone ? (
                                            <>
                                                {editDepositDateField ? (
                                                    <DatePicker
                                                        autoFocus={true}
                                                        selected={tic.depositDate.toString().length === 10
                                                            ? new Date(tic.depositDate * 1000)
                                                            : new Date(tic.depositDate)}
                                                            onChange={(e) => {
                                                                props.updateRow({
                                                                    depositDate: moment(e).unix(),
                                                                    type: "receipt",
                                                                    date: tic.dailyDate,
                                                                    depositDone: tic.depositDone
                                                                })
                                                            }}/>
                                                ) : (
                                                    <Moment
                                                        format="MM/DD/YY"
                                                        tz="America/Los_Angeles"
                                                        onClick={(e) => {
                                                            if (e.detail == 2) {// db click
                                                                let _dailyData = props.data
                                                                _dailyData.receipt[i].editDepositDateField = true
                                                                props.setDailyData({ ... _dailyData })
                                                            }
                                                        }}>
                                                        {tic.depositDate.toString().length === 10
                                                        ? new Date(tic.depositDate * 1000)
                                                        : new Date(tic.depositDate)}
                                                    </Moment>
                                                )}
                                            </>
                                        ) : ((props.missingDepositItems.length > 1
                                                && tic.dailyDate == props.missingDepositItems[props.missingDepositItems.length-1].dailyDate)
                                             ? (
                                                <Button
                                                    id="confirm-btn"
                                                    style={{backgroundColor: 'black', color: 'white', border: 'none'}}
                                                    onClick={(e) => {
                                                        let dates = props.missingDepositItems.map(v=>v.dailyDate)
                                                        if (window.confirm('Are you sure that you want proceed deposit for: ' + dates.join(', '))) {
                                                            props.updateRows(props.missingDepositItems, false)
                                                        }
                                                    }}
                                                    size="sm">BATCH</Button>
                                             ) : '-')}
                                </td>
                                <td
                                    style={{verticalAlign: 'middle'}}
                                    className="text-center">
                                    {tic.cashAmount > 0 && (
                                        <Button
                                            id="confirm-btn"
                                            style={{backgroundColor: 'black', color: 'white', border: 'none'}}
                                            onClick={(e) => {
                                                showEditTransactionModal(tic.dailyDate)
                                            }}
                                            size="sm">$</Button>
                                    )}
                                </td>
                            </>
                        )}
                    </tr>
                    );
                })}
                </tbody>
            </Table>
            {displayTransactionModal && (
                <EditDailyTransactionModal
                    {...props}
                    show={displayTransactionModal}
                    date={dailyDate}
                    toggle={toggleEdit}
                    />
            )}
        </>
    ) : "";
}

export default DailyCoinReceipt;
