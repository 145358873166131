import {
    FETCH_QUOTE_LISTING_FAILURE,
    FETCH_QUOTE_LISTING_SUCCESS,
    SAVE_QUOTE_FORM_DETAILS,
    SAVE_QUOTE_ITEMS_DETAILS,
    SAVE_ITEMS_DETAILS_IN_FORM,
    RESET_SAVE_QUOTE_DETAILS,
    RESET,
} from "./quoteType";
  
const initialState = {
    quotes: {
        data: [],
        count:0,
        page:0,
        error: null
    },
    quoteFormDetails: {
        clerk: "",
        customerName: "",
        contactValue: "",
        contactMethod: "text",
        status: 'open'
    },
    quoteItemsDetails: {
        items: [],
        count: 0,
    }
};
  
const reducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_QUOTE_LISTING_SUCCESS:
        return {
            ...state,
            quotes: {
                data: action.payload.data,
                page: action.payload.page,
                count: action.payload.count,
                error: null,
            }
        };
      case FETCH_QUOTE_LISTING_FAILURE:
        return {
          ...state,
          quotes: {
            data: [],
            count:0,
            page:0,
          },
          error: action.payload,
        };
        case SAVE_QUOTE_FORM_DETAILS:
            return {
              ...state,
              quoteFormDetails: {
                ...state.quoteFormDetails,
                [action.payload.name]: action.payload.value,
              },
            };
      
          case SAVE_QUOTE_ITEMS_DETAILS:
            return {
              ...state,
              quoteItemsDetails: {
                items: action.payload,
                count: action.payload.length,
              },
            };
          case RESET_SAVE_QUOTE_DETAILS:
            return {
                ...state,
                quoteFormDetails: {
                    clerk: "",
                    customerName: "",
                    contactValue: "",
                    contactMethod: "text",
                    status: 'open'
                },
                quoteItemsDetails: {
                    items: [],
                    count: 0,
                }
              };
      case RESET:
        return initialState;
      default:
        return state;
    }
};
  
export default reducer;
  