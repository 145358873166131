const coinInvoiceTypes = [
  { value: "bag", label: "Bag" },
  { value: "box", label: "Box" }
],
paymentOptions = [
  { value: "cash", label: "Cash" },
  { value: "check", label: "Check" },
  { value: "barter", label: "Barter" },
  { value: "wire", label: "Wire" }
],
metroPaymentOptions = [
  { value: "cash", label: "Cash" },
  { value: "check", label: "Check" },
  { value: "wire", label: "Wire" }
], billedToOptions = {
  'ron': "Ron\'s Jewelry International Inc.",
  'dayan': "H. Dayan",
  'john': "Las Vegas Coin Company LLC",
  'igor': "Alternative For Women",
  'scott': "LV Collectibles",
  'bella': 'Bella Diamonte',
  'imj trading': "IMJ Trading",
  'dave': 'Williams Gold And Silver',
  'mario': 'Clark County Public Auction'
}, monthOptions = [
  { value: "1", label: "Jan" },
  { value: "2", label: "Feb" },
  { value: "3", label: "Mar" },
  { value: "4", label: "Apr" },
  { value: "5", label: "May" },
  { value: "6", label: "Jun" },
  { value: "7", label: "Jul" },
  { value: "8", label: "Aug" },
  { value: "9", label: "Sep" },
  { value: "10", label: "Oct" },
  { value: "11", label: "Nov" },
  { value: "12", label: "Dec" }
], monthFullOptions = [
  { value: "1", label: "January" },
  { value: "2", label: "February" },
  { value: "3", label: "March" },
  { value: "4", label: "April" },
  { value: "5", label: "May" },
  { value: "6", label: "June" },
  { value: "7", label: "July" },
  { value: "8", label: "August" },
  { value: "9", label: "September" },
  { value: "10", label: "October" },
  { value: "11", label: "November" },
  { value: "12", label: "December" }
], dayOptions = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31],
paidOptions = [
  { value: 1, label: "Paid" },
  { value: 0, label: "Unpaid" }
],
printCopyOptions = [1,2,3,4,5,6,7,8,9,10],
monthList = [
  "",
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
],
hourOptions = ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'],
minuteOptions = [ '00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31', '32', '33', '34', '35', '36', '37', '38', '39', '40', '41', '42', '43', '44', '45', '46', '47', '48', '49', '50', '51', '52', '53', '54', '55', '56', '57', '58', '59'],
paymentOptionsCoinReceipt = [
  { value: "cash", label: "Cash" },
  { value: "check", label: "Check" }
],
referTypeOptions = [
  { value: "fromx", label: "Refer" },
  { value: "zip", label: "Zip" },
  { value: "keyword", label: "Keyword" }
],
itemTypeOptions = [
  { value: "coin", label: "Coin" },
  { value: "metro", label: "Metro" },
],
quoteStatusOptions = [
  { value: "open", label: "Open" },
  { value: "closed", label: "Closed" },
],
paymentOptionsReceipt = [
  { value: "cash", label: "Cash" },
  { value: "creditcard", label: "Credit Card" },
],
paymentCreditCardOptions = ["creditcard", "cash_creditcard"],
paymentCashOptions = ["cash", "cash_creditcard"],
jewelryPaymentOptionsReceipt = [
  { value: "cash", label: "Cash" },
  { value: "creditcard", label: "Credit Card" },
  { value: "check", label: "Check" },
  { value: "cash_creditcard", label: "Cash & Credit Card" },
],
jewelryReceiptPaymentTypes = [
  { value: "full", label: "Full" },
  { value: "partial", label: "Partial" }
],
sexOptions = [
  { value: "M", label: "Male" },
  { value: "F", label: "Female" },
],
eyesOptions = [
  { value: "Bro", label: "Bro" },
  { value: "Blu", label: "Blu" },
  { value: "Blk", label: "Blk" },
  { value: "Grn", label: "Grn" },
  { value: "Gry", label: "Gry" },
  { value: "Haz", label: "Haz" },
],
hairOptions = [
  { value: "Bro", label: "Bro" },
  { value: "Aub", label: "Aub" },
  { value: "Blk", label: "Blk" },
  { value: "Sil", label: "Sil" },
  { value: "Red", label: "Red" },
  { value: "Bln", label: "Bln" },
  { value: "Dbr", label: "Dbr" },
  { value: "Lbr", label: "Lbr" },
],
displayOrderTypeOptions = [
  { value: "", label: "Metro"},
  { value: "Coin", label: "Coin"},
  { value: "Receipt", label: "Coin Receipt" },
  { value: "JewelryReceipt", label: "Jewelry Receipt"}
],
raceOptions = [
  { value: "", label: "None" },
  { value: "W", label: "White" },
  { value: "B", label: "Black" },
  { value: "A", label: "Asian" },
  { value: "H", label: "Hispanic" },
  { value: "I", label: "Indian" },
  { value: "O", label: "Other" },
  { value: "U", label: "Unknown" },
],
boothOptions = [
  { value: "", label: "" },
  { value: "booth 1", label: "Booth 1" },
  { value: "booth 2", label: "Booth 2" },
  { value: "booth 3", label: "Booth 3" },
  { value: "booth 4", label: "Booth 4" },
  { value: "booth 5", label: "Booth 5" },
  { value: "booth 7", label: "Booth 7" },
  { value: "booth 8", label: "Booth 8" },
  { value: "booth 9", label: "Booth 9" },
  { value: "coin counter", label: "Coin Counter" }
],
stationOptions = [
  // { value: "", label: "None" },
  { value: "station1", label: "Station 1" },
  { value: "station2", label: "Station 2" },
  { value: "station3", label: "Station 3" },
  { value: "station4", label: "Station 4" },
  { value: "station5", label: "Station 5" },
  { value: "station6", label: "Station 6" },
  { value: "__coinShow", label: "Coin Show" },
],
copyToData = [
  { value: "ticket", label: "Ticket" },
  { value: "coin", label: "NewCoin" },
],
fromOptions = [
  { value: "", label: "None" },
  { value: "repeat", label: "Repeat" },
  { value: "signs", label: "Signs" },
  { value: "internet google", label: "Internet Google" },
  { value: "yelp", label: "Yelp" },
  { value: "facebook", label: "Facebook" },
  { value: "BBB", label: "BBB" },
  { value: "zip code mag.", label: "Zip code mag." },
  { value: "Best Of Vegas", label: "Best Of Vegas" },
  { value: "refferal", label: "Refferal" },
  { value: "tv genric", label: "TV genric" },
  { value: "channel 3", label: "Channel 3" },
  { value: "channel 5", label: "Channel 5" },
  { value: "channel 8", label: "Channel 8" },
  { value: "channel 13", label: "Channel 13" },
  { value: "channel 13 morning blend", label: "Channel 13 morning Blend" },
  { value: "bus stop", label: "Bus Stop" },
  { value: "chinese newspaper", label: "Chinese Newspaper" },
  { value: "coin show", label: "Coin Show" },
  { value: "max pawn", label: "Max Pawn" },
  { value: "craigslist", label: "Craigslist" },
  { value: "anthem", label: "Anthem" },
  { value: "citibank", label: "citibank" },
  { value: "am/pm", label: "am/pm" },
  { value: "Wayne Root Radio", label: "Wayne Root Radio" }
],
itemType = ["3 Jewelry", "10 Misc"],
itemMaterial = [
  "Gold 10k",
  "Gold 14k",
  "Gold 18k",
  "Gold 22k",
  "Gold Filled",
  "Sterling Silver",
  "Silver Plate",
  "Platinum",
  "Costume Jewelry",
  "Other"
],
receiptTypeTaxOptions = [
  { id: "", label: "Choose a receipt type" },
  { id: "CR", label: "Coin Receipt" },
  { id: "JR", label: "Jewelry Receipt" }
],
defaultReceiptTypeTaxOption = "CR",
drawerTransferOptions = [
  { value: "coindrawer", label: "Coin Drawer" },
  { value: "metrodrawer", label: "Metro Drawer" },
],
transactionEditOptions = [
  { value: "", label: "" },
  { value: "bank", label: "Bank" },
  { value: "vault2", label: "Vault2" },
  { value: "coinvault", label: "Coin Vault" },
  { value: "metrovault", label: "Metro Vault" },
  { value: "coindrawer", label: "Coin Drawer" },
  { value: "metrodrawer", label: "Metro Drawer" },
  { value: "coinbag", label: "Coin Bag" },
  { value: "metrobag", label: "Metro Bag" },
  { value: "jewelryretail", label: "Jewelry Retail" },
  { value: "retail", label: "Coin Retail" },
  { value: "pettycash", label: "Petty Cash" },
  { value: "customer", label: "Customer" },
  { value: "reconcile", label: "Reconcile" },
],
transactionOptions = [
  { value: "", label: "" },
  { value: "bank", label: "Bank" },
  { value: "vault2", label: "Vault2" },
  { value: "coinvault", label: "Coin Vault" },
  { value: "metrovault", label: "Metro Vault" },
  { value: "coindrawer", label: "Coin Drawer" },
  { value: "metrodrawer", label: "Metro Drawer" },
  { value: "coinbag", label: "Coin Bag" },
  { value: "metrobag", label: "Metro Bag" },
  { value: "jewelryretail", label: "Jewelry Retail" },
  { value: "retail", label: "Coin Retail" },
  { value: "pettycash", label: "Petty Cash" },
  { value: "customer", label: "Customer" },
],
invoicePhoneNumbers = {
  'ron': "702-283-6777",
  'john': "702-812-8294",
  'bella': '214-454-5095',
  'dave': '661-373-5002'
},
invoiceBusinessLicences = {
  'ron': "NV20131655937",
  'john': "NV20151032231",
  'bella': 'Texas Business',
  'imj trading': "CA203522314",
  'dave': '2001062-042-420'
},
pettyCashOptions = [
  "Customer",
  "Vendor",
  "Employee",
  "Cash Back"
],
metricFilterOptions = [
  "keyword",
  "age",
  "race",
  "zip"
],
alphabeticLetters = [
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z'
],
bagNumbers = Array(99).fill(1).map((x, y) => (x + y).toString());

module.exports = {
displayOrderTypeOptions,
alphabeticLetters,
printCopyOptions,
monthFullOptions,
paymentOptions,
itemTypeOptions,
sexOptions,
eyesOptions,
hairOptions,
boothOptions,
stationOptions,
copyToData,
raceOptions,
fromOptions,
itemMaterial,
itemType,
paymentOptionsReceipt,
paymentOptionsCoinReceipt,
transactionOptions,
transactionEditOptions,
receiptTypeTaxOptions,
defaultReceiptTypeTaxOption,
monthOptions,
monthList,
dayOptions,
hourOptions,
minuteOptions,
drawerTransferOptions,
billedToOptions,
paidOptions,
invoicePhoneNumbers,
invoiceBusinessLicences,
referTypeOptions,
quoteStatusOptions,
pettyCashOptions,
metricFilterOptions,
jewelryReceiptPaymentTypes,
metroPaymentOptions,
jewelryPaymentOptionsReceipt,
coinInvoiceTypes,
paymentCreditCardOptions,
paymentCashOptions,
bagNumbers
};
