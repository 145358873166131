import { PostApiWithAuthorizationAction } from "constantFunctions/apiActions";
import { stationOptions } from "constants/ticketConstants";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { DropdownList } from "react-widgets";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";

function StationSelectModal(props) {
  const [station, setStation] = useState(
    localStorage.getItem("lastUsedPrinter")
  );

  const printInvoiceHandler = (id,type) => {
      let body = {
        printerId: localStorage.getItem("lastUsedPrinter"),
        ticketId: id,
        ticketType: type,
        allowSignature: props.data.allowSignature
      };
      PostApiWithAuthorizationAction("common/print-invoice", body).then(
        (res) => {
          //toast.success(res.message);
          props.toggle();
        }
      ).catch(e => props.toggle() );
  };

  return (
    <Modal isOpen={props.show} toggle={props.toggle}>
      <ModalHeader toggle={props.toggle}>Select Station!</ModalHeader>
      <ModalBody>
        <DropdownList
        id="station"
          data={stationOptions}
          textField="label"
          value={station}
          placeholder="Select Station"
          onChange={(e) => {
            setStation(e.value);
            localStorage.setItem("lastUsedPrinter", e.value);
          }}
        />
      </ModalBody>
      <ModalFooter>
        <Button color="primary" id="print" onClick={ e => printInvoiceHandler(props.data.id,props.data.type)}>
          Print
        </Button>{" "}
        <Button color="secondary" onClick={(e) => props.toggle()}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default StationSelectModal;
