import { PostApiWithAuthorizationAction } from "constantFunctions/apiActions";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  FormGroup,
  Input,
  Label
} from "reactstrap";
import { validateVoidReasonForm } from "../../../constantFunctions/formValidations";

function EditVoidReasonModal(props) {
  const [name, setName] = useState(props.editData.reason);
  const updateItemHandler = (e) => {
    let body = {
      id: props.editData.id,
      reason: name.trim()
    };
    let validation = validateVoidReasonForm(body);
    if (validation.success) {
        PostApiWithAuthorizationAction("common/edit-void-reason", body)
      .then((res) => {
        props.fetchAllVoidReasons();
        props.toggle();
      })
      .catch((err) => {
        if (err.status === 440) {
          toast.error(err.message, { position: toast.POSITION.TOP_CENTER });
          props.history.push("/auth/login");
        } else {
          toast.error("Something went wrong! /n Please clear and retry", {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      });
    } else {
        toast.error(validation.err, {
          position: toast.POSITION.TOP_CENTER,
          closeOnClick: true,
          autoClose: 8000,
        });
    }
  };

  return (
    <Modal isOpen={props.show} toggle={props.toggle} autoFocus={false}>
      <ModalHeader toggle={props.toggle}>Add new item</ModalHeader>
      <ModalBody>
        <FormGroup className="mb-0">
          <Label className="form-control-label" for="b_name">
            Name
          </Label>
          <Input
            id="name"
            type="text"
            placeholder="Name"
            value={name}
            autoFocus={true}
            onChange={(e) => setName(e.target.value)}
          />
          <span className="text-danger">
            <small>*required</small>
          </span>
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <Button id="update-btn" color="primary" onClick={updateItemHandler} disabled={name.length === 0}>
          Update
        </Button>{" "}
        <Button color="secondary" onClick={props.toggle}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default EditVoidReasonModal;
