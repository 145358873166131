import {
    FETCH_TRANSACTION_DAILY_COUNT_SUCCESS,
    FETCH_TRANSACTION_DAILY_COUNT_FAILURE,
    SAVE_DC_DETAILS_IN_FORM,
    RESET_DC_FORM
  } from "./transactionsTypes";
  
  const initialState = {
    transactionDailyCount: {
      transactionOptions: [],
      meta: {
        metroTotal: 0,
        coinTotal: 0,
        coinDeposit: 0,
        jewelryDeposit: 0,
        coinChangeTotal: 0,
        jewelryChangeTotal: 0,
        coinDailyTotal: 0,
        denoms: [1, 5, 10, 20, 50, 100],
        vault: [60000, 5000, 10000, 2000, 2000, 500]
      }
    },
    error: null,
  };
  
  const reducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_TRANSACTION_DAILY_COUNT_SUCCESS:
        return {
            transactionDailyCount: action.payload,
          error: null,
        };
      case FETCH_TRANSACTION_DAILY_COUNT_FAILURE:
        return {
          ...initialState,
          error: action.payload,
        };
      case SAVE_DC_DETAILS_IN_FORM:
        return {
            ...state,
            transactionDailyCount: action.payload
          };
      case RESET_DC_FORM: {
        return initialState;
      }
      default:
        return state;
    }
  };
  
  export default reducer;
  