import Header from "components/Headers/Header";
import React, { useEffect, useState } from "react";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    Form,
    FormGroup,
    Input,
    Label,
    Row,
    Table,
} from "reactstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Unauthorized from "views/Pages/Unauthorized";
import { GetApiActionWithAuthorization, PostApiWithAuthorizationAction } from "../../constantFunctions/apiActions";
import ReactPaginate from "react-paginate";
import { resetState } from "../../redux/common/commonAction";
import { connect } from "react-redux";
import ConfirmationDeleteModal from "views/Modal/ConfirmationDeleteModal";
import EditItemDescriptionModal from "views/Modal/ItemDescription/EditItemDescriptionModal";

function ItemDescriptionCheck(props) {
    const [unauthorized, setUnauthorized] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const [editData, setEditData] = useState({});
    const [confirm, setConfirm] = useState(false);
    const [delId, setDelId] = useState("");
    const [items, setItems] = useState({
        data: [],
        count: 0
    });
    const [filter, setFilter] = useState({
        page: 0,
        keyword: ''
    });

    useEffect(() => {
        load();
    }, []);

    if (items.error && !unauthorized) {
        if (items.error.status === 403) {
          setUnauthorized(true);
        } else if (items.error.status === 440) {
          toast.error(items.error.message, {
            position: toast.POSITION.TOP_CENTER,
          });
          props.history.push("/auth/login");
        }
    }

    const toggleEdit = (data) => {
        setEditData(data);
        setEditModal(!editModal);
    };

    const toggleAdd = (e) => {
        setEditData({
            id: 0,
            keyword: ''
        });
        setEditModal(!editModal);
    };

    const toggleConfirm = (id = "") => {
        setDelId(id);
        setConfirm(!confirm);
    };

    const confirmed = () => {
        PostApiWithAuthorizationAction("item-descriptions/delete/" + delId)
        .then((res) => {
          load();
          setConfirm(false);
        })
        .catch((err) => {
          console.log(err);
          if (err.status === 403) {
            setUnauthorized(true);
          } else if (err.status === 440) {
            props.resetState();
            toast.error(err.message, { position: toast.POSITION.TOP_CENTER });
            props.history.push("/auth/login");
          }
        });
    };

  const load = (params) => {
    params = Object.assign(filter, params);
    GetApiActionWithAuthorization("item-descriptions/get-all-data", filter)
    .then((res) => {
        setItems(res)
    })
    .catch((err) => {
      if (err.status === 403) {
        setUnauthorized(true);
      } else if (err.status === 440) {
        props.resetState();
        toast.error(err.message, { position: toast.POSITION.TOP_CENTER });
        props.history.push("/auth/login");
      }
    });
  };

    const renderData = () => {
        return items.data.map((_data, i) => {
            return (
                <>
                    <tr>
                        <td scope="col">{(i+1)}</td>
                        <td scope="col">{_data.keyword}</td>
                        <td scope="col">
                            [
                            <span
                                id={"update-item"+i}
                                className="delete-item"
                                onClick={(e) => {
                                    toggleEdit(_data);
                                }}
                                >
                                Edit
                            </span>
                            ] [
                                <span
                                    id={"delete-item"+i}
                                    className="delete-item"
                                    onClick={(e) => toggleConfirm(_data.id)}>Delete</span>
                            ]{" "}
                        </td>
                    </tr>
                </>
            )
        });
    };

    //
    return unauthorized ? (
        <Unauthorized />
      ) : (
        <>
          <Header />
          {/* Page content */}
          <Container className="mt--9 main-content" fluid>
            <Card className="card-height shadow">
              <CardHeader>
                <div className="text-center">
                    <h1>Item Description Checker</h1>
                </div>
              </CardHeader>
              <CardBody className="page-main-content">
                <Row className="mb-3">
                    <Col md="6" className="search-left-btn">
                        <Button id="add-new" color="primary" onClick={toggleAdd}>
                        New Item
                        </Button>
                    </Col>
                </Row>
                <Table className="table-listing" responsive bordered>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Keyword</th>
                      <th scope="col">Actions</th>
                    </tr>
                  </thead>
                  <tbody>{renderData()}</tbody>
                </Table>
                {items.data.length > 0 && (
                  <ReactPaginate
                    previousLabel={
                        <i className="fas fa-chevron-left text-white"></i>
                    }
                    nextLabel={
                        <i className="fas fa-chevron-right text-white"></i>
                    }
                    breakLabel={".."}
                    breakClassName={"break-me"}
                    initialPage={filter.page}
                    forcePage={filter.page}
                    pageCount={items.count / 50}
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={2}
                    onPageChange={(e) => {
                        if (filter.page !== e.selected) {
                            load({
                                page: e.selected
                            });
                        }
                    }}
                    containerClassName={"pagination"}
                    subContainerClassName={"pages pagination"}
                    activeClassName={"active"}
                />
              )}
              </CardBody>
            </Card>
          </Container>
          {editModal && (
            <EditItemDescriptionModal
                {...props}
                show={editModal}
                editData={editData}
                toggle={toggleEdit}
                reload={load}
                />
        )}
        {confirm && (
            <ConfirmationDeleteModal
            show={confirm}
            toggle={toggleConfirm}
            confirmed={confirmed}
            />
        )}
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        userType: state.loginUserReducer.userType
    };
  };
  
const mapDispatchToProps = (dispatch) => {
    return {
        resetState: () => dispatch(resetState())
    };
};
  
export default connect(mapStateToProps, mapDispatchToProps)(ItemDescriptionCheck);