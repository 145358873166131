import { PostApiWithAuthorizationAction } from "constantFunctions/apiActions";
import React, { useState } from "react";
import { toast } from "react-toastify";
import {
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button, Row, Col, FormGroup, Label, Input
} from "reactstrap";
import Moment from "react-moment";
import ViewTicketChangeValue from "./ViewTicketChangeValue";
import ViewReceiptChangeValue from "./ViewReceiptChangeValue";

function ViewChangeHistory(props) {
    const [viewChangesModal, setViewChangesModal] = useState(false);
    const [changeData, setChangeData] = useState({});

    const toggleChange = () => setViewChangesModal(!viewChangesModal);

    const viewChanges = (value) => {
        setChangeData(value);
        setViewChangesModal(!viewChangesModal);
    };

    const render = () => {
        return props.data.map((d, i) => {
            return (
                <>
                    {(d.updateType == 1) && (
                        <tr style={{ fontWeight: 'bold'}}>
                            <td>Entry <b>#{d.entryId}</b> has been created
                            at&nbsp;
                                <Moment format="MM/DD/YY HH:mm" tz="America/Los_Angeles">
                                    {d.createdAt.toString().length === 10
                                        ? new Date(d.createdAt * 1000)
                                        : new Date(d.createdAt)}
                                </Moment>.
                            </td>
                            <td>
                                <Button
                                    className="view-change-history"
                                    color="primary"
                                    size="sm"
                                    onClick={(e) => {
                                        viewChanges(d.value);
                                    }}
                                    >
                                    <i class="fas fa-eye"></i>
                                </Button>
                            </td>
                        </tr>
                    )}
                    {(d.updateType == 2) && (
                        <tr style={{ fontWeight: 'bold'}}>
                            <td>Entry <b>#{d.entryId}</b> has been updated
                                at&nbsp;<Moment format="MM/DD/YY HH:mm" tz="America/Los_Angeles">
                                    {d.createdAt.toString().length === 10
                                        ? new Date(d.createdAt * 1000)
                                        : new Date(d.createdAt)}
                                </Moment>.
                            </td>
                            <td>
                                <Button
                                    className="view-change-history"
                                    color="primary"
                                    size="sm"
                                    onClick={(e) => {
                                        viewChanges(d.value);
                                    }}>
                                    <i class="fas fa-eye"></i>
                                </Button>
                            </td>
                        </tr>
                    )}
                </>
            );
        });
    }
  return (
    <>
    <Modal
        isOpen={props.show}
        toggle={props.toggle}>
      <ModalHeader toggle={props.toggle}>View Changes History</ModalHeader>
      <ModalBody className="py-0 pb-3">
        <Table className="table-listing" responsive bordered>
            <thead>
                <tr>
                    <th scope="col">History</th>
                    <th scope="col"></th>
                </tr>
            </thead>
            <tbody>
            {render()}
            </tbody>
        </Table>
      </ModalBody>
    </Modal>
    {viewChangesModal && (props.viewType=="ticket") && (
        <ViewTicketChangeValue
          show={viewChangesModal}
          toggle={toggleChange}
          data={changeData}
        />
    )}
    {viewChangesModal && (props.viewType=="receipt") && (
        <ViewReceiptChangeValue
          show={viewChangesModal}
          toggle={toggleChange}
          data={changeData}
        />
      )}
    </>
  );
}

export default ViewChangeHistory;
