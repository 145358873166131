import React, { useEffect, useState } from "react";
// reactstrap components
import {
  Card,
  Container,
  CardHeader,
  CardBody,
  Button,
  Input,
  Label,
  Form,
  Table,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import {
  sexOptions,
  hairOptions,
  paymentOptions,
  eyesOptions,
  stationOptions,
  copyToData,
  raceOptions,
} from "../../../constants/ticketConstants";
import { connect } from "react-redux";
import { copyTicketDetailsToMetro } from "../../../redux/Tickets/ticketAction";
import {
  fetchAllStoresListing,
  fetchCoinItemsListingSuccess,
  fetchAllClerkListing,
  fetchCoinItemsListing,
  fetchCoinBarterItemsListing,
  fetchCoinBarterItemsListingSuccess
} from "../../../redux/CoinTicket/coinAction";
import { copyTicketDetailsToCoin } from "../../../redux/CoinTicket/coinAction";
import { DropdownList } from "react-widgets";
import { PostApiWithAuthorizationAction } from "constantFunctions/apiActions";
import { GetApiActionWithAuthorization } from "constantFunctions/apiActions";
import Unauthorized from "views/Pages/Unauthorized";
import { toast } from "react-toastify";
import BlockModal from "views/Modal/BlockModal";
import Moment from "react-moment";
import { validateTicketForm } from "constantFunctions/formValidations";
import { resetState } from "../../../redux/common/commonAction";
import Autocomplete from "components/AutoComplete/Autocomplete";
import noSign from "../../../assets/img/noSign.png";
import ConfirmationDeleteModal from "views/Modal/ConfirmationDeleteModal";
import ViewAndEditCoinItemsModal from "views/Modal/CoinTicketsModals/viewAndEditCoinItemsModal";
import { jsonToFormData } from "libs/common";
import { enableFileUploader } from "libs/common";
import moment from "moment-timezone";

// class AddNewMetroTicket extends React.Component {
function EditCoinTicket(props) {
  const [payment, setPayment] = useState({ value: "cash", label: "Cash" });
  const [sex, setSex] = useState({ value: "M", label: "Male" });
  const [eyes, setEyes] = useState({ value: "Bro", label: "Bro" });
  const [hair, setHair] = useState({ value: "Bro", label: "Bro" });
  const [booth, setBooth] = useState({ value: "", label: "None" });
  const [station, setStation] = useState({ value: "", label: "None" });
  const [copyTo, setCopyTo] = useState({ value: "ticket", label: "Ticket" });
  const [race, setRace] = useState({ value: "", label: "None" });
  const [storeId, setStoreId] = useState({});
  const [clerk, setClerk] = useState({ id: "", first_name: "None" });
  const [fromx, setFromx] = useState({ value: "", label: "None" });
  const [otherDetails, setOtherDetails] = useState({});
  const [unauthorized, setUnauthorized] = useState(false);
  const [ticketTime, setTicketTime] = useState(false);
  const [blockModal, setBlockModal] = useState(false);
  const [blocked, setBlocked] = useState(false);
  const [censored, setCensored] = useState(false);
  const [keywords, setKeywords] = useState([]);
  const [keyLoad, setKeyLoad] = useState(false);
  const [fromOptions, setFromOption] = useState([]);
  const [confirm, setConfirm] = useState(false);
  const [editItemModal, setEditItemModal] = useState(false);
  const [editBarterItemModal, setEditBarterItemModal] = useState(false);
  const [boothOptions, setBoothOptions] = useState([]);
  const [attachments, setAttachments] = useState([]);
  const [checkNumberPrefix, setCheckNumberPrefix] = useState('');

  useEffect(() => {
    let id = props.match.params.id;
    props.fetchAllStoresListing();
    props.fetchAllClerkListing();
    GetApiActionWithAuthorization(
      `coin-tickets/get-particular-ticket-data/${id}`
    )
      .then((res) => {
        let other = { ...res.data };
        delete other.payment;
        delete other.sex;
        delete other.eyes;
        delete other.hair;
        delete other.booth;
        delete other.station;
        delete other.race;
        delete other.storeId;
        delete other.clerk;
        delete other.fromx;
        setPayment(paymentOptions.find((p) => p.value === res.data.payment));
        setBlocked(res.blocked);
        setSex(sexOptions.find((s) => s.value === res.data.sex));
        setEyes(eyesOptions.find((e) => e.value === res.data.eyes));
        setHair(hairOptions.find((h) => h.value === res.data.hair));
        setBooth({ name: res.data.booth });
        setStation(stationOptions.find((st) => st.value === res.data.station));
        setRace(raceOptions.find((r) => r.value === res.data.race));
        setStoreId({ findWith: res.data.storeId });
        setTicketTime(res.data.ticketTime);
        if (res.data.clerk) {
          setClerk({ findWith: res.data.clerk });
        }
        setFromx({ name: res.data.fromx });
        setOtherDetails(other);
        //
        setAttachments(res.data.new_coin_attachments.map(f => {
          return {
            name: f.url.split('/').pop(),
            type: f.mimeType,
            size: f.fileSize,
            file: f.url,
            local: f.url,
            data: {
              url: f.url,
              thumbnail: f.thumbnailUrl,
              readerForce: true
            }
          }
        }))
        return Promise.all([
          other,
          GetApiActionWithAuthorization("common/get-check-setting")
        ])
      }).then(result => {
        const [other, setting] = result
        const coinCheckNumberPrefix = setting.data.coinCheckNumberPrefix.toString()
        setCheckNumberPrefix(coinCheckNumberPrefix);
        //
        if (other.checkNumber.toString().length <= coinCheckNumberPrefix.length) {
          setOtherDetails({
            ...other,
            'checkNumber': coinCheckNumberPrefix
          })
        }
        //load fileuploader with plain jquery
        window.jQuery(document).ready(function() {
          enableFileUploader();
        });
      })
      .catch((err) => {
        if (err.status === 403) {
          setUnauthorized(true);
        } else if (err.status === 440) {
          props.resetState();
          toast.error(err.message, { position: toast.POSITION.TOP_CENTER });
          props.history.push("/auth/login");
        }
      });
  }, []);

  if (storeId && storeId.findWith) {
    setStoreId(
      props.stores.allStores.find((str) => str.id == storeId.findWith)
    );
  }

  if (clerk.findWith) {
    let find = props.clerks.allClerks.find(
      (str) => str.first_name == clerk.findWith
    );
    setClerk({ ...find });
  }

  const toggleBlock = (e) => {
    setBlockModal(!blockModal);
  };

  const fetchAllBooth = (e) => {
    if (e) {
      GetApiActionWithAuthorization("common/get-all-booths").then((res) => {
        setBoothOptions(res.data);
      });
    }
  };

  const formSubmit = (e) => {
    e.preventDefault();
    if (
      otherDetails.oneTimeEdit ||
      (props.userType && props.userType.toLowerCase() === "admin") ||
      (!otherDetails.signature && (props.userType && props.userType.toLowerCase() !== "admin"))
    ) {
      let validation = validateTicketForm({
        ...otherDetails,
        payment,
        sex,
        fromx,
        clerk,
        race,
        hair,
        eyes,
        booth,
        station,
        userType: props.userType
      });
      if (validation.success) {
        let data = jsonToFormData({
          ...otherDetails,
          payment: payment.value,
          sex: sex ? sex.value : "",
          printer: station ? station.value : "",
          fromx: fromx.name,
          clerk: clerk.first_name,
          storeId: storeId.id,
          race: race.value,
          station: station ? station.value : "",
          booth: booth ? booth.name : "",
          hair: hair ? hair.value : "",
          eyes: eyes ? eyes.value : "",
          id: props.match.params.id,
        });
        let existingFiles = window.jQuery('[name="fileuploader-list-files"]').val();
        data.append('existingFiles', existingFiles);
        window.jQuery('[name="files[]"]').each(function( index ) {
          let files = window.jQuery('[name="files[]"]')[index].files;
          for (const file of files) {
            data.append('files[]', file, file.name);
          }
        });
        PostApiWithAuthorizationAction("coin-tickets/edit-ticket", data, {
          'Content-Type': 'multipart/form-data'
        })
          .then((res) => {
            //toast.success(res.message);
            props.history.push("/portal/coin-tickets");
          })
          .catch((err) => {
            if (err.status === 403) {
              setUnauthorized(true);
            } else if (err.status === 440) {
              props.resetState();
              toast.error(err.message, { position: toast.POSITION.TOP_CENTER });
              props.history.push("/auth/login");
            } else {
              toast.error(err.message, {
                position: toast.POSITION.TOP_CENTER,
              });
            }
          });
      } else {
        toast.error(validation.err, {
          position: toast.POSITION.TOP_CENTER,
          closeOnClick: true,
          autoClose: 8000,
        });
      }
    }
  };

  const copyToNew = (e) => {
    const {
      firstName,
      lastName,
      address,
      city,
      state,
      zip,
      dOB,
      height,
      weight,
      dLState,
      dLNo,
      otherIDType,
      otherIDNo,
      otherNo,
    } = otherDetails;
    let other = {
      firstName,
      lastName,
      address,
      city,
      state,
      zip,
      dOB,
      height,
      weight,
      race,
      sex,
      dLState,
      dLNo,
      otherIDType,
      otherIDNo,
      otherNo,
      fromx: { name: "repeat" },
    };
    if (copyTo.value === "ticket") {
      props.copyTicketDetailsToMetro(other);
      props.history.push("/portal/metro-tickets/add-new");
    } else {
      props.fetchCoinItemsListingSuccess({ data: [] });
      props.copyTicketDetailsToCoin(other);
      props.history.push("/portal/coin-tickets/add-new");
    }
  };

  // const getKeywords = (e) => {
  //   if (keywords.length === 0) {
  //     setKeyLoad(true);
  //     GetApiActionWithAuthorization("common/get-keywords-for-form")
  //       .then((res) => {
  //         setKeyLoad(false);
  //         setKeywords(res.data);
  //       })
  //       .catch((err) => setKeyLoad(false));
  //   }
  // };

  const getFromOptions = (e) => {
    if (e && fromOptions.length === 0) {
      setKeyLoad(true);
      GetApiActionWithAuthorization("common/get-from-select-options")
        .then((res) => {
          setKeyLoad(false);
          setFromOption(res.data);
        })
        .catch((err) => setKeyLoad(false));
    }
  };

  const confirmed = (e) => {
    GetApiActionWithAuthorization("common/remove-signature", {
      id: props.match.params.id,
    })
      .then((res) => {
        //toast.success(res.message, { position: toast.POSITION.TOP_CENTER });
        setOtherDetails({ ...otherDetails, signature: res.data.signature });
        setConfirm(false);
      })
      .catch((err) => {
        setConfirm(false);
        if (err.status === 403) {
          setUnauthorized(true);
        } else if (err.status === 440) {
          props.resetState();
          toast.error(err.message, { position: toast.POSITION.TOP_CENTER });
          props.history.push("/auth/login");
        }
      });
  };

  const toggleConfirm = (id = "") => {
    setConfirm(!confirm);
  };

  const editItemToggle = (e_id = "") => {
    props.fetchCoinItemsListingSuccess({ data: [] });
    setEditItemModal(!editItemModal);
  };

  const editBarterItemToggle = (e_id = "") => {
    props.fetchCoinBarterItemsListingSuccess({ data: [] });
    setEditBarterItemModal(!editBarterItemModal);
  };

  return unauthorized ? (
    <Unauthorized />
  ) : (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--9 main-content" fluid>
        <Card>
          <CardHeader
            className={
              "d-flex align-items-center justify-content-between p-2" +
              (blocked ? ((censored ? " back-green" : " back-red") + " back-red") : " bg-grn ")
            }
          >
            <h4 className="text-white">
              Edit Coin Ticket{" "}
              {!otherDetails.oneTimeEdit &&
                props.userType.toLowerCase() !== "admin" &&
                "(Not Editable for now!)"}
              <br />
              <small>
                (Ticket ID:C{props.match.params.id} - Ticket Time:{" "}
                <Moment format="MMM Do YYYY HH:mm" tz="America/Los_Angeles">
                  {ticketTime.toString().length === 10
                    ? new Date(ticketTime * 1000)
                    : new Date(ticketTime)}
                </Moment>{" "}
                ){" "}
              </small>
            </h4>
            <div className="d-flex align-items-center">
              <div className="d-flex copy-drop justify-content-end align-items-center">
                <Label className="m-0">Copy to:</Label>
                <DropdownList
                  data={copyToData}
                  containerClassName="race-drop"
                  textField="label"
                  value={copyTo}
                  onChange={(e) => setCopyTo(e)}
                />
                <Button
                  className="mx-1"
                  color="info"
                  onClick={copyToNew}
                  disabled={blocked}
                >
                  Copy
                </Button>
                <Button
                  color="warning"
                  className="m-0"
                  onClick={(e) => {
                    if (blocked) {
                      toast.error("Already Blocked");
                    } else {
                      toggleBlock();
                    }
                  }}
                >
                  Block
                </Button>
              </div>
              <Button
                type="button"
                color="primary"
                className="h-fit ml-2 py-2"
                onClick={() => {
                  props.history.goBack();
                }}
              >
                <i className="fas fa-chevron-left"></i> Back
              </Button>
            </div>
          </CardHeader>
          <CardBody className={blocked ? (censored ? " back-green" : " back-red") + " p-2" : "bg-grn p-2"}>
            {/* <Form onSubmit={formSubmit}> */}
            <Table className="add-ticket-table" bordered responsive="sm">
              <tbody>
                <tr className="back-grey">
                  <th>Ticket Amount:</th>
                  <td className="width-middle">
                    <Input
                      id="ticketAmount"
                      type="number"
                      step="1"
                      placeholder="Ticket Amount"
                      name="ticketAmount"
                      value={otherDetails.ticketAmount}
                      onChange={(e) =>
                        setOtherDetails({
                          ...otherDetails,
                          [e.target.name]: e.target.value,
                        })
                      }
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                    />
                  </td>
                  <td className="width-last"></td>
                </tr>
                <tr
                  className={
                    payment.value === "cash" ? "back-green" : "back-orange"
                  }
                >
                  <th>Payment:</th>
                  <td className="width-middle">
                    <div className="d-flex align-items-end">
                      <DropdownList
                        id="payment"
                        data={paymentOptions}
                        containerClassName="payment-drop"
                        textField="label"
                        value={payment}
                        onChange={(e) => setPayment(e)}
                      />
                      {payment.value === "check" && (
                        <>
                          <Label className="ml-2">Check Amount:</Label>
                          <Input
                            id="paymentCheckAmount"
                            type="number"
                            className="ml-1"
                            placeholder="Check Amount"
                            name="paymentCheckAmount"
                            value={otherDetails.paymentCheckAmount}
                            onChange={(e) =>
                              setOtherDetails({
                                ...otherDetails,
                                [e.target.name]: e.target.value,
                              })
                            }
                          />
                          <Label className="ml-2">Check Number:</Label>
                          <Input
                            id="checkNumber"
                            type="text"
                            className="ml-1"
                            placeholder="Check Number"
                            name="checkNumber"
                            value={otherDetails.checkNumber}
                            onChange={(e) => {
                              let characterLength = e.target.value.length
                              let checkNumberPrefixLength = checkNumberPrefix.length
                              if (props.userType.toLowerCase() != 'admin') {
                                if (characterLength >= checkNumberPrefixLength) {
                                  setOtherDetails({
                                    ...otherDetails,
                                    [e.target.name]: e.target.value,
                                  })
                                }
                              } else {
                                setOtherDetails({
                                  ...otherDetails,
                                  [e.target.name]: e.target.value,
                                })
                              }
                            }}
                          />
                        </>
                      )}
                    </div>
                  </td>
                  <td className="width-last"></td>
                </tr>
                <tr>
                  <th>First Name:</th>
                  <td className="width-middle">
                    <Input
                      id="firstName"
                      type="text"
                      placeholder="First Name"
                      name="firstName"
                      value={otherDetails.firstName}
                      onChange={(e) =>
                        setOtherDetails({
                          ...otherDetails,
                          [e.target.name]: e.target.value,
                        })
                      }
                    />
                  </td>
                  <td className="width-last"></td>
                </tr>
                <tr>
                  <th className="back-grey">Middle Name:</th>
                  <td className="width-middle">
                    <Input
                      id="middleName"
                      type="text"
                      placeholder="Middle Name"
                      name="middleName"
                      value={otherDetails.middleName}
                      onChange={(e) =>
                        setOtherDetails({
                          ...otherDetails,
                          [e.target.name]: e.target.value,
                        })
                      }
                    />
                  </td>
                  <td className="width-last"></td>
                </tr>
                <tr>
                  <th>Last Name:</th>
                  <td className="width-middle">
                    <Input
                      id="lastName"
                      type="text"
                      placeholder="Last Name"
                      name="lastName"
                      value={otherDetails.lastName}
                      onChange={(e) =>
                        setOtherDetails({
                          ...otherDetails,
                          [e.target.name]: e.target.value,
                        })
                      }
                    />
                  </td>
                  <td className="width-last"></td>
                </tr>
                <tr className="back-grey">
                  <th>Address:</th>
                  <td className="width-middle">
                    <Input
                      id="address"
                      type="text"
                      placeholder="Address"
                      name="address"
                      value={otherDetails.address}
                      onChange={(e) =>
                        setOtherDetails({
                          ...otherDetails,
                          [e.target.name]: e.target.value,
                        })
                      }
                    />
                  </td>
                  <td className="width-last"></td>
                </tr>
                <tr>
                  <th>City:</th>
                  <td className="width-middle">
                    <Input
                      id="city"
                      type="text"
                      placeholder="City"
                      name="city"
                      value={otherDetails.city}
                      onChange={(e) =>
                        setOtherDetails({
                          ...otherDetails,
                          [e.target.name]: e.target.value,
                        })
                      }
                    />
                  </td>
                  <td className="width-last"></td>
                </tr>
                <tr className="back-grey">
                  <th>State:</th>
                  <td className="width-middle">
                    <Input
                      id="state"
                      type="text"
                      placeholder="State"
                      name="state"
                      value={otherDetails.state}
                      onChange={(e) =>
                        setOtherDetails({
                          ...otherDetails,
                          [e.target.name]: e.target.value,
                        })
                      }
                    />
                  </td>
                  <td className="width-last"></td>
                </tr>
                <tr>
                  <th>Zip:</th>
                  <td className="width-middle">
                    <Input
                      id="zip"
                      type="number"
                      placeholder="Zip"
                      name="zip"
                      value={otherDetails.zip}
                      onChange={(e) =>
                        setOtherDetails({
                          ...otherDetails,
                          [e.target.name]: e.target.value,
                        })
                      }
                    />
                  </td>
                  <td className="width-last"></td>
                </tr>
                <tr className="back-grey">
                  <th>Sex:</th>
                  <td className="width-middle">
                    <div className="d-flex align-items-end">
                      <DropdownList
                        id="sex"
                        data={sexOptions}
                        containerClassName="payment-drop"
                        textField="label"
                        value={sex}
                        onChange={(e) => setSex(e)}
                      />
                    </div>
                  </td>
                  <td className="width-last"></td>
                </tr>
                <tr>
                  <th>DOB:</th>
                  <td className="width-middle">
                    <Input
                      id="dOB"
                      type="number"
                      placeholder="DOB"
                      name="dOB"
                      value={otherDetails.dOB}
                      onChange={(e) => {
                        const formattedDOB = moment(e.target.value, 'MMDDYYYY');
                        if (formattedDOB.isValid() && e.target.value.length === 8) {
                          const yearOfBirth = moment().diff(formattedDOB, 'years');
                          //check less than 18
                          setBlocked((yearOfBirth < 18 && yearOfBirth > 0));
                          setCensored((yearOfBirth < 18 && yearOfBirth > 0));
                        } else {
                          setBlocked(false);
                          setCensored(false)
                        }
                        setOtherDetails({
                          ...otherDetails,
                          [e.target.name]: e.target.value,
                        })
                      }}
                    />
                  </td>
                  <td className="width-last">
                    <span>Example: MMDDYYYY 12311980.</span>
                  </td>
                </tr>
                <tr className="back-grey">
                  <th>Height:</th>
                  <td className="width-middle">
                    <Input
                      id="height"
                      type="text"
                      placeholder="Height"
                      name="height"
                      value={otherDetails.height}
                      onInput={(e) => {
                        e.target.value = Math.max(0, parseInt(e.target.value))
                          .toString()
                          .slice(0, 3);
                      }}
                      onChange={(e) =>
                        setOtherDetails({
                          ...otherDetails,
                          [e.target.name]: e.target.value,
                        })
                      }
                    />
                  </td>
                  <td className="width-last">
                    <span>Example: If someone is 6'3" put 603.</span>
                  </td>
                </tr>
                <tr>
                  <th>Weight:</th>
                  <td className="width-middle">
                    <Input
                      id="weight"
                      type="number"
                      placeholder="Weight"
                      name="weight"
                      onInput={(e) => {
                        e.target.value = Math.max(0, parseInt(e.target.value))
                          .toString()
                          .slice(0, 3);
                      }}
                      value={otherDetails.weight}
                      onChange={(e) =>
                        setOtherDetails({
                          ...otherDetails,
                          [e.target.name]: e.target.value,
                        })
                      }
                    />
                  </td>
                  <td className="width-last"></td>
                </tr>
                <tr className="back-grey">
                  <th>Eyes:</th>
                  <td className="width-middle">
                    <div className="d-flex align-items-end">
                      <DropdownList
                        id="eyes"
                        data={eyesOptions}
                        containerClassName="payment-drop"
                        value={eyes}
                        textField="label"
                        onChange={(e) => setEyes(e)}
                      />
                    </div>
                  </td>
                  <td className="width-last"></td>
                </tr>
                <tr>
                  <th>Hair:</th>
                  <td className="width-middle">
                    <div className="d-flex align-items-end">
                      <DropdownList
                        id="hair"
                        data={hairOptions}
                        containerClassName="payment-drop"
                        value={hair}
                        textField="label"
                        onChange={(e) => setHair(e)}
                      />
                    </div>
                  </td>
                  <td className="width-last"></td>
                </tr>
                <tr className="back-red ">
                  <th>Race:</th>
                  <td className="width-middle">
                    <div className="d-flex align-items-end">
                      <DropdownList
                        id="race"
                        data={raceOptions}
                        containerClassName="race-drop"
                        textField="label"
                        value={race}
                        onChange={(e) => setRace(e)}
                      />
                    </div>
                  </td>
                  <td className="width-last"></td>
                </tr>
                <tr>
                  <th>DL State:</th>
                  <td className="width-middle">
                    <Input
                      id="dLState"
                      type="text"
                      placeholder="DL State"
                      name="dLState"
                      onInput={(e) => {
                        e.target.value = e.target.value.slice(0, 2);
                      }}
                      value={otherDetails.dLState}
                      onChange={(e) =>
                        setOtherDetails({
                          ...otherDetails,
                          [e.target.name]: e.target.value,
                        })
                      }
                    />
                  </td>
                  <td className="width-last"></td>
                </tr>
                <tr className="back-grey">
                  <th>DL Number:</th>
                  <td className="width-middle">
                    <Input
                      id="dLNo"
                      type="text"
                      placeholder="DL Number"
                      name="dLNo"
                      value={otherDetails.dLNo}
                      onChange={(e) =>
                        setOtherDetails({
                          ...otherDetails,
                          [e.target.name]: e.target.value,
                        })
                      }
                    />
                  </td>
                  <td className="width-last"></td>
                </tr>
                <tr>
                  <th>Other ID Type:</th>
                  <td className="width-middle">
                    <Input
                      id="otherIDType"
                      type="text"
                      placeholder="Other ID Type"
                      name="otherIDType"
                      value={otherDetails.otherIDType}
                      onChange={(e) =>
                        setOtherDetails({
                          ...otherDetails,
                          [e.target.name]: e.target.value,
                        })
                      }
                    />
                  </td>
                  <td className="width-last"></td>
                </tr>
                <tr className="back-grey">
                  <th>Other ID Number:</th>
                  <td className="width-middle">
                    <Input
                      id="otherIDNo"
                      type="text"
                      placeholder="Other ID Number"
                      name="otherIDNo"
                      value={otherDetails.otherIDNo}
                      onChange={(e) =>
                        setOtherDetails({
                          ...otherDetails,
                          [e.target.name]: e.target.value,
                        })
                      }
                    />
                  </td>
                  <td className="width-last"></td>
                </tr>
                <tr className="back-red">
                  <th>From:</th>
                  <td className="width-middle">
                    <div className="d-flex align-items-end">
                      <DropdownList
                        id="fromx"
                        data={fromOptions}
                        containerClassName="from-drop"
                        textField="name"
                        onToggle={getFromOptions}
                        value={fromx}
                        onChange={(e) => setFromx(e)}
                      />
                    </div>
                  </td>
                  <td className="width-last"></td>
                </tr>
                <tr className="back-red ">
                  <th>Booth:</th>
                  <td className="width-middle">
                    <div className="d-flex align-items-end">
                      <DropdownList
                        id="booth"
                        data={boothOptions}
                        containerClassName="booth-drop"
                        textField="name"
                        value={booth}
                        onChange={(e) => setBooth(e)}
                        onToggle={fetchAllBooth}
                      />
                    </div>
                  </td>
                  <td className="width-last"></td>
                </tr>
                {props.userType && !["employee", "employees", "employe"].includes(
                  props.userType.toLowerCase()
                ) && (
                  <tr>
                    <th>Station:</th>
                    <td className="width-middle">
                      <div className="d-flex align-items-end">
                        <DropdownList
                          id="station"
                          data={stationOptions}
                          containerClassName="station-drop"
                          textField="label"
                          value={station}
                          onChange={(e) => setStation(e)}
                        />
                      </div>
                    </td>
                    <td className="width-last"></td>
                  </tr>
                )}
                <tr>
                  <th>Keyword:</th>
                  <td className="width-middle autocomplete">
                    {/* <Autocomplete
                      suggestions={keywords}
                      value={otherDetails.keyword}
                      getKeywords={(e) => {
                        getKeywords();
                      }}
                      changeValues={(e) =>
                        setOtherDetails({
                          ...otherDetails,
                          ["keyword"]: e,
                        })
                      }
                    /> */}
                    <Input
                id="keyword"
                type="text"
                placeholder="Keyword"
                name="keyword"
                value={otherDetails.keyword}
                onChange={(e) =>
                  setOtherDetails({
                    ...otherDetails,
                    ["keyword"]: e.target.value,
                  })
                }
                autoComplete="off"
              />
                  </td>
                  <td className="width-last"></td>
                </tr>
                <tr>
                  <th>Coupon:</th>
                  <td className="width-middle">
                    <Input
                      id="coupon"
                      type="text"
                      placeholder="Coupon"
                      name="coupon"
                      value={otherDetails.coupon}
                      onChange={(e) =>
                        setOtherDetails({
                          ...otherDetails,
                          [e.target.name]: e.target.value,
                        })
                      }
                    />
                  </td>
                  <td className="width-last"></td>
                </tr>
                <tr className="back-red booth-drop">
                  <th>Clerk Name:</th>
                  <td className="width-middle">
                    <div className="d-flex align-items-end">
                      <DropdownList
                        id="clerk"
                        data={props.clerks.allClerks}
                        dropUp={true}
                        value={clerk}
                        containerClassName="booth-drop"
                        textField="first_name"
                        onChange={(e) => setClerk(e)}
                      />
                    </div>
                  </td>
                  <td className="width-last"></td>
                </tr>
                <tr>
                  <th>
                    <div class="">Property Description:</div>
                    <div class="">(Taking from Customer)</div>
                  </th>
                  <td className="width-middle">
                    <a
                      className="delete-item"
                      style={{ fontSize: "14px", paddingLeft: "5px" }}
                      onClick={editItemToggle}
                    >
                      View{" "}
                    </a>
                  </td>
                  <td className="width-last"></td>
                </tr>
                { payment.value == 'barter' && (
                <tr>
                  <th>
                    <div class="">Barter Description:</div>
                    <div class="">(Giving to Customer)</div>
                  </th>
                  <td className="width-middle">
                    <a
                      className="delete-item"
                      style={{ fontSize: "14px", paddingLeft: "5px" }}
                      onClick={editBarterItemToggle}
                    >
                      View{" "}
                    </a>
                  </td>
                  <td className="width-last"></td>
                </tr>
                )}
                <tr>
                  <th>Print labels:</th>
                  <td className="width-middle copies d-flex align-items-center">
                    <Label className="mb-0 mr-1">Copies:</Label>
                    <Input
                      id="copies"
                      type="number"
                      placeholder="Copies"
                      value={otherDetails.copies}
                      onChange={(e) =>
                        setOtherDetails({
                          ...otherDetails,
                          ["copies"]: e.target.value,
                        })
                      }
                    />
                  </td>

                  <td className="width-last"></td>
                </tr>
                <tr>
                  <th>Signature:</th>
                  <td className="width-middle">
                    <img
                      src={
                        otherDetails.signature ? otherDetails.signature : noSign
                      }
                      width="200px"
                      height="80px"
                    />
                  </td>
                  <td className="width-last text-center">
                    {otherDetails.signature &&
                      props.userType &&
                      props.userType.toLowerCase() === "admin" && (
                        <a
                          style={{ fontSize: "13px" }}
                          className="delete-item"
                          onClick={toggleConfirm}
                        >
                          Remove signature
                        </a>
                      )}
                  </td>
                </tr>
                <tr>
                  <th>Attachments:</th>
                  <td className="width-middle">
                    <input type="file" name="files[]" id="fileuploader"  data-fileuploader-files={JSON.stringify(attachments)}/>
                  </td>
                  <td className="width-last"></td>
                </tr>
              </tbody>
            </Table>
            <div className="text-center mt-3">
              {blocked ? (
                <Button type="button" color="primary" disabled>
                  Blocked
                </Button>
              ) : !otherDetails.oneTimeEdit &&
                props.userType &&
                props.userType.toLowerCase() !== "admin" &&
                otherDetails.signature ? (
                <Button type="button" color="primary" disabled>
                  Can't Update
                </Button>
              ) : (
                <Button
                  id="update-ticket"
                  type="submit"
                  color="primary"
                  onClick={formSubmit}
                >
                  Submit
                </Button>
              )}
            </div>
            {/* </Form> */}
          </CardBody>
        </Card>
      </Container>

      {blockModal && (
        <BlockModal
          show={blockModal}
          toggle={toggleBlock}
          setBlock={setBlocked}
          data={{
            firstName: otherDetails.firstName,
            lastName: otherDetails.lastName,
            ticketId: "C" + props.match.params.id,
          }}
        />
      )}

      {confirm && (
        <ConfirmationDeleteModal
          show={confirm}
          message={<h4>Are you sure you want to remove Signature?</h4>}
          toggle={toggleConfirm}
          confirmed={confirmed}
        />
      )}

      {editItemModal && (
        <ViewAndEditCoinItemsModal
          {...props}
          show={editItemModal}
          isBarter={false}
          id={props.match.params.id}
          toggle={editItemToggle}
        />
      )}

      { editBarterItemModal && (
        <ViewAndEditCoinItemsModal
          {...props}
          show={editBarterItemModal}
          isBarter={true}
          id={props.match.params.id}
          toggle={editBarterItemToggle}
        />
      )}
    </>
  );
}
// }

const mapStateToProps = (state) => {
  return {
    stores: state.ticketReducer.stores,
    clerks: state.ticketReducer.clerks,
    userType: state.loginUserReducer.userType,
    items: state.coinReducer.coinItemdetails,
    barterItems: state.coinReducer.coinBarterItemdetails
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchAllStoresListing: () => dispatch(fetchAllStoresListing()),
    fetchAllClerkListing: () => dispatch(fetchAllClerkListing()),
    fetchCoinItemsListing: (id) => dispatch(fetchCoinItemsListing(id)),
    fetchCoinBarterItemsListing: (id) => dispatch(fetchCoinBarterItemsListing(id)),
    copyTicketDetailsToMetro: (body) => dispatch(copyTicketDetailsToMetro(body)),
    copyTicketDetailsToCoin: (body) => dispatch(copyTicketDetailsToCoin(body)),
    fetchCoinItemsListingSuccess: (body) => dispatch(fetchCoinItemsListingSuccess(body)),
    fetchCoinBarterItemsListingSuccess: (body) => dispatch(fetchCoinBarterItemsListingSuccess(body)),
    resetState: () => dispatch(resetState()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditCoinTicket);
