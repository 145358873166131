import { PostApiWithAuthorizationAction } from "constantFunctions/apiActions";
import React, { useState } from "react";
import NumberFormat from "react-number-format";
import { toast } from "react-toastify";
import classnames from "classnames";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  FormGroup,
  Input,
  Label,
  CustomInput,
} from "reactstrap";

function MakeOfferModal(props) {
  const [amount, setAmount] = useState(props.offer.amount || "0");
  const [description, setDescription] = useState(props.offer.message || "");
  const [loading, setLoading] = useState(false);

  const createOfferHandler = (e) => {
    let body = {
        amount,
        description: description.trim(),
        invoiceId: props.invoiceId,
        invoiceItemId: props.invoiceItemId
    };
    setLoading(true);
    const url = props.offer.id ? "invoices/edit-offer/" + props.offer.id : "invoices/add-new-offer"
    PostApiWithAuthorizationAction(url, body)
      .then((res) => {
        setLoading(false);
        props.fetchSingleCoinInvoice(props.invoiceId)
        props.toggle();
      })
      .catch((err) => {
        setLoading(false);
        if (err.status === 440) {
          toast.error(err.message, { position: toast.POSITION.TOP_CENTER });
          props.history.push("/auth/login");
        }
      });
  };
  return (
    <Modal isOpen={props.show} toggle={props.toggle} autoFocus={false} centered>
      <ModalHeader toggle={props.toggle} style={{fontSize: '20px'}} className="modal-custom-header">
      <i class="fas fa-bullhorn"></i> {props.offer.id ? 'View Offer' : 'Make Offer'}
      </ModalHeader>
      <ModalBody className="py-0 pt-2">
        <FormGroup className="mb-4">
          <Label className="form-control-label" for="c_name">
            Amount<sup className="text-danger" style={{fontSize: '18px', verticalAlign: 'text-bottom'}}>*</sup>
          </Label>
            <NumberFormat
                id="amount"
                allowNegative={true}
                className={classnames({ "input-null": amount === "", 'form-control': true })}
                autoFocus
                style={{display: 'inline'}}
                value={amount.replace('.00', '')}
                onValueChange={(e) => {
                    setAmount(e.value);
                }}
                />
        </FormGroup>
        <FormGroup>
          <Input
            id="description"
            type="textarea"
            placeholder="Do you have any message for us ?"
            rows="5"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </FormGroup>
      </ModalBody>
      <ModalFooter className="py-0 pb-3">
        {loading ? (
          <Button color="primary" disabled>
            Submitting <i className="fas fa-spinner fa-spin"></i>
          </Button>
        ) : (
          <>
            <Button
            id="add-btn"
              color="primary"
              onClick={createOfferHandler}
              disabled={amount == 0}
            >
              Submit
            </Button>{" "}
           {/* <Button color="secondary" onClick={props.toggle}>
              Cancel
        </Button> */}
          </>
        )}
      </ModalFooter>
    </Modal>
  );
}

export default MakeOfferModal;
