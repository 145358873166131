import Header from "components/Headers/Header";
import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Container,
  Table,
} from "reactstrap";
import Unauthorized from "views/Pages/Unauthorized";
import Moment from "react-moment";
import { PostApiWithAuthorizationAction } from "constantFunctions/apiActions";
import { GetApiActionWithAuthorization } from "constantFunctions/apiActions";
import ConfirmationDeleteModal from "views/Modal/ConfirmationDeleteModal";
import EditTransactionModal from "views/Modal/Tranactions/EditTransactionModal";
import useSound from "use-sound";
import coin from "../../assets/coin.mp3";
import receipt from "../../assets/retail.wav";
import metro from "../../assets/ticket.mp3";
import { connect } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import ConfirmDrawerCountModal from "views/Modal/Notices/ConfirmDrawerCountModal";

function Notices(props) {
  const [unauthorized, setUnauthorized] = useState(false);
  const [notices, setNotices] = useState([]);
  const [confirmDismiss, setConfirmDismiss] = useState(false);
  const [confirmDismissAll, setConfirmDismissAll] = useState(false);
  const [editTransaction, setEditTransaction] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);
  const [loadMore, setLoadMore] = useState(true);
  const [id, setId] = useState("");
  const [amount, setAmount] = useState("");
  const [page, setPage] = useState(0);
  const [playMetro] = useSound(metro);
  const [playCoin] = useSound(coin);
  const [playReceipt] = useSound(receipt);
  const [showCountDrawer, setShowCountDrawer] = useState(false);
  const [confirmDrawerCountModal, setConfirmDrawerCountModal] = useState(false);

  //first Load data
  useEffect(() => {
    fetchNewNotices(false);//first load
    setFirstLoad(false);
    //show "Count Drawer" at 9am 1pm & 5pm
    runCountDrawerCheck();
    if (!window.localStorage.getItem('_count_drawer_')) {
      window.localStorage.setItem('_count_drawer_', true);
    }
    setInterval(function() {
      window.location.reload(true);
    },60*60000);
    const countDrawerinterval = setInterval(() => {
      //console.log('drawer count listener.');
      let hour = (new Date()).getHours();
      runCountDrawerCheck();
      let min = parseInt((new Date()).getMinutes());
      if ((hour == 9 && min == 0) || (hour == 13 && min == 0) || (hour == 17 && min == 45)) {
        window.localStorage.setItem('_count_drawer_', true);
      }
    }, 1000);
    return () => clearInterval(countDrawerinterval);
  }, []);

  const runCountDrawerCheck = () => {
    let hour = (new Date()).getHours();
    let min = parseInt((new Date()).getMinutes());
    if ((hour == 9 && min == 0) || (hour == 13 && min == 0) || (hour == 17 && min == 45)) {
      setShowCountDrawer(true);
    } else {
      setShowCountDrawer(false);
    }
  };

  //change handler for notices
  useEffect(() => {//only first entries & new entries
    const interval = setInterval(() => {
      fetchNewNotices(true);
    }, 20000);
    return () => clearInterval(interval);
  }, [notices]);

  const fetchNewNotices = (enableSound = false) => {
    PostApiWithAuthorizationAction("common/get-all-notices", {
      noticeIds: notices.map(n=>n.ticketId)
    }).then((res) => {
      let _notices = notices;
      if (res.existingNotices.length > 0) {
        _notices = res.existingNotices;
      }
      let newNotices = res.data.filter(function(obj) {
        return !_notices.some(function(obj2) {
            return obj.id == obj2.id;
        });
      });
      if (enableSound && newNotices.length > 0) {
        if (newNotices[0].ticketId[0] === "M") {
          playMetro();
        } else if (newNotices[0].ticketId[0] === "C" && newNotices[0].ticketId[1] !== "R") {
          playCoin();
        } else {
          playReceipt();
        }
      }
      setNotices(newNotices.concat(_notices));
    }).catch((err) => {
      setFirstLoad(true);
      if (err.status === 403) {
        setUnauthorized(true);
      } else if (err.status === 440) {
        toast.error(err.message, { position: toast.POSITION.TOP_CENTER });
        props.history.push("/auth/login");
      }
    });
  };

  const fetchNextMoreNotices = () => {
    let nextPage = page + 1;
    fetchMoreNotices(nextPage).then(_notices => {
      setNotices(notices.concat(_notices));
      setPage(nextPage);
    });
  };

  const fetchMoreNotices = (page) => {
    return PostApiWithAuthorizationAction("common/get-all-notices", {
      page,
      more: true
    })
    .then((res) => {
      setLoadMore(res.hasMore);
      return res.data;
    })
    .catch((err) => {
      if (err.status === 403) {
        setUnauthorized(true);
      } else if (err.status === 440) {
        toast.error(err.message, { position: toast.POSITION.TOP_CENTER });
        props.history.push("/auth/login");
      }
    });
  };

  const paid = (pId, index) => {
    PostApiWithAuthorizationAction("common/update-notice-details", {
      id: pId,
      isPaid: true,
    }).then((res) => {
      setFirstLoad(true);
      let _notices = notices;
      _notices[index].isPaid = true;
      setNotices([... _notices]);
    }).catch((err) => {
      setFirstLoad(true);
      if (err.status === 403) {
        setUnauthorized(true);
      } else if (err.status === 440) {
        toast.error(err.message, { position: toast.POSITION.TOP_CENTER });
        props.history.push("/auth/login");
      }
    });
  };
  //-------------------------------------------------------

  //-----------------For dismiss modal-----------------------
  const toggleConfirmDismiss = (id = "") => {
    setId(id);
    setConfirmDismiss(!confirmDismiss);
  };
  const confirmedDismiss = (e) => {
    GetApiActionWithAuthorization("common/dismiss-notice", {
      id: id,
    })
      .then((res) => {
        setFirstLoad(true);
        //toast.success(res.message, { position: toast.POSITION.TOP_CENTER });
        setNotices(res.data);
        toggleConfirmDismiss();
      })
      .catch((err) => {
        toggleConfirmDismiss();
        setFirstLoad(true);
        if (err.status === 403) {
          setUnauthorized(true);
        } else if (err.status === 440) {
          toast.error(err.message, { position: toast.POSITION.TOP_CENTER });
          props.history.push("/auth/login");
        }
      });
  };
  //-------------------------------------------------------

  //-----------------For dismiss all modal-----------------------
  const toggleConfirmDismissAll = (e) => {
    setConfirmDismissAll(!confirmDismissAll);
  };
  const confirmedDismissAll = (e) => {
    GetApiActionWithAuthorization("common/dismiss-notice", {
      id: id,
      all: true,
    })
      .then((res) => {
        setFirstLoad(true);
        //toast.success(res.message, { position: toast.POSITION.TOP_CENTER });
        setNotices(res.data);
        toggleConfirmDismissAll();
      })
      .catch((err) => {
        setFirstLoad(true);
        toggleConfirmDismissAll();
        if (err.status === 403) {
          setUnauthorized(true);
        } else if (err.status === 440) {
          toast.error(err.message, { position: toast.POSITION.TOP_CENTER });
          props.history.push("/auth/login");
        }
      });
  };
  //-------------------------------------------------------

  //-----------------For Edit modal-----------------------
  const toggleEditTransaction = (id = "", amount = "") => {
    setId(id);
    setAmount(amount);
    setEditTransaction(!editTransaction);
  };

  //--------------------------------------------------------
  const toggleConfirmDrawerCount = () => {
    setConfirmDrawerCountModal(!confirmDrawerCountModal);
  };

  const confirmedDrawerCount = () => {
    window.localStorage.setItem('_count_drawer_', false)
    setShowCountDrawer(false)
    setConfirmDrawerCountModal(false);
  };

  //-------------------------------------------------------

  return unauthorized ? (
    <Unauthorized />
  ) : (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--10 main-content" fluid>
        <Card className="card-height shadow">
          <CardBody>
            <div className="mh-300">
              {showCountDrawer && window.localStorage.getItem('_count_drawer_') == 'true' && (
                <>
                  <div
                    className="text-center mb-3"
                    style={{fontSize: "18px"}}>
                    <Button
                      color="warning"
                      onClick={(e) => toggleConfirmDrawerCount()}
                    >
                      <i class="fas fa-calculator"></i> Count Drawer
                    </Button>
                  </div>
                </>
              )}
            <InfiniteScroll
              dataLength={notices.length}
              className="text-center"
              next={fetchNextMoreNotices}
              hasMore={loadMore}
              loader={
                firstLoad === false && (<img src="/Pulse.gif" style={{maxWidth: '40px'}}/>)
              }
            >
              <Table className="notice-table" responsive borderless>
                <tbody>
                  {notices.map((not, i) => {
                    return (
                      <tr
                        key={i}
                        style={{
                          backgroundColor: not.isPaid
                            ? "lightblue"
                            : not.isCheck
                            ? "orange"
                            : "white",
                        }}
                      >
                        <td>{not.ticketId}</td>
                        <td>
                          {not.isCheck === 1 && (
                            <i className="fas fa-money-check-alt"></i>
                          )}{" "}
                          ${not.amount}
                        </td>
                        <td>{not.clerk}</td>
                        <td>
                          <Moment format="HH:mm" tz="America/Los_Angeles">
                            {not.dateAdded.toString().length === 10
                              ? not.dateAdded * 1000
                              : not.dateAdded}
                          </Moment>
                        </td>
                        <td>{not.booth}</td>
                        <td style={{whiteSpace:"normal"}}>
                          {props.userType === "admin" && (
                            <>
                              <Button
                                color="warning"
                                onClick={(e) =>
                                  toggleConfirmDismiss(not.ticketId)
                                }
                              >
                                Dismiss
                              </Button>{" "}
                            </>
                          )}
                          {(props.userType === "admin" || props.userType === "drawer") && (
                            <Button
                              color="secondary"
                              onClick={(e) =>
                                toggleEditTransaction(not.ticketId, not.amount)
                              }
                            >
                              Edit
                            </Button>
                          )}
                          <Button
                            color="info"
                            onClick={(e) => paid(not.ticketId, i)}
                            disabled={not.isPaid}
                          >
                            {not.isPaid ? "Paid" : "Mark as Paid"}
                          </Button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </InfiniteScroll>
            </div>
            {(props.userType === "admin" && notices.length > 0) && (
              <div className="text-center mt-4">
                <Button color="warning" onClick={toggleConfirmDismissAll}>
                  Dismiss All
                </Button>{" "}
              </div>
            )}
          </CardBody>
        </Card>
      </Container>
      {confirmDrawerCountModal && (
        <ConfirmDrawerCountModal
          show={confirmDrawerCountModal}
          toggle={toggleConfirmDrawerCount}
          confirmedDrawerCount={confirmedDrawerCount}
        />
      )}
      {confirmDismiss && (
        <ConfirmationDeleteModal
          show={confirmDismiss}
          message={<h4>Are you sure you want to dismiss {id} Notice ?</h4>}
          toggle={toggleConfirmDismiss}
          confirmed={confirmedDismiss}
        />
      )}
      {confirmDismissAll && (
        <ConfirmationDeleteModal
          show={confirmDismissAll}
          message={<h4>Are you sure you want to dismiss all Notices ?</h4>}
          toggle={toggleConfirmDismissAll}
          confirmed={confirmedDismissAll}
        />
      )}
      {editTransaction && (
        <EditTransactionModal
          show={editTransaction}
          toggle={toggleEditTransaction}
          id={id}
          amount={amount}
        />
      )}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    userType: state.loginUserReducer.userType,
  };
};

export default connect(mapStateToProps, null)(Notices);
