import Header from "components/Headers/Header";
import React, { useEffect, useState } from "react";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    Form,
    FormGroup,
    Input,
    Label,
    Row,
    Table,
} from "reactstrap";
import { toast } from "react-toastify";
import Unauthorized from "views/Pages/Unauthorized";
import Moment from "react-moment";
import moment from "moment";
import { GetApiActionWithAuthorization, PostApiWithAuthorizationAction } from "../../constantFunctions/apiActions";
import ReactPaginate from "react-paginate";
import { monthOptions, dayOptions, paidOptions } from "../../constants/ticketConstants";
import { DropdownList } from "react-widgets";
import { resetState } from "../../redux/common/commonAction";
import { connect } from "react-redux";
import { referTypeOptions } from "constants/ticketConstants";

function Refer(props) {
    let startTimestamp = moment().tz('US/Pacific').subtract(2, 'months');
    let endTimestamp = moment().tz('US/Pacific').subtract(1, 'months');
    const [unauthorized, setUnauthorized] = useState(false);
    const [years, setYears] = useState([]);
    const [repeatBreakDown, setRepeatBreakDown] = useState(false);
    const [referData, setReferData] = useState({
        referType: 'fromx',
        referTypeLabel: 'Refer',
    });
    const [filter, setFilter] = useState({
        page: 0,
        referType: 'fromx',
        startMonth: monthOptions.find(m=>m.value==(startTimestamp.format('M'))).value,
        startDay: startTimestamp.endOf('months').format('D'),
        startYear: startTimestamp.format('YYYY'),
        endMonth: monthOptions.find(m=>m.value==(endTimestamp.format('M'))).value,
        endDay: endTimestamp.endOf('months').format('D'),
        endYear: endTimestamp.format('YYYY')
    });

    useEffect(() => {
        load();
        //
        GetApiActionWithAuthorization("invoices/get-all-years").then((res) => {
            setYears(res.data)
        });
    }, []);

    if (referData.error && !unauthorized) {
        if (referData.error.status === 403) {
          setUnauthorized(true);
        } else if (referData.error.status === 440) {
          toast.error(referData.error.message, {
            position: toast.POSITION.TOP_CENTER,
          });
          props.history.push("/auth/login");
        }
    }

    const resetFilter = () => {
        setFilter({
            ... filter,
            repeatBreakDown: false,
            page: 0
        })
    };

  const load = (params) => {
    Object.assign(filter, params);
    filter.repeatBreakDown = repeatBreakDown;
    GetApiActionWithAuthorization("refer/get-all-data/" + filter.referType, filter)
    .then((res) => {
        res.referTypeLabel = referTypeOptions.find(w=>w.value===res.referType).label;
        setReferData(res)
    })
    .catch((err) => {
      if (err.status === 403) {
        setUnauthorized(true);
      } else if (err.status === 440) {
        props.resetState();
        toast.error(err.message, { position: toast.POSITION.TOP_CENTER });
        props.history.push("/auth/login");
      }
    });
  };
    const hasReferTableData = (table) => {
        return (typeof referData.data !== 'undefined' && typeof referData.data[referData.referType][table] !== 'undefined');
        
    };
    const renderReferHeadTableData = () => {
        return (
            <thead className="thead-light">
                <tr>
                    <th scope="col">Total Tickets</th>
                    <th scope="col">refer</th>
                    <th scope="col">Spent</th>
                    <th scope="col">Profit</th>
                </tr>
            </thead>
        );
    };

    const renderReferBodyTableData = (table) => {
        if (referData.hasOwnProperty('data')) {
            return referData.data[referData.referType][table].map((_data, i) => {
                return (
                    <>
                        <tr>
                            <td scope="col">{_data.count}</td>
                            <td scope="col">{_data.refer}</td>
                            <td scope="col">{parseFloat(_data.ticketAmount).toFixed(2).replace('.00', '')}</td>
                            <td scope="col">{parseFloat(_data.profit).toFixed(2).replace('.00', '')}</td>
                        </tr>
                    </>
                )
            });
        }
    };

    //
    return unauthorized ? (
        <Unauthorized />
      ) : (
        <>
          <Header />
          {/* Page content */}
          <Container className="mt--9 main-content" fluid>
            <Card className="card-height shadow">
              <CardHeader>
                <div className="text-center">
                    <h1>Keywords</h1>
                </div>
              </CardHeader>
              <CardBody className="checks-main-content page-main-content">
                <Row className="mb-3">
                    <Col md="12">
                        <Row>
                        <Col md="5" className="d-flex d-flex-full mt-1">
                            <DropdownList
                                data={referTypeOptions}
                                value={filter.referType}
                                placeholder="Type"
                                textField="label"
                                valueField='value'
                                onChange={(e) => {
                                    setFilter({
                                        ... filter,
                                        referType: e.value
                                    })
                                    load({
                                        referType: e.value
                                    });
                                }}
                            />
                            <span class="mx-1" style={{fontSize: "22px"}}>Start: </span>
                            <DropdownList
                                data={monthOptions}
                                value={filter.startMonth}
                                placeholder="Month"
                                textField="label"
                                valueField='value'
                                onChange={(e) => {
                                    setFilter({
                                        ... filter,
                                        startMonth: e.value
                                    })
                                }}
                            />
                        <div class="mx-1" style={{fontSize: "24px"}}> / </div>
                        <DropdownList
                            data={dayOptions}
                            value={filter.startDay}
                            placeholder="Day"
                            onChange={(e) => {
                            setFilter({
                                ... filter,
                                startDay: e
                            })
                            }}
                        />
                        <span class="mx-1" style={{fontSize: "24px"}}> / </span>
                        <DropdownList
                            data={years}
                            value={filter.startYear}
                            placeholder="Year"
                            onChange={(e) => {
                            setFilter({
                                ... filter,
                                startYear: e
                            })
                            }}
                        />
                        </Col>
                        <Col md="4" className="d-flex d-flex-full pl-0 mt-1">
                            <span class="mx-1" style={{fontSize: "22px"}}>End: </span>
                            <DropdownList
                            data={monthOptions}
                            value={filter.endMonth}
                            placeholder="Month"
                            textField="label"
                            valueField='value'
                            onChange={(e) => {
                                setFilter({
                                ... filter,
                                endMonth: e.value
                                })
                            }}
                            />
                        <div class="mx-1" style={{fontSize: "24px"}}> / </div>
                        <DropdownList
                            data={dayOptions}
                            value={filter.endDay}
                            placeholder="Day"
                            onChange={(e) => {
                            setFilter({
                                ... filter,
                                endDay: e
                            })
                            }}
                        />
                        <span class="mx-1" style={{fontSize: "24px"}}> / </span>
                        <DropdownList
                            data={years}
                            value={filter.endYear}
                            placeholder="Year"
                            onChange={(e) => {
                                setFilter({
                                    ... filter,
                                    endYear: e
                                })
                            }}
                        />
                        </Col>
                        <Col md="3" className="d-flex d-flex-full mt-1 pl-0" style={{justifyContent: 'center'}}>
                            <Label className="form-control-label pt-1" check>
                                <Input
                                type="checkbox"
                                id="showFirstTimeOnly"
                                checked={repeatBreakDown}
                                onChange={(e) => {
                                    setRepeatBreakDown(e.target.checked)
                                }}
                                />{" "}
                                Breakdown Repeats
                            </Label>
                            <Button
                            id="btn-get-worksheet"
                            size="sm"
                            className="ml-2"
                            onClick={(e) => {
                                setFilter({
                                    ... filter,
                                    page: 0
                                })
                                load({
                                    page: 0
                                });
                            }}
                            color="primary">Submit</Button>
                        </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col md="4">
                    { hasReferTableData('ticket') && (
                        <>
                            <div className="font-weight-bold">{referData.referTypeLabel} ticket</div>
                            <Table className="ticket-table table-listing items-list-table" responsive bordered>
                                {renderReferHeadTableData()}
                                <tbody>{renderReferBodyTableData('ticket')}</tbody>
                            </Table>
                        </>
                    )}
                    </Col>
                    <Col md="4">
                    { hasReferTableData('new_coin') && (
                        <>
                        <div className="font-weight-bold">{referData.referTypeLabel} newCoin</div>
                        <Table className="ticket-table table-listing items-list-table" responsive bordered>
                            {renderReferHeadTableData()}
                            <tbody>{renderReferBodyTableData('new_coin')}</tbody>
                        </Table>
                        </>
                    )}
                    </Col>
                    <Col md="4">
                        { hasReferTableData('receipt') && (
                            <>
                            <div className="font-weight-bold">{referData.referTypeLabel} receipt</div>
                            <Table className="ticket-table table-listing items-list-table" responsive bordered>
                                {renderReferHeadTableData()}
                                <tbody>{renderReferBodyTableData('receipt')}</tbody>
                            </Table>
                            </>
                        )}
                        { hasReferTableData('coin') && (
                            <>
                            <div className="font-weight-bold">{referData.referTypeLabel} coin</div>
                            <Table className="ticket-table table-listing items-list-table" responsive bordered>
                                {renderReferHeadTableData()}
                                <tbody>{renderReferBodyTableData('coin')}</tbody>
                            </Table>
                            </>
                        )}
                        { hasReferTableData('bullion') && (
                            <>
                                <div className="font-weight-bold">{referData.referTypeLabel} bullion</div>
                                <Table className="ticket-table table-listing items-list-table" responsive bordered>
                                    {renderReferHeadTableData()}
                                    <tbody>{renderReferBodyTableData('bullion')}</tbody>
                                </Table>
                            </>
                        )}
                    </Col>
                </Row>
              </CardBody>
            </Card>
          </Container>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        userType: state.loginUserReducer.userType
    };
  };
  
const mapDispatchToProps = (dispatch) => {
    return {
        resetState: () => dispatch(resetState())
    };
};
  
export default connect(mapStateToProps, mapDispatchToProps)(Refer);