import { GetApiActionWithAuthorization } from "constantFunctions/apiActions";
import React, { useEffect, useState } from "react";
import Moment from "react-moment";
import loaderSpinner from "../../assets/img/loader.gif";

function MetroInvoice(props) {
  const [ticketInfo, setTicketInfo] = useState({inventories: []});
  const [ticketPrefix, setTicketPrefix] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let id = props.match.params.id;
    let pref = props.match.params.type === "metro" ? "M" : "C";
    setTicketPrefix(pref);
    let url =
      props.match.params.type === "metro"
        ? `metro-tickets/get-particular-ticket-data/${id}`
        : `coin-tickets/get-particular-ticket-data/${id}`;
        setLoading(true);
    GetApiActionWithAuthorization(
      url
    ).then((res) => {
        setLoading(false);
        setTicketInfo(res.data);
    });
  }, []);

  const renderTicketItems = () => {
    return ticketInfo.inventories.map((item, i) => {
      const lastIndex = (i == (ticketInfo.inventories.length - 1))
      return (
        <>
          <span>{ item.qty } { item.item.name } ${item.cost}</span>{!lastIndex ? "," : ""}
        </>
      )
    })
  };

  return (
    <>
      <table
        border="0"
        cellPadding="0"
        cellSpacing="0"
        width="100%"
        style={{ borderCollapse: "collapse" }}
      >
        <tbody>
          <tr>
            <td>
              <table
                border="0"
                cellPadding="0"
                cellSpacing="0"
                width="100%"
                style={{ borderCollapse: "collapse", padding: "5px" }}
              >
                <tbody>
                  <tr>
                    <td>
                      <table
                        border="0"
                        cellPadding="0"
                        cellSpacing="0"
                        width="100%"
                        style={{ borderCollapse: "collapse" }}
                      >
                        <tbody>
                          <tr>
                            <td>
                              <table
                                border="0"
                                cellPadding="0"
                                cellSpacing="0"
                                width="100%"
                                style={{ borderCollapse: "collapse" }}
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      align="left"
                                      style={{
                                        fontSize: "16px",
                                        paddingBottom: "5px",
                                        color: "black",
                                      }}
                                    >
                                      Nevada Coin Mart Inc. &#174;
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      align="left"
                                      style={{
                                        fontSize: "16px",
                                        paddingBottom: "5px",
                                        color: "black",
                                      }}
                                    >
                                      4065 S. Jones Blvd.
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      align="left"
                                      style={{
                                        fontSize: "16px",
                                        paddingBottom: "5px",
                                        color: "black",
                                      }}
                                    >
                                      Las Vegas, NV 89103
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      align="left"
                                      style={{
                                        fontSize: "16px",
                                        paddingBottom: "10px",
                                        color: "black",
                                      }}
                                    >
                                      702-998-4000
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      align="left"
                                      style={{
                                        fontSize: "16px",
                                        paddingBottom: "5px",
                                        color: "black",
                                      }}
                                    >
                                      Clerk: {ticketInfo.clerk}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                            <td>
                              <table
                                border="0"
                                cellPadding="0"
                                cellSpacing="0"
                                width="100%"
                                style={{ borderCollapse: "collapse" }}
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      align="right"
                                      style={{
                                        fontSize: "16px",
                                        paddingBottom: "10px",
                                        color: "black",
                                      }}
                                    >
                                      Ticket Number:{" "}
                                      {ticketPrefix + ticketInfo.id}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      align="right"
                                      style={{
                                        fontSize: "16px",
                                        paddingBottom: "10px",
                                        color: "black",
                                      }}
                                    >
                                      <Moment
                                        format="MM/DD/YY HH:mm"
                                        tz="America/Los_Angeles"
                                      >
                                        {ticketInfo.ticketTime &&
                                          (ticketInfo.ticketTime.toString()
                                            .length === 10
                                            ? new Date(
                                                ticketInfo.ticketTime * 1000
                                              )
                                            : new Date(ticketInfo.ticketTime))}
                                      </Moment>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      align="right"
                                      style={{
                                        fontSize: "16px",
                                        paddingBottom: "10px",
                                        color: "black",
                                      }}
                                    >
                                      Transaction Amount: $
                                      {ticketInfo.ticketAmount &&
                                        ticketInfo.ticketAmount.toFixed(2)}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      align="right"
                                      style={{
                                        fontSize: "16px",
                                        paddingBottom: "5px",
                                        color: "black",
                                      }}
                                    >
                                      Window: {ticketInfo.booth}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <table
                        border="0"
                        cellPadding="0"
                        cellSpacing="0"
                        width="100%"
                        style={{ borderCollapse: "collapse" }}
                      >
                        <tbody>
                          <tr>
                            <td>
                              <table
                                border="0"
                                cellPadding="0"
                                cellSpacing="0"
                                width="100%"
                                style={{ borderCollapse: "collapse" }}
                              >
                                <tbody>
                                  <tr>
                                    <th
                                      style={{
                                        fontSize: "16px",
                                        paddingBottom: "5px",
                                        color: "black",
                                        paddingTop: "10px",
                                      }}
                                    >
                                      Seller:
                                    </th>
                                  </tr>
                                  <tr>
                                    <td
                                      style={{
                                        fontSize: "16px",
                                        paddingBottom: "5px",
                                        color: "black",
                                      }}
                                    >
                                      {ticketInfo.firstName &&
                                        ticketInfo.firstName.toUpperCase() +
                                          " " +
                                          (ticketInfo.middleName
                                            ? ticketInfo.middleName.toUpperCase() +
                                              " "
                                            : "") +
                                          ticketInfo.lastName.toUpperCase()}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      style={{
                                        fontSize: "16px",
                                        paddingBottom: "5px",
                                        color: "black",
                                      }}
                                    >
                                      {ticketInfo.address}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      style={{
                                        fontSize: "16px",
                                        paddingBottom: "5px",
                                        color: "black",
                                      }}
                                    >
                                      {ticketInfo.city +
                                        " " +
                                        ticketInfo.state +
                                        " " +
                                        ticketInfo.zip}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                            <td>
                              <table
                                border="0"
                                cellPadding="0"
                                cellSpacing="0"
                                width="100%"
                                style={{ borderCollapse: "collapse" }}
                              >
                                <tbody>
                                  <tr>
                                    <th
                                      style={{
                                        fontSize: "16px",
                                        textAlign: "end",
                                        paddingBottom: "5px",
                                        color: "black",
                                        paddingTop: "10px",
                                      }}
                                    >
                                      ID Information:
                                    </th>
                                  </tr>
                                  <tr>
                                    <td
                                      align="right"
                                      style={{
                                        fontSize: "16px",
                                        paddingBottom: "5px",
                                        color: "black",
                                      }}
                                    >
                                      Height: {ticketInfo.height}. Weight:{" "}
                                      {ticketInfo.weight} Lb. Date of Birth:{" "}
                                      {ticketInfo.dOB}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      align="right"
                                      style={{
                                        fontSize: "16px",
                                        paddingBottom: "5px",
                                        color: "black",
                                      }}
                                    >
                                      Gender: {"s"}. Eye: {"e"}. Hair: {"Blk"}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      align="right"
                                      style={{
                                        fontSize: "16px",
                                        paddingBottom: "5px",
                                        color: "black",
                                      }}
                                    >
                                      ID Type: {"t"}. ID Number: {"n"}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <table
                        border="0"
                        cellPadding="0"
                        cellSpacing="0"
                        width="100%"
                        style={{ borderCollapse: "collapse" }}
                      >
                        <tbody>
                          <tr>
                            <td colSpan="2">
                              <table
                                border="0"
                                cellPadding="0"
                                cellSpacing="0"
                                width="100%"
                                style={{ borderCollapse: "collapse" }}
                              >
                                <tbody>
                                  <tr>
                                    <th
                                      align="left"
                                      style={{
                                        fontSize: "16px",
                                        paddingBottom: "5px",
                                        color: "black",
                                        paddingTop: "10px",
                                      }}
                                    >
                                      Terms Of Sale:
                                    </th>
                                  </tr>
                                  <tr>
                                    <td
                                      style={{
                                        fontSize: "16px",
                                        paddingBottom: "5px",
                                        color: "black",
                                      }}
                                    >
                                      By signing below I,{" "}
                                      {ticketInfo.firstName &&
                                        ticketInfo.firstName.toUpperCase() +
                                          " " +
                                          (ticketInfo.middleName
                                            ? ticketInfo.middleName.toUpperCase() +
                                              " "
                                            : "") +
                                          ticketInfo.lastName.toUpperCase()}
                                      ,hereby affirm that I am the owner of, or
                                      have legal right to sell the items for
                                      which i am receiving a payment of $
                                      {ticketInfo.ticketAmount &&
                                        ticketInfo.ticketAmount.toFixed(2)}
                                      . All items involved in this transaction
                                      are being SOLD to Nevada Coin Mart Inc.
                                      (Clark County Secondhand Dealer Licence
                                      #2000123.735). These items can not be
                                      re-bought by the Seller(NRS 647.019). All
                                      transactions are final may not be voided
                                      (per instruction of LVMPD
                                      Financial/Property Crime Details).{" "}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              {ticketPrefix == "C" && (
                                <>
                                  <div style={{fontSize: '16px', color: 'black'}}>
                                    <hr className="mb-3 mt-2"/>
                                      <b>Items Sold:</b> { renderTicketItems() }
                                    <hr className="mt-3 mb-2"/>
                                  </div>
                                </>
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td colSpan="2">
                              <table
                                border="0"
                                cellPadding="0"
                                cellSpacing="0"
                                width="100%"
                                style={{ borderCollapse: "collapse" }}
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      align="left"
                                      style={{
                                        fontSize: "16px",
                                        paddingBottom: "5px",
                                        color: "black",
                                        paddingTop: "10px",
                                      }}
                                    >
                                      Seller's Signature Below   <Moment
                                        format="MM/DD/YY HH:mm"
                                        tz="America/Los_Angeles"
                                      >
                                        {ticketInfo.ticketTime &&
                                          (ticketInfo.ticketTime.toString()
                                            .length === 10
                                            ? new Date(
                                                ticketInfo.ticketTime * 1000
                                              )
                                            : new Date(ticketInfo.ticketTime))}
                                      </Moment>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td style={{ width: "50%" }}>
                                      <img
                                        src={ticketInfo.signature}
                                        style={{ maxWidth: "60%" }}
                                      />
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>

      {loading && (
          <div className="loaderSpinner">
            <img src={loaderSpinner} alt="" />
          </div>
        )}
    </>
  );
}

export default MetroInvoice;
