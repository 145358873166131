import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { resetState } from "../../redux/common/commonAction";
import Unauthorized from "views/Pages/Unauthorized";
import Header from "components/Headers/Header.js";
import {
  Card,
  Container,
  CardHeader,
  CardBody,
  Row,
  Col,
  Label,
  Input,
  Button
} from "reactstrap";
import { GetApiActionWithAuthorization } from "constantFunctions/apiActions";
import { PostApiWithAuthorizationAction } from "constantFunctions/apiActions";
import { toast } from "react-toastify";

function CheckSetting(props) {
  const [unauthorized, setUnauthorized] = useState(false);
  const [metroCheckNumberPrefix, setMetroCheckNumberPrefix] = useState(0);
  const [coinCheckNumberPrefix, setCoinCheckNumberPrefix] = useState(0);
  const [receiptCheckNumberPrefix, setReceiptCheckNumberPrefix] = useState(0);
  const [jewelryreceiptCheckNumberPrefix, setJewelryreceiptCheckNumberPrefix] = useState(0);

  useEffect(() => {
    GetApiActionWithAuthorization("common/get-check-setting").then((res) => {
      setMetroCheckNumberPrefix(res.data.metroCheckNumberPrefix);
      setCoinCheckNumberPrefix(res.data.coinCheckNumberPrefix);
      setReceiptCheckNumberPrefix(res.data.receiptCheckNumberPrefix);
      setJewelryreceiptCheckNumberPrefix(res.data.jewelryreceiptCheckNumberPrefix);
    }).catch((err) => {
      if (err.status === 440) {
        toast.error(err.message, { position: toast.POSITION.TOP_CENTER });
        props.history.push("/auth/login");
      }
    });
  }, []);

  const saveCheckSettingHandler = (e) => {
    let body = {
      metroCheckNumberPrefix,
      coinCheckNumberPrefix,
      receiptCheckNumberPrefix,
      jewelryreceiptCheckNumberPrefix
    };
    PostApiWithAuthorizationAction("common/save-check-setting", body)
      .then((res) => {
        toast.success("Setting Updated Successfully!", { position: toast.POSITION.TOP_RIGHT });
      })
      .catch((err) => {
        if (err.status === 440) {
          toast.error(err.message, { position: toast.POSITION.TOP_CENTER });
          props.history.push("/auth/login");
        }
      });
  };

  return unauthorized ? (
    <Unauthorized />
  ) : (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--9 main-content" fluid>
        <Card className="card-height shadow">
          <CardHeader className="">
            <div className="text-center">
              <h1>Check Setting</h1>
            </div>
          </CardHeader>
          <CardBody className="checks-main-content page-main-content">
            <Row className="mb-1">
              <Col md="3">
                <Label
                  className="form-control-label font-weight-bold"
                  for="metro_check_prefix">Metro Check# Prefix</Label>
                <Input
                  id="metroCheckNumberPrefix"
                  type="text"
                  placeholder="Metro Check Number Prefix"
                  value={metroCheckNumberPrefix}
                  onChange={(e) => setMetroCheckNumberPrefix(e.target.value)}
                />
                <span className="text-danger"><small>*required</small>*</span>
              </Col>
              <Col md="3">
                <Label
                  className="form-control-label font-weight-bold"
                  for="coin_check_prefix">Coin Check# Prefix</Label>
                <Input
                    id="coinCheckNumberPrefix"
                    type="text"
                    placeholder="Coin Check Number Prefix"
                    value={coinCheckNumberPrefix}
                    onChange={(e) => setCoinCheckNumberPrefix(e.target.value)}
                  />
                <span className="text-danger"><small>*required</small>*</span>
              </Col>
              <Col md="3">
                <Label
                  className="form-control-label font-weight-bold"
                  for="receipt_check_prefix">Receipt Check# Prefix</Label>
                <Input
                    id="receiptCheckNumberPrefix"
                    type="text"
                    placeholder="Receipt Check Number Prefix"
                    value={receiptCheckNumberPrefix}
                    onChange={(e) => setReceiptCheckNumberPrefix(e.target.value)}
                  />
                <span className="text-danger"><small>*required</small>*</span>
              </Col>
              <Col md="3">
                <Label
                  className="form-control-label font-weight-bold"
                  for="receipt_check_prefix">Jewelry Receipt Check# Prefix</Label>
                <Input
                    id="jewelryreceiptCheckNumberPrefix"
                    type="text"
                    placeholder="Jewelry Receipt Check Number Prefix"
                    value={jewelryreceiptCheckNumberPrefix}
                    onChange={(e) => setJewelryreceiptCheckNumberPrefix(e.target.value)}
                  />
                <span className="text-danger"><small>*required</small>*</span>
              </Col>
            </Row>
            <div className="text-right">
              <Button
                id="add-btn"
                color="primary"
                onClick={saveCheckSettingHandler}
                disabled={metroCheckNumberPrefix <= 0 || coinCheckNumberPrefix <= 0 || receiptCheckNumberPrefix <= 0}
              >
                Save Changes
              </Button>
            </div>
          </CardBody>
        </Card>
      </Container>
    </>
  );
}


const mapStateToProps = (state) => {
    return {
      
    };
  };
  
  const mapDispatchToProps = (dispatch) => {
    return {
      resetState: () => dispatch(resetState()),
    };
  };
  
  export default connect(mapStateToProps, mapDispatchToProps)(CheckSetting);