import { PostApiWithAuthorizationAction } from "constantFunctions/apiActions";
import React, { useState } from "react";
import { toast } from "react-toastify";
import {
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button, Row, Col, FormGroup, Label, Input
} from "reactstrap";
import noSign from "../../assets/img/noSign.png";

function ViewTicketChangeValue(props) {
  const renderAllItemsHTML = (items) => {
    return items.map((item, i) => {
      return (
        <tr key={i}>
          <td className="input-td">{item.qty}</td>
          <td className="input-td">
            {(typeof item.name !== "undefined") ? item.name.name : ""}
          </td>
          <td className="input-td">${item.unit}</td>
          <td className="input-td">${item.cost}</td>
        </tr>
      );
    });
  };
  //
  const renderAttachments = (data) => {
    return  data.attachments.map((d, i) => {
      return (
        <>
          <img
            src={d.thumbnailUrl}
            style={{maxWidth: '150px', marginRight: '10px'}}
          />
        </>
      )
    });
  };

    const render = () => {
        let data = props.data;
        if (typeof data !== 'object') {
          data = JSON.parse(data);
        }
        let showColumns = [
          {
            col: 'ticketAmount',
            label: 'Ticket Amount'
          },
          {
            col: 'firstName',
            label: 'First Name'
          },
          {
            col: 'middleName',
            label: 'Middle Name'
          },
          {
            col: 'lastName',
            label: 'Last Name'
          },
          {
            col: 'address',
            label: 'Address'
          },
          {
            col: 'state',
            label: 'State'
          },
          {
            col: 'city',
            label: 'City'
          },
          {
            col: 'zip',
            label: 'ZIP'
          },
          {
            col: 'dOB',
            label: 'DOB'
          },
          {
            col: 'height',
            label: 'Height'
          },
          {
            col: 'weight',
            label: 'Weight'
          },
          {
            col: 'fromx',
            label: 'From'
          },
          {
            col: 'clerk',
            label: 'Clerk'
          },
          {
            col: 'sex',
            label: 'Gender'
          },
          {
            col: 'eyes',
            label: 'Eyes'
          },
          {
            col: 'booth',
            label: 'Booth'
          },
          {
            col: 'station',
            label: 'Station'
          },
          {
            col: 'payment',
            label: 'Payment'
          },
          {
            col: 'dLNo',
            label: 'DL Number'
          }
        ];
        return (
          <>
            <Row style={{ fontWeight: 'bold'}}>
              {(
                Object.keys(data).map(function(key) {
                  let columnIndex = showColumns.findIndex(v=>v.col==key);
                  return (
                    <>
                      {(typeof data[key] !== "undefined" && columnIndex >= 0) && (
                        <Col md="6"><b>{showColumns[columnIndex].label}</b>: <span style={{color: 'red'}}>{data[key]}</span></Col>
                      )}
                    </>
                  );
                })
              )}
            </Row>
            <div style={{textAlign: 'center', marginTop: '5px'}}>
              <img
                src={
                  data.signature ? data.signature : noSign
                }
                width="200px"
                height="80px"
              />
            </div>
            {(typeof data.items !== "undefined") && (
              <>
              <div
                  className="text-center"
                  style={{fontWeight: 'bold'}}>
                  <u>Items</u>
              </div>
              <Table
                  className="add-receipt-item-table mt-2"
                  bordered
                  striped>
                <thead>
                  <tr>
                    <th className="qty-col">Qty</th>
                    <th className="desc-col">DESC</th>
                    <th className="unit-col">UNIT($)</th>
                    <th className="amount-col">Amount($)</th>
                  </tr>
                </thead>
                <tbody>
                  {renderAllItemsHTML(data.items)}
                </tbody>
              </Table>
              </>
            )}
            {(typeof data.attachments !== 'undefined')
                && data.attachments.length > 0
                && (
              <div style={{textAlign: 'center', marginTop: '5px'}}>
                <div>
                  <strong>Attachments:</strong>
                </div>
                {renderAttachments(data)}
              </div>
            )}
          </>
        );
    }
  return (
    <Modal isOpen={props.show} toggle={props.toggle}>
      <ModalHeader toggle={props.toggle}>View Changes</ModalHeader>
      <ModalBody className="py-0 pb-3">
        {render()}
      </ModalBody>
    </Modal>
  );
}

export default ViewTicketChangeValue;
