import Header from "components/Headers/Header";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { DropdownList } from "react-widgets";
import classnames from "classnames";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Container,
  Input,
  Table,
  Row,
  Col
} from "reactstrap";
import Unauthorized from "views/Pages/Unauthorized";
import {
  monthOptions,
  dayOptions,
} from "../../constants/ticketConstants";
import { resetState } from "redux/common/commonAction";
import {
    saveInvoiceFormDetails,
    saveItemsDetails,
    resetAddInvoiceForm
} from "../../redux/Invoices/InvoicesAction";
import { GetApiActionWithAuthorization } from "constantFunctions/apiActions";
import { PostApiWithAuthorizationAction } from "constantFunctions/apiActions";
import { validateInvoiceForm } from "../../constantFunctions/formValidations";
import moment from 'moment';
import NumberFormat from 'react-number-format';

function CreateNewInvoice(props) {
    const [unauthorized, setUnauthorized] = useState(false);
    const [years, setYears] = useState([]);
    const [loading, setLoading] = useState(false);
    const { match: { params } } = props;

    useEffect(() => {
        props.resetAddInvoiceForm();
        if (!params.id) {
          props.saveItemsDetails([{
            itemDescription: '',
            dateTime: '',
            price: '0.00'
          }]);
        } else {
            GetApiActionWithAuthorization("invoices/get-single-invoice-data/" + params.id).then((res) => {
                props.saveInvoiceFormDetails({
                  name: "billedTo",
                  value: res.data.billedTo
                })
                //delivery date
                let deliveryDate = moment(res.data.deliveryDate*1000);
                //items
                let items = res.items;
                props.saveItemsDetails(items);
                props.saveInvoiceFormDetails({
                  name: "checkNumber",
                  value: res.data.checkNumber
                })
                props.saveInvoiceFormDetails({
                  name: "deliveryDateDay",
                  value: deliveryDate.format('D')
                })
                props.saveInvoiceFormDetails({
                  name: "deliveryDateMonth",
                  value: monthOptions.find(i=>i.value==deliveryDate.format('M'))
                })
                props.saveInvoiceFormDetails({
                  name: "deliveryDateYear",
                  value: deliveryDate.format('Y')
                })
            });
        }
        GetApiActionWithAuthorization("invoices/get-all-years").then((res) => {
            setYears(res.data)
        });
    }, []);

    const addRowInItem = (e) => {
        let obj = {
          itemDescription: "",
          price: "0.00",
          dateTime: ""
        };
        let olderItems = props.invoiceItemsDetails.items;
        olderItems.push(obj);
        props.saveItemsDetails(olderItems);
      };
    
      const removeRowInItem = (index) => {
        let olderItems = props.invoiceItemsDetails.items;
        olderItems.splice(index, 1);
        props.saveItemsDetails(olderItems);
      };
    
      const changeItemValues = (name, value, index) => {
        let temp = props.invoiceItemsDetails.items;
        let itemObj = temp[index];
        itemObj[name] = value;
        temp[index] = itemObj;

        if (name === "price") {
            itemObj["price"] = parseFloat(value).toFixed(2);
        }
        props.saveItemsDetails(temp);
      };    

    const formSubmitHandler = (e) => {
        let validation = validateInvoiceForm(
            { ...props.invoiceFormDetails },
            props.invoiceItemsDetails.items
        );
        if (validation.success) {
            let body = {
              formDetails: {
                ...props.invoiceFormDetails,
                deliveryDateMonth: props.invoiceFormDetails.deliveryDateMonth.value
              },
              items: props.invoiceItemsDetails.items,
            };
      
            setLoading(true);
            PostApiWithAuthorizationAction("invoices/save/" + (params.id ? params.id : ""), body)
              .then((res) => {
                setLoading(false);
                //toast.success(res.message, { position: toast.POSITION.TOP_CENTER });
                props.resetAddInvoiceForm();
                props.history.push("/portal/invoices");
              })
              .catch((err) => {
                setLoading(false);
                if (err.status === 403) {
                  setUnauthorized(true);
                } else if (err.status === 440) {
                  props.resetState();
                  toast.error(err.message, { position: toast.POSITION.TOP_CENTER });
                  props.history.push("/auth/login");
                }
              });
        } else {
            toast.error(validation.err, {
              position: toast.POSITION.TOP_CENTER,
              closeOnClick: true,
            });
        }
    };

    const renderAllItemsHTML = (e) => {
        return props.invoiceItemsDetails.items.map((item, i) => {
            return (
              <tr key={i} id={"invoice-item-row" + i}>
                <td className="input-td">
                    <Input
                        id="description"
                        type="text"
                        value={item.itemDescription}
                        className={classnames({
                            "input-null": item.itemDescription === "",
                        })}
                        onChange={(e) => {
                            changeItemValues("itemDescription", e.target.value, i);
                        }}
                    />
                </td>
                <td className="input-td">
                  <Input
                    id="dateTime"
                    type="text"
                    value={item.dateTime}
                    onChange={(e) => {
                      changeItemValues("dateTime", e.target.value, i);
                    }}
                  />
                </td>
                <td className="input-td">
                  <NumberFormat
                    id="price"
                    allowNegative={true}
                    className={classnames({ "input-null": item.price === "", 'form-control': true })}
                    value={item.price.replace('.00', '')}
                    onValueChange={(e) => {
                      changeItemValues("price", e.value, i);
                    }}
                  />
                </td>
                <td className="action-col text-center">
                  {(props.invoiceItemsDetails.items.length == (i+1)) && (
                    <Button
                      id="add-more"
                      className="icon-cross"
                      color="primary"
                      title="Add more"
                      onClick={() => {
                        if (item.price !== "" && item.itemDescription !== "") {
                          addRowInItem();
                        }
                      }}
                    >
                      <i className="fas fa-plus-square"></i>
                    </Button>
                  )}
                  {(i >= 0 && props.invoiceItemsDetails.items.length > 1 || params.id && props.invoiceItemsDetails.items.length > 1)&& (
                  <Button
                    id="remove"
                    className="icon-cross"
                    color="danger"
                    title="remove"
                    onClick={() => {
                      removeRowInItem(i);
                    }}
                  >
                    <i className="fas fa-times"></i>
                  </Button>)}
                </td>
              </tr>
            );
        });
    };

    return unauthorized ? (
        <Unauthorized />
      ) : (
        <>
          <Header />
          {/* Page content */}
          <Container className="mt--9 main-content" fluid>
            <Card className="card-height shadow">
              <CardHeader className="bg-white d-flex justify-content-between">
                <h2>
                    {params.id ? 'Update Invoice' : 'Create New Invoice'}
                </h2>
                <Button
                  type="button"
                  color="primary"
                  onClick={() => {
                    props.history.goBack();
                  }}
                >
                  <i className="fas fa-chevron-left"></i> Back
                </Button>
              </CardHeader>
              <CardBody>
                  <div className="px-4">
                    <h1>Invoice #{params.id ? params.id : ''}</h1>
                    <div className="pull-right">
                        <div className="text-right my-2">
                            <Button
                                id="save-receipt"
                                color="primary"
                                onClick={formSubmitHandler}
                                >Save Invoice</Button>
                        </div>
                        <Row className="justify-content-center mb-2">
                            <Col lg="9" md="9" className="invoice-delivery-date-label pt-1 pr-0">Billed To:</Col>
                            <Col lg="3" md="3" className="pr-1">
                                <Input
                                    id="billedTo"
                                    type="text"
                                    placeholder="Billed To"
                                    autoComplete="off"
                                    size="sm"
                                    name="billedTo"
                                    value={props.invoiceFormDetails.billedTo}
                                    onChange={(e) =>
                                        props.saveInvoiceFormDetails({
                                            name: "billedTo",
                                            value: e.target.value,
                                        })
                                    }
                                    autoFocus={true}/>
                            </Col>
                        </Row>
                        <Row className="justify-content-center mb-2">
                            <Col lg="9" md="9" className="invoice-delivery-date-label pt-1 pr-0">Check Number#:</Col>
                            <Col lg="3" md="3" className="pr-1">
                                <Input
                                    id="checkNumber"
                                    type="text"
                                    placeholder="Check Number"
                                    autoComplete="off"
                                    name="checkNumber"
                                    size="sm"
                                    value={props.invoiceFormDetails.checkNumber}
                                    onChange={(e) =>
                                        props.saveInvoiceFormDetails({
                                            name: "checkNumber",
                                            value: e.target.value,
                                        })
                                    }/>
                            </Col>
                        </Row>
                        <Row className="justify-content-center">
                            <Col lg="9" md="9" className="invoice-delivery-date-label pt-2 pr-0">Delivery Date:</Col>
                            <Col lg="1" md="1" className="mb-2 pr-1">
                                <DropdownList
                                    data={monthOptions}
                                    value={props.invoiceFormDetails.deliveryDateMonth}
                                    placeholder="Month"
                                    textField="label"
                                    onChange={(e) => {
                                        props.saveInvoiceFormDetails({
                                          name: "deliveryDateMonth",
                                          value: e,
                                        });
                                    }}
                                />
                            </Col>
                            <Col lg="1" md="1" className="mb-2 pr-1">
                                <DropdownList
                                    data={dayOptions}
                                    value={props.invoiceFormDetails.deliveryDateDay}
                                    placeholder="Day"
                                    onChange={(e) => {
                                        props.saveInvoiceFormDetails({
                                          name: "deliveryDateDay",
                                          value: e,
                                        });
                                    }}
                                />
                            </Col>
                            <Col lg="1" md="1" className="mb-2 pr-1">
                                <DropdownList
                                    data={years}
                                    value={props.invoiceFormDetails.deliveryDateYear}
                                    placeholder="Year"
                                    onChange={(e) => {
                                        props.saveInvoiceFormDetails({
                                          name: "deliveryDateYear",
                                          value: e,
                                        });
                                    }}
                                />
                            </Col>
                        </Row>
                    </div>
                    <Table className="create-invoice-table add-receipt-item-table" bordered responsive="sm">
                        <thead className="thead-light">
                            <tr>
                                <th scope="col">Desc</th>
                                <th scope="col" className="datetime-col">Date/Time</th>
                                <th scope="col" className="amount-col">Amount</th>
                            </tr>
                        </thead>
                        <tbody>{renderAllItemsHTML()}</tbody>
                        <tfoot className="tfoot-light">
                            <tr>
                                <td scope="col" colSpan="2">Total:</td>
                                <td scope="col" colSpan="2">
                                ${props.invoiceItemsDetails.items
                                    .reduce((prev, curr) => {
                                        let price = !isNaN(curr.price) ? parseFloat(curr.price) : 0.0
                                        return prev + price;
                                    }, 0)
                                    .toFixed(2)}
                                </td>
                            </tr>
                        </tfoot>
                    </Table>
                  </div>
              </CardBody>
            </Card>
          </Container>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        invoiceFormDetails: state.invoicesReducer.invoiceFormDetails,
        invoiceItemsDetails: state.invoicesReducer.invoiceItemdetails,
        userType: state.loginUserReducer.userType
    };
  };
  
const mapDispatchToProps = (dispatch) => {
    return {
        saveInvoiceFormDetails: (body) => dispatch(saveInvoiceFormDetails(body)),
        saveItemsDetails: (body) => dispatch(saveItemsDetails(body)),
        resetAddInvoiceForm: () => dispatch(resetAddInvoiceForm()),
        resetState: () => dispatch(resetState())
    };
};
  
export default connect(mapStateToProps, mapDispatchToProps)(CreateNewInvoice);