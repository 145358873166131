
import { Table } from "reactstrap";
import { Link } from "react-router-dom";
import { monthList } from "constants/ticketConstants";
import React, { useState } from "react";

function DailyJewelryReceipt(props) {
    return props.data.jewelryReceipt.length > 0 ? (
        <>
            <div className="mt-2">
                <h4 style={{display:'inline'}}>Jewelry Receipt </h4> [
                <Link
                    to={{
                    pathname: "/portal/daily/historical-chart",
                    state: { type: "JewelryReceipt" },
                    }}
                >
                    Historical Chart
                </Link>
                ]
            </div>
            <Table className="daily-table" bordered striped responsive="lg">
                <thead>
                <tr>
                    <th>Date</th>
                    <th>#</th>
                    <th>Amount</th>
                    <th className="text-center">Cash<br/>Amount</th>
                    <th className="text-center">Credit Card<br/>Amount</th>
                    <th className="text-center">Check<br/>Amount</th>
                    {/*<th>Profit</th>*/}
                    {(props.data.view.jewelryReceipt === "day" && props.userType.toLowerCase() === "admin") && (
                    <>
                    <th>Change</th>
                    <th className="text-center">Change<br/>Ret.</th>
                    </>
                    )}
                    {(props.data.view.jewelryReceipt === "day" && props.userType.toLowerCase() === "admin") && (
                    <th className="text-center">Dep.<br/>Done</th>
                    )}
                </tr>
                </thead>
                <tbody>
                {props.data.jewelryReceipt.map((tic, i) => {
                    let ticTimestamp = tic.timeStamp;
                    if (tic.type === "month") {
                        ticTimestamp = monthList.indexOf(ticTimestamp);
                    }
                    return (
                    <tr key={i}>
                        <td>
                        <a
                            href={
                            (tic.type === "year")
                            ? `/portal/daily/${tic.timeStamp}?dataType=jewelryReceipt`
                            : (tic.type === "month")
                                ? `${props.location.pathname}/${monthList.indexOf(tic.timeStamp)}?dataType=jewelryReceipt`
                                : `/portal/jewelry-receipts?date=${props.year}/${monthList.indexOf(props.timeStamp)}/${tic.timeStamp}`}
                            className="delete-item">{ticTimestamp}</a>
                        </td>
                        <td>{tic.totalCount}</td>
                        <td className="text-center">${tic.amount.toFixed(2).replace('.00', '')}</td>
                        <td className="text-center">${tic.cashAmount.toFixed(2).replace('.00', '')}</td>
                        <td className="text-center">${tic.creditAmount.toFixed(2).replace('.00', '')}</td>
                        <td className="text-center">${tic.checkAmount.toFixed(2).replace('.00', '')}</td>
                        {/*<td className="text-center">${tic.profit}</td>*/}
                        {(tic.type === "day" && props.userType.toLowerCase() === "admin") && (
                        <>
                            <td className="text-center">${tic.change}</td>
                            <td style={{verticalAlign: 'middle'}} className="text-center">
                            <input
                                id={"changeReturned" + i}
                                type="checkbox"
                                checked={tic.changeReturned}
                                className="db-sized-checkbox"
                                onChange={(e) => props.checkboxHandler(e, tic.dailyDate, "changeReturned", "jewelryReceipt")}
                            />
                            <small className="ml-2"></small>
                            </td>
                        </>
                        )}
                        {(tic.type === "day" && props.userType.toLowerCase() === "admin") && (
                        <td style={{verticalAlign: 'middle'}} className="text-center">
                            <input
                                id={"depositDone" + i}
                                type="checkbox"
                                checked={tic.depositDone}
                                className="db-sized-checkbox"
                                onChange={(e) => props.checkboxHandler(e, tic.dailyDate, "depositDone", "jewelryReceipt")}
                            />
                            <small className="ml-2"></small>
                        </td>
                        )}
                    </tr>
                    );
                })}
                </tbody>
            </Table>
        </>
    ) : "";
}

export default DailyJewelryReceipt;
