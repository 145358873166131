import React, { useState } from "react";
import { useEffect } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from "reactstrap";

function MissingDepositModal(props) {
    const [items, setItems] = useState([]);
    useEffect(() => {
        setItems([... props.items.map(v=>{
            v.depositDone = true
            return v
        })])
    }, []);
    
    const renderMissingDays = (e) => {
        return items.map((item, i) => {
          return (
            <div className="" key={i}>
                <input
                    id={"depositDone" + i}
                    type="checkbox"
                    checked={item.depositDone}
                    className="db-sized-checkbox"
                    onChange={(e) => {
                        const { checked } = e.target;
                        let newItems = items
                        newItems[i].depositDone = checked
                        setItems([... newItems])
                    }}
                    />&nbsp;&nbsp;&nbsp;
                <strong>{item.dailyDate}</strong>
            </div>
          );
        });
    };

  return (
    <Modal isOpen={props.show} toggle={props.toggle} className={props.class ? props.class : ""}>
      <ModalHeader toggle={props.toggle}>Confirmation!</ModalHeader>
      <ModalBody className="py-0">
        <div className="form-group text-center">
            <>
                <h4>Do you want also deposit the following days?</h4>
                {renderMissingDays()}
            </>
        </div>
      </ModalBody>
      <ModalFooter className="pt-0">
        <Button id="confirm-btn" color="primary" onClick={e => props.confirmed(items)}>
          Confirm
        </Button>{" "}
        <Button color="secondary" onClick={e =>props.toggle()}>Cancel</Button>
      </ModalFooter>
    </Modal>
  );
}

export default MissingDepositModal;
