import {
    FETCH_INVOICE_LISTING_FAILURE,
    FETCH_INVOICE_LISTING_SUCCESS,
    UPDATE_INVOICE_DATA_AFTER_UPDATE,
    SAVE_INVOICE_FORM_DETAILS,
    SAVE_INVOICE_ITEMS_DETAILS,
    SAVE_ITEMS_DETAILS_IN_FORM,
    RESET_SAVE_INVOICE_DETAILS,
    FETCH_SINGLE_INVOICE_SUCCESS,
    FETCH_SINGLE_INVOICE_FAILURE,
    SAVE_CUSTOMER_ITEMS_DETAILS,
    RESET,
} from "./InvoicesTypes";

import {
    setLoaderRequestTrue,
    setLoaderRequestFalse,
} from "../common/commonAction";
import { GetApiActionWithAuthorization } from "../../constantFunctions/apiActions";
  
  /*_________________________________________________________________From Invoice Listing __________________________________________________________________ */
  
  //Api Call Function for from listing
  export const fetchInvoicesListing = (params = {page: 0}) => {
    return (dispatch) => {
      dispatch(setLoaderRequestTrue());
      GetApiActionWithAuthorization("invoices/get-all-invoices-data", params)
        .then((res) => {
          dispatch(setLoaderRequestFalse());
          dispatch(fetchInvoiceListingSuccess(res.data, params.page, res.count));
        })
        .catch((err) => {
          dispatch(setLoaderRequestFalse());
          dispatch(fetchInvoiceListingFailure(err));
        });
    };
  };

  //Api Call Function for from listing
  export const fetchSingleInvoice = (params) => {
    return (dispatch) => {
      dispatch(setLoaderRequestTrue());
      GetApiActionWithAuthorization("invoices/get-single-invoice-data/" + params.id, params)
        .then((res) => {
          dispatch(setLoaderRequestFalse());
          dispatch(fetchSingleInvoiceSuccess({
            model: res.data,
            items: res.items
          }));
        })
        .catch((err) => {
          dispatch(setLoaderRequestFalse());
          dispatch(fetchInvoiceListingFailure(err));
        });
    };
  };

  export const fetchSingleCoinInvoice = (id) => {
    return (dispatch) => {
      dispatch(setLoaderRequestTrue());
      GetApiActionWithAuthorization("invoices/get-single-coin-invoice-data/" + id)
        .then((res) => {
          dispatch(setLoaderRequestFalse());
          dispatch(fetchSingleInvoiceSuccess({
            model: res.data,
            items: res.items,
            totalAmount: res.items.reduce((prev, curr) => {
                  let price = !isNaN(curr.price) ? parseFloat(curr.price) : 0.0
                  return prev + price;
              }, 0)
              .toFixed(0)
          }));
        })
        .catch((err) => {
          dispatch(setLoaderRequestFalse());
          dispatch(fetchSingleInvoiceFailure(err));
        });
    };
  };

export const fetchSingleInvoiceSuccess = (invoice) => {
  return {
    type: FETCH_SINGLE_INVOICE_SUCCESS,
    payload: invoice,
  };
};

export const fetchSingleInvoiceFailure = (error) => {
  return {
    type: FETCH_SINGLE_INVOICE_FAILURE,
    payload: error,
  };
};

  //From Op. Success save Payload data to store
export const fetchInvoiceListingSuccess = (invoices, page, count) => {
    return {
      type: FETCH_INVOICE_LISTING_SUCCESS,
      payload: { invoices, page, count },
    };
  };

//From Op. Failure save Payload
export const fetchInvoiceListingFailure = (error) => {
    return {
        type: FETCH_INVOICE_LISTING_FAILURE,
        payload: error,
    };
};

//Invoice Failure save Payload
export const updateInvoiceTableAfterUpdate = (invoices) => {
    return {
      type: UPDATE_INVOICE_DATA_AFTER_UPDATE,
      payload: invoices,
    };
  };

  export const saveInvoiceFormDetails = ({ name, value }) => {
    return {
      type: SAVE_INVOICE_FORM_DETAILS,
      payload: { name, value },
    };
  };
  
  export const saveInvoiceItemDetails = (items) => {
    return {
      type: SAVE_INVOICE_ITEMS_DETAILS,
      payload: items,
    };
  };

  export const resetAddInvoiceForm = () => {
    return {
      type: RESET_SAVE_INVOICE_DETAILS,
    };
  };

  export const saveItemsDetails = (items) => {
    return {
      type: SAVE_ITEMS_DETAILS_IN_FORM,
      payload: items,
    };
  };

  export const saveCustomerItemsDetails = (items) => {
    return {
      type: SAVE_CUSTOMER_ITEMS_DETAILS,
      payload: items,
    };
  };