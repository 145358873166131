import axios from "axios";
import publicIp from "public-ip";
import { toast } from "react-toastify";
const statusCode = [401, 404, 409];

//For a post request to api without authorization header
function PostApiAction(url, obj, customHeader = {}) {
  return new Promise((resolve, reject) => {
    Promise.all([
      publicIp.v4().catch(e=>console.log(e.message)),
      publicIp.v6().catch(e=>console.log(e.message))
    ]).then (ips => {
      //attach both v4&v6 ips
      let ipHeaders = {};
      if (ips[0]) {//ipv4
        ipHeaders['x-ipaddr-v4'] = ips[0];
      }
      if (ips[1]) {//ipv6
        ipHeaders['x-ipaddr-v6'] = ips[1];
      }
      let headers = Object.assign(ipHeaders, customHeader);
      axios
        .post(process.env.REACT_APP_apiUrl + url, obj, { headers })
        .then((res) => {
          let data = res.data;
          resolve(data);
        })
        .catch((error) => {
          let data = error.response.data;
          if (statusCode.includes(data.status)) {
            toast.error(data.message, { position: toast.POSITION.TOP_CENTER });
          }
          reject(error);
        });
    });
  });
}

//For a get request to api without authorization header
function GetApiAction(url,params={}) {
  return new Promise((resolve, reject) => {
    Promise.all([
      publicIp.v4().catch(e=>console.log(e.message)),
      publicIp.v6().catch(e=>console.log(e.message))
    ]).then (ips => {
      //attach both v4&v6 ips
      if (typeof params.headers === 'undefined') {
        params.headers = {};
      }
      if (ips[0]) {//ipv4
        params.headers['x-ipaddr-v4'] = ips[0];
      }
      if (ips[1]) {//ipv6
        params.headers['x-ipaddr-v6'] = ips[1];
      }
      axios
        .get(process.env.REACT_APP_apiUrl + url,params)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          let data = error.response.data;
          if (statusCode.includes(data.status)) {
            toast.error(data.message, { position: toast.POSITION.TOP_CENTER });
          }
          reject(error);
        });
    });
  });
}

//For a get request to api with authorization header
function GetApiActionWithAuthorization(url,params={}) {
  let token = localStorage.getItem("token"),
    header = { headers: { Authorization: `Bearer ${token}` },params };

  return new Promise((resolve, reject) => {
    Promise.all([
      publicIp.v4().catch(e=>console.log(e.message)),
      publicIp.v6().catch(e=>console.log(e.message))
    ]).then (ips => {
      //attach both v4&v6 ips
      if (ips[0]) {//ipv4
        header.headers['x-ipaddr-v4'] = ips[0];
      }
      if (ips[1]) {//ipv6
        header.headers['x-ipaddr-v6'] = ips[1];
      }
      axios
      .get(process.env.REACT_APP_apiUrl + url, header)
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        let data = error.response.data;
        if (statusCode.includes(data.status)) {
          toast.error(data.message, { position: toast.POSITION.TOP_CENTER });
        }
        //Session Expires Check
        else if (data.status === 440) {
          localStorage.removeItem("token");
        }
        reject(data);
      });
    });
  });
}

//For a post request to api with authorization
function PostApiWithAuthorizationAction(url, obj, customHeader = {}) {
  let token = localStorage.getItem("token"),
  header = {
    headers: Object.assign(customHeader, { Authorization: `Bearer ${token}` })
  };
  return new Promise((resolve, reject) => {
    Promise.all([
      publicIp.v4().catch(e=>console.log(e.message)),
      publicIp.v6().catch(e=>console.log(e.message))
    ]).then (ips => {
      //attach both v4&v6 ips
      if (ips[0]) {
        header.headers['x-ipaddr-v4'] = ips[0];
      }
      if (ips[1]) {
        header.headers['x-ipaddr-v6'] = ips[1];
      }
      axios
        .post(process.env.REACT_APP_apiUrl + url, obj, header)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          let data = error.response.data;
          if (statusCode.includes(data.status)) {
            toast.error(data.message, { position: toast.POSITION.TOP_CENTER });
          }
          //Session Expires Check
          else if (data.status === 440) {
            localStorage.removeItem("token");
          }
          reject(data);
        });
    });
  });
}

export {
  GetApiAction,
  PostApiAction,
  PostApiWithAuthorizationAction,
  GetApiActionWithAuthorization,
};
