
export const FETCH_INVOICE_LISTING_FAILURE = 'FETCH_INVOICE_LISTING_FAILURE';
export const FETCH_INVOICE_LISTING_SUCCESS = 'FETCH_INVOICE_LISTING_SUCCESS';
export const UPDATE_INVOICE_DATA_AFTER_UPDATE = 'UPDATE_INVOICE_DATA_AFTER_UPDATE';
export const SAVE_INVOICE_FORM_DETAILS = 'SAVE_INVOICE_FORM_DETAILS';
export const SAVE_INVOICE_ITEMS_DETAILS = 'SAVE_INVOICE_ITEMS_DETAILS';
export const RESET_SAVE_INVOICE_DETAILS = 'RESET_SAVE_INVOICE_DETAILS';
export const SAVE_ITEMS_DETAILS_IN_FORM = 'SAVE_ITEMS_DETAILS_IN_FORM';
export const FETCH_SINGLE_INVOICE_SUCCESS = 'FETCH_SINGLE_INVOICE_SUCCESS';
export const FETCH_SINGLE_INVOICE_FAILURE = 'FETCH_SINGLE_INVOICE_FAILURE';
export const SAVE_CUSTOMER_ITEMS_DETAILS = 'SAVE_CUSTOMER_ITEMS_DETAILS';

export const RESET = 'RESET';
