/**
 * Open a link in a new tab
 * @param {*} url 
 */
const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
}
/**
 * FileUploader global setting
 */
const enableFileUploader = () => {
    const $ = window.jQuery;
    $('#fileuploader').fileuploader({
        limit: 5,
        extensions: ['jpg', 'png', 'jpeg'],
        changeInput: ' ',
        theme: 'thumbnails',
        enableApi: true,
        addMore: true,
        thumbnails: {
          box: '<div class="fileuploader-items">' +
                          '<ul class="fileuploader-items-list">' +
                    '<li class="fileuploader-thumbnails-input"><div class="fileuploader-thumbnails-input-inner"><i>+</i></div></li>' +
                          '</ul>' +
                      '</div>',
          item: '<li class="fileuploader-item">' +
                   '<div class="fileuploader-item-inner">' +
                               '<div class="type-holder">${extension}</div>' +
                               '<div class="actions-holder">' +
                        '<button type="button" class="fileuploader-action fileuploader-action-remove" title="${captions.remove}"><i class="fileuploader-icon-remove"></i></button>' +
                               '</div>' +
                               '<div class="thumbnail-holder">' +
                                   '${image}' +
                                   '<span class="fileuploader-action-popup"></span>' +
                               '</div>' +
                               '<div class="content-holder"><h5>${name}</h5><span>${size2}</span></div>' +
                                '<div class="progress-holder">${progressBar}</div>' +
                           '</div>' +
                      '</li>',
          item2: '<li class="fileuploader-item">' +
                   '<div class="fileuploader-item-inner">' +
                               '<div class="type-holder">${extension}</div>' +
                               '<div class="actions-holder">' +
                        '<a href="${file}" class="fileuploader-action fileuploader-action-download" title="${captions.download}" download><i class="fileuploader-icon-download"></i></a>' +
                        '<button type="button" class="fileuploader-action fileuploader-action-remove" title="${captions.remove}"><i class="fileuploader-icon-remove"></i></button>' +
                               '</div>' +
                               '<div class="thumbnail-holder">' +
                                   '${image}' +
                                   '<span class="fileuploader-action-popup"></span>' +
                               '</div>' +
                               '<div class="content-holder"><h5 title="${name}">${name}</h5><span>${size2}</span></div>' +
                                '<div class="progress-holder">${progressBar}</div>' +
                           '</div>' +
                       '</li>',
          startImageRenderer: true,
          canvasImage: false,
          _selectors: {
            list: '.fileuploader-items-list',
            item: '.fileuploader-item',
            start: '.fileuploader-action-start',
            retry: '.fileuploader-action-retry',
            remove: '.fileuploader-action-remove'
          },
          onItemShow: function(item, listEl, parentEl, newInputEl, inputEl) {
            var plusInput = listEl.find('.fileuploader-thumbnails-input'),
                api = $.fileuploader.getInstance(inputEl.get(0));
            //
            plusInput.insertAfter(item.html)[api.getOptions().limit && api.getChoosedFiles().length >= api.getOptions().limit ? 'hide' : 'show']();
            //
            if(item.format == 'image') {
              item.html.find('.fileuploader-item-icon').hide();
            }
          },
          onItemRemove: function(html, listEl, parentEl, newInputEl, inputEl) {
            var plusInput = listEl.find('.fileuploader-thumbnails-input'),
                api = $.fileuploader.getInstance(inputEl.get(0));
            //
            html.children().animate({'opacity': 0}, 200, function() {
              html.remove();
              if (api.getOptions().limit && api.getChoosedFiles().length - 1 < api.getOptions().limit)
                plusInput.show();
            });
          }
        },
        dragDrop: {
          container: '.fileuploader-thumbnails-input'
        },
        afterRender: function(listEl, parentEl, newInputEl, inputEl) {
          var plusInput = listEl.find('.fileuploader-thumbnails-input'),
            api = $.fileuploader.getInstance(inputEl.get(0));
          plusInput.on('click', function() {
            api.open();
          });
          api.getOptions().dragDrop.container = plusInput;
        }
      });
}

/**
 * form data functions
 */
const buildFormData = function (formData, data, parentKey) {
    if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
      Object.keys(data).forEach(key => {
        buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
      });
    } else {
      const value = data == null ? '' : data;
      formData.append(parentKey, value);
    }
}
//
const jsonToFormData = function (data) {
    const formData = new FormData();
    buildFormData(formData, data);
    return formData;
}
//
module.exports = {
    openInNewTab,
    enableFileUploader,
    jsonToFormData
};