import React from "react";
import { Container, Card, CardBody } from "reactstrap";

import unauth from "../../assets/img/unauthorised-156169_1280.png";

import Header from "../../components/Headers/BlankHeader";

class Unauthorized extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <Header />
        <Container className="mt--9 main-content" fluid>
          <Card className="card-height">
            <CardBody className="align-items-center d-flex justify-content-center">
              <div className="text-center mt-8">
                <h1 className="mt-3">This invoice is no longer active</h1>
              </div>
            </CardBody>
          </Card>
        </Container>
      </>
    );
  }
}

export default Unauthorized;
