import React, { useEffect, useState } from "react";
// reactstrap components
import {
  Card,
  Container,
  CardHeader,
  CardBody,
  Row,
  Col,
  Button,
  Input,
  Table,
  Form,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import ReactPaginate from "react-paginate";
import { PostApiWithAuthorizationAction } from "constantFunctions/apiActions";
import { resetState } from "../../redux/common/commonAction";
import Unauthorized from "views/Pages/Unauthorized";
import {
  fetchBlockListing,
  fetchBlockListingSuccess,
} from "../../redux/BlockList/BlockListAction";
import ConfirmationDeleteModal from "views/Modal/ConfirmationDeleteModal";
import AddNewBuyerModal from '../Modal/Block/AddNewBlockModal';
import { GetApiActionWithAuthorization } from "constantFunctions/apiActions";
import { toast } from "react-toastify";
import { DropdownList } from "react-widgets";

function BlockList(props) {
  const [unauthorized, setUnauthorized] = useState(false);
  const [search, setSearch] = useState("");
  const [filterBy, setFilterBy] = useState({ value: "all", label: "All" });
  const [addModal, setAddModal] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [delId, setDelId] = useState("");
  const [editUrl, setEditUrl] = useState(false);
  const [editData, setEditData] = useState({
    firstName: '',
    lastName: '',
    reason: '',
    videoUrl: ''
  });

  useEffect(() => {
    props.fetchBlockListing({
      page: props.blockDetails.page
    });
  }, []);

  if (props.blockDetails.error && !unauthorized) {
    if (props.blockDetails.error.status === 403) {
      setUnauthorized(true);
    } else if (props.blockDetails.error.status === 440) {
      props.resetState();
      toast.error(props.blockDetails.error.message, {
        position: toast.POSITION.TOP_CENTER,
      });
      props.history.push("/auth/login");
    }
  }
  const toggleConfirm = (id = "") => {
    setDelId(id);
    setConfirm(!confirm);
  };

  const toggleSave = (block = null) => {
    if (block) {
      setEditData(block);
    } else {
      setEditData({
        firstName: '',
        lastName: '',
        reason: '',
        videoUrl: ''
      });
    }
    setAddModal(!addModal);
  };

  const confirmed = (e) => {
    GetApiActionWithAuthorization(
      "common/delete-from-block-list/" + delId + "/" + props.blockDetails.page
    )
      .then((res) => {
        props.updateBlockListing({
          data: res.data,
          page: props.blockDetails.page,
          count: res.count,
        });
        //toast.success(res.message, { position: toast.POSITION.TOP_CENTER });
        setConfirm(false);
      })
      .catch((err) => {
        console.log(err);
        if (err.status === 403) {
          setUnauthorized(true);
        } else if (err.status === 440) {
          props.resetState();
          toast.error(err.message, { position: toast.POSITION.TOP_CENTER });
          props.history.push("/auth/login");
        }
      });
  };

  //CheckBox change handler
  const checkboxHandler = (e, name, id) => {
    const { checked } = e.target;
    let body = {
      id: id,
      [name]: checked ? 1 : 0,
      page: props.blockDetails.page,
    };
    PostApiWithAuthorizationAction("common/update-block-details", body)
      .then((res) => {
        props.updateBlockListing({
          data: res.data,
          page: props.blockDetails.page,
          count: res.count,
        });
      })
      .catch((err) => {
        if (err.status === 403) {
          setUnauthorized(true);
        } else if (err.status === 440) {
          props.resetState();
          toast.error(err.message, { position: toast.POSITION.TOP_CENTER });
          props.history.push("/auth/login");
        }
      });
  };

  const renderTicketsTableData = (e) => {
    return props.blockDetails.blockData.map((block, i) => {
      return (
        <tr key={i}>
          <td>{block.id}</td>
          <td>{block.firstName}</td>
          <td>{block.lastName}</td>
          <td>
            {block.ticketId !== "" && (
              <a
                href={
                  block.ticketId[0] === "C"
                    ? "/portal/coin-tickets/edit/" + block.ticketId.slice(1)
                    : block.ticketId[0] === "M"
                    ? "/portal/metro-tickets/edit/" + block.ticketId.slice(1)
                    : "/portal/metro-tickets/edit/" + block.ticketId
                }
              >
                Ticket #{block.ticketId} -
              </a>
            )}
            Badge #{block.badge} - Case #{block.case_id} - Detective:
            {block.detective} - Reason:{block.reason}
          </td>
          <td>
            <div className="">
              {(block.videoUrl.length > 0) && (
                <a href={block.videoUrl} target="_blank">{block.videoUrl}</a>
              )}
            </div>
          </td>
          <td>
            <Moment format="MMM Do YYYY HH:mm" tz="America/Los_Angeles">
              {block.dateAdded.toString().length === 10
                ? block.dateAdded * 1000
                : block.dateAdded}
            </Moment>
          </td>
          {props.userType === "admin" && (
            <>
              <td>
                {" "}
                <input
                  type="checkbox"
                  checked={block.isPulled}
                  onChange={(e) => checkboxHandler(e, "isPulled", block.id)}
                />
              </td>
              <td>
                {" "}
                <input
                  type="checkbox"
                  checked={block.isPickedUp}
                  onChange={(e) => checkboxHandler(e, "isPickedUp", block.id)}
                />
              </td>
              <td>
                [<Link href="#"
                  onClick={(e) => {
                    toggleSave(block)
                  }}>
                  Edit
                </Link>]
                [<Link href="#" onClick={(e) => toggleConfirm(block.id)}>
                  Delete
                </Link>]
              </td>
            </>
          )}
        </tr>
      );
    });
  };

  return unauthorized ? (
    <Unauthorized />
  ) : (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--9 main-content" fluid>
        <Card className="card-height shadow">
          <CardHeader>
            <div className="text-center">
              <h1>Block List</h1>
            </div>
          </CardHeader>
          <CardBody>
          <Row className="align-items-center search-container my-4">
              <Col md="5" lg="6" xl="7" className="search-left-btn">
                <Button
                  color="primary"
                  onClick={(e) => {
                    toggleSave()
                  }}
                >
                  Create New Block
                </Button>
                </Col>
              <Col md="7" lg="6" xl="5">
                <Form
                  className="d-flex"
                  onSubmit={(e) => {
                    e.preventDefault();
                    props.fetchBlockListing({
                      page: 0,
                      search,
                      filterBy: filterBy.value
                    });
                  }}
                >
                  <Input
                    type="text"
                    className="mr-2"
                    placeholder={"Search by customer firstName, lastName"}
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                  <DropdownList
                    data={[
                      { value: "all", label: "All" },
                      { value: "ticketNumber", label: "Ticket #" },
                      { value: "name", label: "Name" },
                      { value: "reason", label: "Other" },
                    ]}
                    containerClassName="filter-By"
                    value={filterBy}
                    valueField="value"
                    textField="label"
                    placeholder="Select"
                    onChange={(e) => setFilterBy(e)}
                  />
                  <Button type="submit" className="mx-2" color="primary">
                    Search
                  </Button>
                </Form>
              </Col>
            </Row>
            <Table className="ticket-table table-listing" responsive bordered>
              <thead className="thead-light">
                <tr>
                  <th scope="col">ID</th>
                  <th scope="col">First Name</th>
                  <th scope="col">Last Name</th>
                  <th scope="col">Reason</th>
                  <th scope="col">Video url</th>
                  <th scope="col">Date Created</th>
                  {props.userType === "admin" && (
                    <>
                      <th scope="col">Is pulled</th>
                      <th scope="col">Picked Up</th>
                      <th scope="col">Action</th>
                    </>
                  )}
                </tr>
              </thead>
              <tbody>{renderTicketsTableData()}</tbody>
            </Table>
            {props.blockDetails.blockData.length > 0 && (
              <ReactPaginate
                previousLabel={<i className="fas fa-chevron-left"></i>}
                nextLabel={<i className="fas fa-chevron-right"></i>}
                breakLabel={".."}
                breakClassName={"break-me"}
                initialPage={props.blockDetails.page}
                pageCount={props.blockDetails.count / 50}
                marginPagesDisplayed={1}
                pageRangeDisplayed={2}
                onPageChange={(e) => {
                  props.fetchBlockListing({
                    page: e.selected
                  });
                }}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}
              />
            )}
          </CardBody>
        </Card>
      </Container>

      {confirm && (
        <ConfirmationDeleteModal
          show={confirm}
          toggle={toggleConfirm}
          confirmed={confirmed}
        />
      )}

      {addModal && (
        <AddNewBuyerModal {...props} show={addModal} toggle={toggleSave} block={editData} setBlock={setEditData}/>
      )}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    blockDetails: state.BlockListReducer,
    userType: state.loginUserReducer.userType,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchBlockListing: (params) => dispatch(fetchBlockListing(params)),
    updateBlockListing: (body) => dispatch(fetchBlockListingSuccess(body)),
    resetState: () => dispatch(resetState()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BlockList);
