import Header from "components/Headers/Header";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { DropdownList } from "react-widgets";
import classnames from "classnames";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Container,
  Input,
  Table,
  InputGroup,
  InputGroupAddon

} from "reactstrap";
import {
  paymentOptionsReceipt,
  paymentOptionsCoinReceipt,
  stationOptions,
} from "../../constants/ticketConstants";
import { resetState } from "redux/common/commonAction";
import {
  saveReceiptFormDetails,
  saveItemsDetails,
  resetAddReceiptForm,
} from "../../redux/Receipt/ReceiptAction";
import {
  setLoaderRequestFalse,
  setLoaderRequestTrue,
} from "../../redux/common/commonAction";
import Unauthorized from "views/Pages/Unauthorized";
import { GetApiActionWithAuthorization } from "constantFunctions/apiActions";
import { PostApiWithAuthorizationAction } from "constantFunctions/apiActions";
import Moment from "react-moment";
import { validateReceiptForm } from "constantFunctions/formValidations";
import Autocomplete from "components/AutoComplete/Autocomplete";
import { jsonToFormData } from "libs/common";
import { enableFileUploader } from "libs/common";

function UpdateReceipt(props) {
  const [keywords, setKeywords] = useState([]);
  const [items, setitems] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [unauthorized, setUnauthorized] = useState(false);
  const [keyLoad, setKeyLoad] = useState(false);
  const [allClerks, setAllClerks] = useState([]);
  const [otherDetails, setOtherDetails] = useState({});
  const [loading, setLoading] = useState(false);
  const [fromOptions, setFromOption] = useState([]);
  const [ethSpotPrice, setETHSpotPrice] = useState(0);
  const [btcSpotPrice, setBTCSpotPrice] = useState(0);
  const [resetTime, setResetTime] = useState(false);
  const [boothOptions, setBoothOptions] = useState([]);
  const [attachments, setAttachments] = useState([]);

  useEffect(() => {
    let id = props.match.params.id;
    props.setLoaderRequestTrue();
    fetchAllBooth()
    GetApiActionWithAuthorization(`receipts/get-particular-receipt-data/${id}`)
      .then((res) => {
        props.setLoaderRequestFalse();
        let obj = {
          ...res.details,
          fromx: { name: res.details.fromx },
          booth: { name: res.details.booth },
          paidBy: paymentOptionsCoinReceipt.find(
            (fr) => fr.value === res.details.paidBy
          ),
          clerk: { first_name: res.details.clerk },
          printer: stationOptions.find(
            (fr) => fr.value === res.details.printer
          ),
        };
        setOtherDetails(obj);
        setSelectedItems(res.items);
        //load fileuploader with plain jquery
        window.jQuery(document).ready(function() {
          enableFileUploader();
        });
        return res;
      })
      .then(res => {
        setAttachments(res.details.receipt_attachments.map(f => {
          return {
            name: f.url.split('/').pop(),
            type: f.mimeType,
            size: f.fileSize,
            file: f.url,
            local: f.url,
            data: {
              url: f.url,
              thumbnail: f.thumbnailUrl,
              readerForce: true
            }
          }
        }))
      })
      .catch((err) => {
        props.setLoaderRequestFalse();
        if (err.status === 403) {
          setUnauthorized(true);
        } else if (err.status === 440) {
          props.resetState();
          toast.error(err.message, { position: toast.POSITION.TOP_CENTER });
          props.history.push("/auth/login");
        }
      });

    // ___________Get spot prices for ETH and BTC______________
    GetApiActionWithAuthorization("common/get-all-spot-prices").then((res) => {
      setBTCSpotPrice(res.data.find((s) => s.item === "btc").price);
      setETHSpotPrice(res.data.find((s) => s.item === "eth").price);
    });
  }, []);

  // ___________Get spot prices for ETH and BTC(every hour changes)______________
  useEffect(() => {
    let timer = setTimeout(() => {
      if (new Date().getMinutes() === 2) {
        setResetTime(!resetTime);
        GetApiActionWithAuthorization("common/get-all-spot-prices").then(
          (res) => {
            setBTCSpotPrice(res.data.find((s) => s.item === "btc").price);
            setETHSpotPrice(res.data.find((s) => s.item === "eth").price);
          }
        );
      } else {
        setResetTime(!resetTime);
      }
    }, 30000);
    return () => {
      clearTimeout(timer);
    };
  }, [resetTime]);

  const getDropItems = (e) => {
    if (e && items.length <= 0)
      GetApiActionWithAuthorization("common/get-items-select-options")
        .then((res) => {
          setitems(res.data);
        })
        .catch((err) => {
          if (err.status === 440) {
            toast.error(err.message, { position: toast.POSITION.TOP_CENTER });
            props.history.push("/auth/login");
          } else {
            toast.error("Something went wrong! /n Please clear and retry", {
              position: toast.POSITION.TOP_CENTER,
            });
          }
        });
  };
  // const getKeywords = (e) => {
  //   if (keywords.length === 0) {
  //     GetApiActionWithAuthorization("common/get-keywords-for-form")
  //       .then((res) => {
  //         setKeyLoad(false);
  //         setKeywords(res.data);
  //       })
  //       .catch((err) => setKeyLoad(false));
  //   }
  // };

  const getFromOptions = (e) => {
    if (e && fromOptions.length === 0) {
      setKeyLoad(true);
      GetApiActionWithAuthorization("common/get-from-select-options")
        .then((res) => {
          setKeyLoad(false);
          setFromOption(res.data);
        })
        .catch((err) => setKeyLoad(false));
    }
  };

  const fetchAllClerks = (e) => {
    if (e && allClerks.length <= 0) {
      GetApiActionWithAuthorization("common/get-clerk-listing")
        .then((res) => {
          setAllClerks(res.data);
        })
        .catch((err) => {
          if (err.status === 403) {
            setUnauthorized(true);
          } else if (err.status === 440) {
            props.resetState();
            toast.error(err.message, { position: toast.POSITION.TOP_CENTER });
            props.history.push("/auth/login");
          }
        });
    }
  };

  const fetchAllBooth = () => {
    GetApiActionWithAuthorization("common/get-all-booths?displayOrderType=Receipt").then((res) => {
      setBoothOptions(res.data);
    });
  };

  const addRowInItem = (e) => {
    let obj = {
      item: "",
      unitPrice: "",
      price: "",
      qty: "",
      tax: "",
    };
    setSelectedItems([...selectedItems, obj]);
  };

  const removeRowInItem = (index) => {
    let olderItems = [...selectedItems];
    olderItems.splice(index, 1);
    setSelectedItems([...olderItems]);
  };

  const changeItemValues = (name, value, index) => {
    let temp = selectedItems;
    let itemObj = temp[index];
    itemObj[name] = value;
    if (
      name === "qty" &&
      (itemObj.item === "" ||
        !["btc", "eth"].includes(itemObj.item.name.toLowerCase()))
    ) {
      itemObj["unitPrice"] = (
        parseFloat(itemObj.price) / parseInt(value)
      ).toFixed(2);
    }
    if (name === "item") {
      if (value.taxable) {
        itemObj["tax"] = ((parseFloat(itemObj.price) * 8.375) / 100).toFixed(2);
      } else {
        itemObj["tax"] = 0.0;
      }
    }
    if (
      name === "price" &&
      (itemObj.item === "" ||
        !["btc", "eth"].includes(itemObj.item.name.toLowerCase()))
    ) {
      if (itemObj.qty) {
        itemObj["unitPrice"] = (
          parseFloat(value) / parseInt(itemObj.qty)
        ).toFixed(2);
      } else {
        itemObj["unitPrice"] = parseFloat(value).toFixed(2);
      }
    }
    if (name === "price") {
      if (itemObj.item && itemObj.item.taxable) {
        itemObj["tax"] = ((parseFloat(value) * 8.375) / 100).toFixed(2);
      } else {
        itemObj["tax"] = 0.0;
      }
    }
    temp[index] = itemObj;

    setSelectedItems([...temp]);
  };

  const formSubmitHandler = (e) => {
    let validation = validateReceiptForm(otherDetails, selectedItems);
    if (validation.success) {
      if (
        otherDetails.oneTimeEdit ||
        (props.userType && props.userType.toLowerCase() === "admin")
      ) {
        let data = jsonToFormData({
          id: props.match.params.id,
          formDetails: {
            ...otherDetails,
            fromx: otherDetails.fromx.name,
            printer: otherDetails.printer.value,
            booth: otherDetails.booth.name,
            paidBy: otherDetails.paidBy.value,
            clerk: otherDetails.clerk.first_name,
            ticketAmount: selectedItems
              .reduce((prev, curr) => {
                if (curr.price === "") {
                  return prev + 0.0;
                } else {
                  return prev + parseFloat(curr.price);
                }
              }, 0)
              .toFixed(2),
            tax: selectedItems
              .reduce((prev, curr) => {
                if (curr.tax === "") {
                  return prev + 0.0;
                } else {
                  return prev + parseFloat(curr.tax);
                }
              }, 0)
              .toFixed(2),
          },
          items: selectedItems,
        });
        //
        let existingFiles = window.jQuery('[name="fileuploader-list-files"]').val();
        data.append('existingFiles', existingFiles);
        window.jQuery('[name="files[]"]').each(function( index ) {
          let files = window.jQuery('[name="files[]"]')[index].files;
          for (const file of files) {
            data.append('files[]', file, file.name);
          }
        });
        setLoading(true);
        PostApiWithAuthorizationAction("receipts/edit-receipt", data, {
          'Content-Type': 'multipart/form-data'
        })
          .then((res) => {
            setLoading(false);
            //toast.success(res.message, { position: toast.POSITION.TOP_CENTER });
            props.history.push("/portal/receipts");
          })
          .catch((err) => {
            if (err.status === 403) {
              setUnauthorized(true);
            } else if (err.status === 440) {
              props.resetState();
              toast.error(err.message, { position: toast.POSITION.TOP_CENTER });
              props.history.push("/auth/login");
            }
          });
      }
    } else {
      toast.error(validation.err, {
        position: toast.POSITION.TOP_CENTER,
        closeOnClick: true,
      });
    }
  };

  const renderAllItemsHTML = (e) => {
    return selectedItems.map((item, i) => {
      return (
        <tr key={i}>
          <td className="input-td">
            <Input
              type="number"
              value={item.qty}
              className={classnames({ "input-null": item.qty === "" })}
              disabled={props.userType.toLowerCase() !== "admin"}
              onChange={(e) => {
                changeItemValues("qty", e.target.value, i);
              }}
            />
          </td>
          <td className="input-td">
            <Input
              type="number"
              className={classnames({ "input-null": item.price === "" })}
              value={item.price}
              onChange={(e) => {
                if (item.item && item.item.name.toLowerCase() === "btc") {
                  changeItemValues("price", e.target.value, i);
                  changeItemValues("unitPrice", btcSpotPrice, i);
                  changeItemValues(
                    "qty",
                    (
                      parseFloat(e.target.value) / parseFloat(btcSpotPrice)
                    ).toFixed(8),
                    i
                  );
                } else if (
                  item.item &&
                  item.item.name.toLowerCase() === "eth"
                ) {
                  changeItemValues("price", e.target.value, i);
                  changeItemValues("unitPrice", ethSpotPrice, i);
                  changeItemValues(
                    "qty",
                    (
                      parseFloat(e.target.value) / parseFloat(ethSpotPrice)
                    ).toFixed(8),
                    i
                  );
                } else {
                  changeItemValues("price", e.target.value, i);
                }
              }}
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
            />
          </td>
          <td className="input-td">
            <Input type="number" value={item.unitPrice} disabled />
          </td>
          <td>
            <DropdownList
              data={items}
              textField="name"
              containerClassName="item-drop"
              value={item.item}
              onToggle={getDropItems}
              onChange={(e) => {
                if (e.name.toLowerCase() === "eth") {
                  changeItemValues("item", e, i);
                } else if (e.name.toLowerCase() === "btc") {
                  changeItemValues("item", e, i);
                } else {
                  changeItemValues("item", e, i);
                }
              }}
            />
          </td>
          <td className="action-col">
            <Button
              className="icon-cross"
              color="primary"
              title="Add more"
              onClick={() => {
                if (item.price !== "" && item.item !== "") {
                  addRowInItem();
                }
              }}
            >
              <i className="fas fa-check-square"></i>
            </Button>
            <Button
              className="icon-cross"
              color="danger"
              title="remove"
              onClick={() => {
                removeRowInItem(i);
              }}
            >
              <i className="fas fa-times"></i>
            </Button>
          </td>
        </tr>
      );
    });
  };

  return unauthorized ? (
    <Unauthorized />
  ) : (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--9 main-content" fluid>
        <Card className="card-height shadow">
          <CardHeader
            className={
              "d-flex align-items-center justify-content-between p-2"
              //   (blocked ? " back-red" : " bg-white ")
            }
          >
            <h3>
              Edit Coin Receipt{" "}
              {!otherDetails.oneTimeEdit &&
                props.userType.toLowerCase() !== "admin" &&
                "(Not Editable for now!)"}
              <br />
              <small>
                (Receipt ID: {props.match.params.id} - Receipt Time:{" "}
                <Moment format="MMM Do YYYY HH:mm" tz="America/Los_Angeles">
                  {otherDetails.ticketTime &&
                  otherDetails.ticketTime.toString().length === 10
                    ? new Date(otherDetails.ticketTime * 1000)
                    : new Date(otherDetails.ticketTime)}
                </Moment>{" "}
                ){" "}
              </small>
            </h3>
            <Button
              type="button"
              color="primary"
              className="h-fit"
              onClick={() => {
                props.history.goBack();
              }}
            >
              <i className="fas fa-chevron-left"></i> Back
            </Button>
          </CardHeader>
          <CardBody>
            <Table className="add-receipt-table" bordered responsive="sm">
              <tbody>
                <tr>
                  <th>First Name:</th>
                  <td className="input-td">
                    <Input
                      id="firstName"
                      type="text"
                      placeholder="First Name"
                      autoComplete="off"
                      name="firstName"
                      value={otherDetails.firstName}
                      onChange={(e) =>
                        setOtherDetails({
                          ...otherDetails,
                          firstName: e.target.value,
                        })
                      }
                    />
                  </td>
                  <th>Last Name:</th>
                  <td className="input-td">
                    <Input
                      id="lastName"
                      type="text"
                      placeholder="Last Name"
                      autoComplete="off"
                      name="lastName"
                      value={otherDetails.lastName}
                      onChange={(e) =>
                        setOtherDetails({
                          ...otherDetails,
                          lastName: e.target.value,
                        })
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <th>Paid By:</th>
                  <td>
                    <DropdownList
                      id="payment"
                      data={paymentOptionsCoinReceipt}
                      textField="label"
                      value={otherDetails.paidBy}
                      onChange={(e) => {
                        if (e.value === "creditcard") {
                          setOtherDetails({
                            ...otherDetails,
                            tendered: 0,
                            paidBy: e,
                          });
                        } else {
                          setOtherDetails({ ...otherDetails, paidBy: e });
                        }
                      }}
                    />
                  </td>
                  {otherDetails.paidBy && otherDetails.paidBy.value === "creditcard" && (
                    <>
                      <th>Card Last 4 digits:</th>

                      <td className="input-td">
                        <Input
                          id="cardnumber"
                          type="number"
                          className={classnames({
                            "input-null": otherDetails.creditCardNumber === "",
                          })}
                          onInput={(e) => {
                            e.target.value = Math.max(
                              0,
                              parseInt(e.target.value)
                            )
                              .toString()
                              .slice(0, 4);
                          }}
                          step="0.01"
                          placeholder="Card Last 4 digits"
                          autoComplete="off"
                          name="creditCardNumber"
                          value={otherDetails.creditCardNumber}
                          onChange={(e) => {
                            setOtherDetails({
                              ...otherDetails,
                              creditCardNumber: e.target.value,
                            });
                          }}
                        />
                      </td>
                    </>
                  )}
                  {otherDetails.paidBy && otherDetails.paidBy.value === "cash" && (
                    <>
                      <th>Cash Tendered ($):</th>
                      <td className="input-td">
                        <Input
                          id="tendered"
                          type="number"
                          className={classnames({
                            "input-null": otherDetails.tendered === "",
                          })}
                          step="1"
                          placeholder="Cash Tendered ($)"
                          autoComplete="off"
                          name="tendered"
                          value={otherDetails.tendered}
                          onChange={(e) =>
                            setOtherDetails({
                              ...otherDetails,
                              tendered: e.target.value,
                            })
                          }
                          onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                        />
                      </td>
                    </>
                  )}
                </tr>
                {otherDetails.paidBy && otherDetails.paidBy.value === "check" && (
                    <tr>
                      <th>Check Amount:</th>
                      <td className="input-td">
                        <Input
                          type="number"
                          id="checkAmount"
                          placeholder="Check Amount"
                          autoComplete="off"
                          name="checkAmount"
                          value={otherDetails.checkAmount}
                          onChange={(e) =>
                            setOtherDetails({
                              ...otherDetails,
                              checkAmount: e.target.value,
                            })
                          }
                        />
                      </td>
                      <th>Check #:</th>
                      <td className="input-td">
                        {(props.userType === 'admin') && (
                          <InputGroup>
                            <InputGroupAddon addonType="prepend">
                              <Button
                                size="sm"
                                color="danger"
                                title="Set Check Number Prefix"
                                onClick={(e) => {
                                  e.preventDefault();
                                  //
                                  props.history.push("/portal/setting-checks");
                                }}>
                                <i class="fas fa-money-check-alt"></i>
                              </Button>
                            </InputGroupAddon>
                            <Input
                              id="checkNumber"
                              type="text"
                              placeholder="Check Number"
                              autoComplete="off"
                              name="checkNumber"
                              value={otherDetails.checkNumber}
                              onChange={(e) => {
                                setOtherDetails({
                                  ...otherDetails,
                                  checkNumber: e.target.value,
                                })
                              }}
                              />
                        </InputGroup>
                        )}
                      </td>
                    </tr>
                  )}
                {/*<tr className="back-red">
                  <th>From:</th>
                  <td colSpan="3">
                    <div className="d-flex align-items-end">
                      <DropdownList
                        id="fromx"
                        data={fromOptions}
                        containerClassName="from-drop"
                        textField="name"
                        onToggle={getFromOptions}
                        value={otherDetails.fromx}
                        onChange={(e) =>
                          setOtherDetails({ ...otherDetails, fromx: e })
                        }
                      />
                    </div>
                  </td>
                      </tr>*/}
                <tr className="back-red">
                  <th>Booth:</th>
                  <td colSpan="3">
                    <div className="d-flex align-items-end">
                      <DropdownList
                        id="booth"
                        data={boothOptions}
                        containerClassName="booth-drop"
                        textField="name"
                        valueField="name"
                        value={otherDetails.booth}
                        onChange={(e) =>
                          setOtherDetails({ ...otherDetails, booth: e })
                        }
                      />
                    </div>
                  </td>
                </tr>
                {props.userType &&
                  !["employee", "employees", "employe"].includes(
                    props.userType.toLowerCase()
                  ) && (
                    <tr>
                      <th>Station:</th>
                      <td colSpan="3">
                        <div className="d-flex align-items-end">
                          <DropdownList
                            id="station"
                            data={stationOptions}
                            containerClassName="station-drop"
                            textField="label"
                            value={otherDetails.printer}
                            onChange={(e) =>
                              setOtherDetails({ ...otherDetails, printer: e })
                            }
                          />
                        </div>
                      </td>
                    </tr>
                  )}
                {/*<tr className="back-grey">
                  <th>Keyword:</th>
                  <td colSpan="3" className="autocomplete input-td">
                    <Input
                      id="keyword"
                      type="text"
                      placeholder="Keyword"
                      name="keyword"
                      value={otherDetails.keyword}
                      onChange={(e) =>
                        setOtherDetails({
                          ...otherDetails,
                          ["keyword"]: e.target.value,
                        })
                      }
                      autoComplete="off"
                    />
                  </td>
                    </tr>*/}
                <tr className="back-red">
                  <th>Clerk Name:</th>
                  <td colSpan="3">
                    <div className="d-flex align-items-end">
                      <DropdownList
                        id="clerk"
                        data={allClerks}
                        containerClassName="booth-drop"
                        textField="first_name"
                        onToggle={fetchAllClerks}
                        value={otherDetails.clerk}
                        onChange={(e) =>
                          setOtherDetails({ ...otherDetails, clerk: e })
                        }
                      />
                    </div>
                  </td>
                </tr>
                <tr className="back-grey">
                  <th>Note:</th>
                  <td colSpan="3" className="input-td">
                    <Input
                      id="note"
                      className="w-75"
                      type="text"
                      placeholder="Note"
                      autoComplete="off"
                      name="note"
                      value={otherDetails.note}
                      onChange={(e) =>
                        setOtherDetails({
                          ...otherDetails,
                          note: e.target.value,
                        })
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <th>Attachments:</th>
                  <td className="width-middle" colSpan="3">
                    <input type="file" name="files[]" id="fileuploader"  data-fileuploader-files={JSON.stringify(attachments)}/>
                  </td>
                </tr>
              </tbody>
            </Table>
            <Table className="add-receipt-item-table" bordered striped>
              <thead>
                <tr>
                  <th className="qty-col">Qty</th>
                  <th className="amount-col">Amount($)</th>
                  <th className="unit-col">UNIT($)</th>
                  <th className="desc-col">DESC</th> 
                </tr>
              </thead>
              <tbody>
                {selectedItems.length === 0 && (
                  <tr>
                    <td className="py-3 text-center" colSpan="4">
                      <Button
                        className="py-1"
                        color="primary"
                        onClick={addRowInItem}
                      >
                        Add item row+
                      </Button>
                    </td>
                  </tr>
                )}
                {renderAllItemsHTML()}
                <tr>
                  <td colSpan="3">Tax:</td>
                  <td>
                    $
                    {selectedItems
                      .reduce((prev, curr) => {
                        if (curr.tax === "") {
                          return prev + 0.0;
                        } else {
                          return prev + parseFloat(curr.tax);
                        }
                      }, 0)
                      .toFixed(2)}
                  </td>
                </tr>
                <tr>
                  <td colSpan="3">Total:</td>
                  <td>
                    $
                    {selectedItems
                      .reduce((prev, curr) => {
                        if (curr.price === "") {
                          return prev + 0.0;
                        } else {
                          return prev + parseFloat(curr.price);
                        }
                      }, 0)
                      .toFixed(2)}
                  </td>
                </tr>
              </tbody>
            </Table>
            <div className="text-center my-2">
              {!otherDetails.oneTimeEdit &&
              props.userType &&
              props.userType.toLowerCase() !== "admin" ? (
                <Button type="button" color="primary" disabled>
                  Can't Update
                </Button>
              ) : loading ? (
                <div className="text-center my-2">
                  <Button color="primary" disabled>
                    Saving <i className="fas fa-spinner fa-spin"></i>
                  </Button>
                </div>
              ) : (
                <div className="text-center my-2">
                  <Button
                    id="save-receipt"
                    color="primary"
                    onClick={formSubmitHandler}
                  >
                    Save Receipt
                  </Button>
                </div>
              )}
            </div>
          </CardBody>
        </Card>
      </Container>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    receiptFormDetails: state.ReceiptReducer.addReceiptFormdetails,
    receiptItemsDetails: state.ReceiptReducer.receiptItemdetails,
    userType: state.loginUserReducer.userType,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    saveReceiptFormDetails: (body) => dispatch(saveReceiptFormDetails(body)),
    setLoaderRequestFalse: () => dispatch(setLoaderRequestFalse()),
    setLoaderRequestTrue: () => dispatch(setLoaderRequestTrue()),
    saveItemsDetails: (body) => dispatch(saveItemsDetails(body)),
    resetAddReceiptForm: () => dispatch(resetAddReceiptForm()),
    resetState: () => dispatch(resetState()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdateReceipt);
