import Header from "components/Headers/Header";
import React, { useEffect, useState } from "react";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    Form,
    FormGroup,
    Input,
    Label,
    Row,
    Table,
} from "reactstrap";
import { toast } from "react-toastify";
import Unauthorized from "views/Pages/Unauthorized";
import classnames from "classnames";
import { GetApiActionWithAuthorization, PostApiWithAuthorizationAction } from "../../constantFunctions/apiActions";
import { resetState } from "../../redux/common/commonAction";
import { connect } from "react-redux";
import NumberFormat from "react-number-format";

function DailyProfit(props) {
    const [unauthorized, setUnauthorized] = useState(false);
    const { match: { params } } = props;
    const [data, setData] = useState([]);

    useEffect(() => {
        load();
    }, []);

    if (data.error && !unauthorized) {
        if (data.error.status === 403) {
          setUnauthorized(true);
        } else if (data.error.status === 440) {
          toast.error(data.error.message, {
            position: toast.POSITION.TOP_CENTER,
          });
          props.history.push("/auth/login");
        }
    }

  const load = () => {
    GetApiActionWithAuthorization("common/daily-profit-data", params)
    .then((res) => {
        setData(res.data)
    })
    .catch((err) => {
      if (err.status === 403) {
        setUnauthorized(true);
      } else if (err.status === 440) {
        props.resetState();
        toast.error(err.message, { position: toast.POSITION.TOP_CENTER });
        props.history.push("/auth/login");
      }
    });
  };

  const changeItemValues = (name, value, tableIndex, rowIndex) => {
    let temp = data;
    let itemObj = temp[tableIndex]['tableData'][rowIndex];
    if (name === "profit") {
        itemObj[name] = parseInt(value);
    }
    temp[tableIndex]['tableData'][rowIndex] = itemObj;
    setData([...temp]);
  };

    const tableUpdate = (e) => {
        e.preventDefault();
        PostApiWithAuthorizationAction("common/update-daily-profit-data", { data })
        .then((res) => {})
        .catch((err) => {
          if (err.status === 403) {
            setUnauthorized(true);
          } else if (err.status === 440) {
            props.resetState();
            props.history.push("/auth/login");
          }
        });
    }

    const renderDailyProfitTableRowData = (_data, i, rowLength) => {
        let tableData = _data.tableData
        if(tableData.length < rowLength) {
            let remainingLength = rowLength - tableData.length;
            for(let row=0;row<remainingLength;row++) {
                tableData.push({
                    id: 0,
                    ticketAmount: 0,
                    profit: 0
                })
            }
        }
        return tableData.map((rowData, j) => {
            return rowData.id > 0 ? (
                <tr>
                    <td scope="col"></td>
                    <td scope="col" style={{backgroundColor: '#ffff88'}}>{rowData.id}</td>
                    <td scope="col" style={{backgroundColor: '#bbFFbb'}}>
                        ${parseFloat(rowData.ticketAmount).toFixed(2)}
                    </td>
                    <td scope="col" style={{backgroundColor: '#67BDFF'}}>
                        <NumberFormat
                            id="profit"
                            prefix="$"
                            allowNegative={true}
                            className={classnames({ "input-null": rowData.profit === "", 'form-control': true })}
                            value={parseFloat(rowData.profit).toFixed(2).replace('.00', '')}
                            onValueChange={(e) => {
                                changeItemValues("profit", e.value, i, j);
                            }}
                        />
                    </td>
                </tr>
            ) : (
                <tr>
                    <td scope="col"></td>
                    <td scope="col" style={{backgroundColor: '#ffff88'}}></td>
                    <td scope="col" style={{backgroundColor: '#bbFFbb'}}></td>
                    <td scope="col" style={{backgroundColor: '#67BDFF'}}>
                        <NumberFormat
                            id="profit"
                            prefix="$"
                            className={classnames({ "input-null": rowData.profit === "", 'form-control': true })}
                            style={{visibility: 'hidden'}}
                        />
                    </td>
                </tr>
            );
        })
    };

    const renderDailyProfitTableData = () => {
        let rowLength= data.map((_data) => _data.tableData.length).reduce((a, b) => Math.max(a, b), 0)
        return data.map((_data, i) => {
            let totalProfit = _data.tableData.map(d=>d.profit).reduce((a, b) => a + b, 0);
            let totalAmount = _data.tableData.map(d=>d.ticketAmount).reduce((a, b) => a + b, 0);
            return (
                <>
                    <Col
                        sm={(12 / data.length)}
                        className="px-0 my-2">
                        <div
                            className="text-center"
                            style={{
                                backgroundColor: '#1fd386',
                                padding: '5px',
                                fontWeight: 'bolder',
                                textTransform: 'capitalize',
                                fontSize: '16px',
                                color: 'white'
                            }}>{_data.table.replace('new_coin', 'coin')}</div>
                        <Table
                            className="ticket-table table-listing items-list-table"
                            responsive
                            bordered>
                            <tr>
                                <th scope="col"></th>
                                <th scope="col"></th>
                                <th scope="col" style={{backgroundColor: '#ffff88'}}>${totalAmount}</th>
                                <th scope="col" style={{backgroundColor: '#bbFFbb'}}>${totalProfit}</th>
                            </tr>
                            <tr>
                                <th scope="col"></th>
                                <th scope="col">#</th>
                                <th scope="col">{_data.table.replace('new_coin', 'coin')}</th>
                                <th scope="col">Profit</th>
                            </tr>
                            {renderDailyProfitTableRowData(_data, i, rowLength)}
                            <tr>
                                <td scope="col"></td>
                                <td scope="col"></td>
                                <td scope="col">Profit</td>
                                <td scope="col"></td>
                            </tr>
                            <tr>
                                <td scope="col">Daily %</td>
                                <td scope="col"></td>
                                <td scope="col">%{parseFloat((totalProfit/totalAmount)*100).toFixed(2)}</td>
                                <td scope="col"></td>
                            </tr>
                        </Table>
                    </Col>
                </>
            )
        }); 
    };

    //
    return unauthorized ? (
        <Unauthorized />
      ) : (
        <>
          <Header />
          {/* Page content */}
          <Container
            className="mt--9 main-content profit-main-content"
            fluid>
            <Card className="card-height shadow">
              <CardBody className="checks-main-content">
                <div className="text-center">
                    <h2 className="mb-0">{params.month}/{params.day}/{params.year}</h2>
                </div>
                {data.length > 0 && (
                    <div className="text-right">
                        <Button
                            id="update-ticket"
                            type="submit"
                            color="primary"
                            onClick={tableUpdate}
                            >
                        Update
                        </Button>
                    </div>
                )}
                <Row>
                    {renderDailyProfitTableData()}
                </Row>
                {data.length > 0 && (
                    <div className="text-right">
                        <Button
                            id="update-ticket"
                            type="submit"
                            color="primary"
                            onClick={tableUpdate}
                            >
                        Update
                        </Button>
                    </div>
                )}
              </CardBody>
            </Card>
          </Container>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        userType: state.loginUserReducer.userType
    };
  };
  
const mapDispatchToProps = (dispatch) => {
    return {
        resetState: () => dispatch(resetState())
    };
};
  
export default connect(mapStateToProps, mapDispatchToProps)(DailyProfit);