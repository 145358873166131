import {
    FETCH_INVOICE_LISTING_FAILURE,
    FETCH_INVOICE_LISTING_SUCCESS,
    UPDATE_INVOICE_DATA_AFTER_UPDATE,
    SAVE_INVOICE_FORM_DETAILS,
    SAVE_INVOICE_ITEMS_DETAILS,
    SAVE_ITEMS_DETAILS_IN_FORM,
    RESET_SAVE_INVOICE_DETAILS,
    FETCH_SINGLE_INVOICE_SUCCESS,
    FETCH_SINGLE_INVOICE_FAILURE,
    SAVE_CUSTOMER_ITEMS_DETAILS,
    RESET,
} from "./InvoicesTypes";
  
const initialState = {
    invoices: {
        page: 0,
        count: 0,
        allInvoices: [],
        error: null
    },
    invoiceFormDetails: {
        lockedAmount: 0,
        billedTo: "Mario",
        checkNumber: "",
        deliveryDateDay: "",
        deliveryDateMonth: "",
        applyDiscount: false,
        deliveryDateYear: (new Date()).getFullYear()
    },
    invoiceItemdetails: {
        items: [],
        count: 0,
    },
    invoice: {
      model: {
        creationDate: 0,
        goldSpotPrice: 0,
        silverSpotPrice: 0,
        platinumSpotPrice: 0,
        palladiumSpotPrice: 0,
        applyDiscount: false,
        billedTo: '',
        lockedAmount: 0
      },
      items: [],
      totalAmount: 0
    }
};

  const reducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_INVOICE_LISTING_SUCCESS:
        return {
            ...state,
            invoices: {
                allInvoices: action.payload.invoices,
                page: action.payload.page,
                count: action.payload.count,
                error: null,
            },
        };
      case FETCH_INVOICE_LISTING_FAILURE:
        return {
            ...state,
            invoices: {
              page: 0,
              count: 0,
              allInvoices: [],
              error: action.payload,
            },
        };
        case SAVE_INVOICE_FORM_DETAILS:
            return {
              ...state,
              invoiceFormDetails: {
                ...state.invoiceFormDetails,
                [action.payload.name]: action.payload.value,
              },
            };
          case SAVE_CUSTOMER_ITEMS_DETAILS:
            return {
              ...state,
              invoice: {
                ... state.invoice,
                items: action.payload
              },
            };
          case SAVE_ITEMS_DETAILS_IN_FORM:
            return {
              ...state,
              invoiceItemdetails: {
                items: action.payload,
                count: action.payload.length,
              },
            };
        case UPDATE_INVOICE_DATA_AFTER_UPDATE:
            return {
              ...state,
              invoices: {
                ...state.invoices,
                allInvoices: action.payload,
                error: null,
              },
            };
        case RESET_SAVE_INVOICE_DETAILS:
            return {
                ...state,
                invoiceFormDetails: initialState.invoiceFormDetails,
                invoiceItemdetails: {
                    items: [],
                    count: 0,
                },
            };
        case FETCH_SINGLE_INVOICE_SUCCESS:
            return {
              ...state,
              invoice: action.payload
            };
        case FETCH_SINGLE_INVOICE_FAILURE:
          return {
            ...state,
            invoice: {
              model: {
                creationDate: 0,
                goldSpotPrice: 0,
                billedTo: '',
                lockedAmount: 0,
                applyDiscount: false,
              },
              items: [],
              totalAmount: 0,
              error: action.payload
            }
          };
      case RESET: {
        return initialState;
      }
      default:
        return state;
    }
  };
  
  export default reducer;
  