
import { stationOptions } from "constants/ticketConstants";

const getStationValue =( )=>{
  if(localStorage.getItem("lastUsedPrinter")){
    return stationOptions.find(s => s.value === localStorage.getItem("lastUsedPrinter"));
  }
  else{
    return { value: "", label: "None" };
  }
}

export const initialState = {
  
  receipts: {
    allReceipts: [],
    page: 0,
    count: 0,
    totalReceiptCount: 0,
    totalDailyReceiptCount: 0,
    totalReceiptCheckAmount: 0,
    totalReceiptCashAmount: 0,
    error: null,
  },
  addReceiptFormdetails: {
    payment: { value: "cash", label: "Cash" },
    booth: { name: 'Booth 5', id: 'Booth 5', value: 'Booth 5'},
    printer: getStationValue(),
    storeId: 1,
    clerk: { id: "", first_name: "" },
    fromx: {name:"" },
    keyword: "",
    lastName: "",
    firstName: "",
    note: "",
    checkNumber: "",
    tendered: "",
    creditCardNumber:"",
    ticketAmount: "",
    checkAmount: 0
  },
  receiptItemdetails: {
    items: [],
    count: 0,
  },
};


