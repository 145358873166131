import React from "react";
import {
  Modal,
  Row,
  Col,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  FormGroup,
  Input,
  Form
} from "reactstrap";
import {
    getErrorMessages,
    getallErrors,
    formValid,
  } from "../../constantFunctions/formValidations";
  import {
    PostApiWithAuthorizationAction
  } from "../../constantFunctions/apiActions";
  import { toast } from "react-toastify";
  
class SetTwoFactorAuthentication extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        formValue: {
            verification_code: "",
            verified: false
        },
        formErrors: {
            verification_code: ""
        },
    };
  }

  //Handle input changes
  handleChange = (e) => {
    const { name, value } = e.target;
    let formValues = this.state.formValue;
    formValues[name] = value;
    this.setState(
      {
        formValue: formValues,
      },
      () => {
        let formErrors = { ...this.state.formErrors };
        formErrors = getErrorMessages(formErrors, name, value);
        this.setState({ formErrors: formErrors });
      }
    );
  };

  //On Submit update details
  submitHandler = (e) => {
    e.preventDefault();
    if (this.state.formValue.verification_code.length > 0) {
        PostApiWithAuthorizationAction("users/verify-authenticator-code", {
            verification_code: this.state.formValue.verification_code
        })
        .then((res) => {
          if (res.verified) {
            this.setState({ verified: true });
            this.props.hideTwoFactorAuthenticationBtn();
          } else {
            this.setState({ formErrors: {
                verification_code: "Verification code failed."
            }});
          }
        }).catch((err) => {
            if (err.status === 440) {
                toast.error(err.message, { position: toast.POSITION.TOP_CENTER });
                this.props.history.push("/auth/login");
            }
        });
    } else {
        let errors = this.state.formErrors;
        if (errors.verification_code === "" && this.state.formValue.verification_code.length === 0) {
            errors.verification_code = "Verification code is required during this process.";
        }
        this.setState({ formErrors: errors });
    }
  };

  render() {
    const { formErrors } = this.state;
    return (
      <Modal isOpen={this.props.show} toggle={this.props.toggle} autoFocus={false}>
        <ModalHeader toggle={this.props.toggle}>Two-Factor Authentication</ModalHeader>
        <Form onSubmit={this.submitHandler}>
            <ModalBody className="py-0">
                {(this.state.verified) && (
                    <div className="text-center mt-1">
                        <img
                            src={require("assets/img/success_confirmation.png")}
                            style={{maxWidth: '220px'}}/>
                        <div style={{fontSize: '18px'}} className="pt-2">We've successfully setup the two step factor authentication for your acccount.</div>
                    </div>
                )}
                {(!this.state.verified) && (
                    <>
                        <div style={{fontSize: '18px'}}>Two-Factor authentication increases the security of your account.</div>
                        <div className="text-center py-2">
                            <img src={this.props.auth_barcode_url} style={{minWidth: '200px', border: '1px solid silver', borderRadius: '10px', boxShadow: '0px 0px 5px #bbb'}}/>
                        </div>
                        <div className="py-1">
                            Scan this image with your app. You will see a 6-digit code on your screen. Enter the code below to verify your phone and complete the setup.
                        </div>
                        <div className="py-1">
                            <FormGroup>
                                <Input
                                    className="form-control-alternative"
                                    id="input-verification-code"
                                    name="verification_code"
                                    placeholder="Verification Code"
                                    onChange={(e) => {
                                        this.handleChange(e);
                                    }}
                                    value={this.state.formValue.verification_code}
                                    autoComplete="off"
                                    type="text"
                                    maxLength="6"
                                    autoFocus={true}
                                />
                                {formErrors.verification_code.length > 0 && (
                                    <span className="text-danger">
                                        <small>{formErrors.verification_code}</small>
                                    </span>
                                )}
                            </FormGroup>
                        </div>
                    </>
                )}
            </ModalBody>
            <ModalFooter className="pt-0">
                {(!this.state.verified) && (
                    <Button type="submit" color="primary">Finish</Button>
                )}
                {(this.state.verified) && (
                    <Button type="button" color="secondary" onClick={this.props.toggle}>Close</Button>
                )}
            </ModalFooter>
        </Form>
      </Modal>
    );
  }
}

export default SetTwoFactorAuthentication;
