import Header from "components/Headers/Header";
import React, { useEffect, useState } from "react";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    Form,
    FormGroup,
    Input,
    Label,
    Row,
    Table,
} from "reactstrap";
import { toast } from "react-toastify";
import Unauthorized from "views/Pages/Unauthorized";
import Moment from "react-moment";
import moment from "moment";
import { GetApiActionWithAuthorization, PostApiWithAuthorizationAction } from "../../constantFunctions/apiActions";
import ReactPaginate from "react-paginate";
import { monthOptions, dayOptions, paidOptions } from "../../constants/ticketConstants";
import { DropdownList } from "react-widgets";
import { resetState } from "../../redux/common/commonAction";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

function HighValueTickets(props) {
    const [unauthorized, setUnauthorized] = useState(false);
    const [years, setYears] = useState([]);
    const [data, setData] = useState({
        data: {
            coins3k: [],
            tickets3k: [],
            receipts3k: [],
            coins10k: [],
            tickets10k: [],
            receipts10k: [],
            jewelreceipts3k: [],
            jewelreceipts10k: []
        },
        titles: {
            coins3k: 'Coin > $3k',
            coins10k: 'Coin > $10k',
            tickets3k: 'Metro > $3k',
            tickets10k: 'Metro > $10k',
            receipts3k: 'Coin Receipt > $3k',
            receipts10k: 'Coin Receipt > $10k',
            jewelreceipts3k: 'Jewelry Receipt > $3k',
            jewelreceipts10k: 'Jewelry Receipt > $10k'
        }
    });
    const [filter, setFilter] = useState({
        page: 0,
        startMonth: monthOptions.find(m=>m.value==(moment().subtract(1, 'months').format('M'))).value,
        startDay: (new Date()).getDate(),
        startYear: (new Date()).getFullYear(),
        endMonth: monthOptions.find(m=>m.value==(moment().format('M'))).value,
        endDay: (new Date()).getDate(),
        endYear: (new Date).getFullYear()
    });

    useEffect(() => {
        load();
        //
        GetApiActionWithAuthorization("invoices/get-all-years").then((res) => {
            setYears(res.data)
        });
    }, []);

    if (data.error && !unauthorized) {
        if (data.error.status === 403) {
          setUnauthorized(true);
        } else if (data.error.status === 440) {
          toast.error(data.error.message, {
            position: toast.POSITION.TOP_CENTER,
          });
          props.history.push("/auth/login");
        }
    }

    const resetFilter = () => {
        setFilter({
            ... filter,
            page: 0
        })
    };

  const load = (params) => {
    Object.assign(filter, params);
    GetApiActionWithAuthorization("high-value-ticket/get-all-data/", filter)
    .then((res) => {
        setData(res)
    })
    .catch((err) => {
      if (err.status === 403) {
        setUnauthorized(true);
      } else if (err.status === 440) {
        props.resetState();
        toast.error(err.message, { position: toast.POSITION.TOP_CENTER });
        props.history.push("/auth/login");
      }
    });
  };
    const hasReferTableData = (table) => {
        return (typeof data.data[table] !== 'undefined');
    };
    const generateHighValueTables = () => {
        let datas = Object.entries(data.data);
        return Object.entries(data.data).forEach(([key, tickets]) => {
            return (<Col md="2">
                    <>
                        <div className="font-weight-bold">{data.titles[key]}</div>
                        <Table className="table-listing items-list-table" responsive bordered>
                            {renderReferHeadTableData()}
                            <tbody>{renderReferBodyTableData(tickets, key.toUpperCase()[0])}</tbody>
                        </Table>
                    </>
            </Col>)
        });
    };
    const renderReferHeadTableData = () => {
        return (
            <thead className="thead-light">
                <tr>
                    <th scope="col">#</th>
                    <th scope="col">Date</th>
                    <th scope="col">Amount</th>
                </tr>
            </thead>
        );
    };

    const renderReferBodyTableData = (table, prefix = '', url = '') => {
        return data.data[table].map((_data, i) => {
            return (
                <>
                    <tr>
                        <td scope="col">
                            <a href={url + _data.id} target="_blank">
                                {prefix + _data.id}
                            </a>
                        </td>
                        <td scope="col">
                            <Moment format="MM/DD/YY" tz="America/Los_Angeles">
                            {_data.ticketTime.toString().length === 10
                                ? _data.ticketTime * 1000
                                : _data.ticketTime}
                            </Moment>
                        </td>
                        <td scope="col">{parseInt(_data.ticketAmount)}</td>
                    </tr>
                </>
            )
        });
    };

    //
    return unauthorized ? (
        <Unauthorized />
      ) : (
        <>
          <Header />
          {/* Page content */}
          <Container className="mt--9 main-content" fluid>
            <Card className="card-height shadow">
              <CardHeader>
                <div className="text-center">
                    <h1>High Value Tickets</h1>
                </div>
              </CardHeader>
              <CardBody className="checks-main-content page-main-content">
                <Row className="mb-3">
                    <Col md="12">
                        <Row>
                        <Col md="4" className="d-flex d-flex-full mt-1">
                            <span class="mx-1" style={{fontSize: "22px"}}>Start: </span>
                            <DropdownList
                                data={monthOptions}
                                value={filter.startMonth}
                                placeholder="Month"
                                textField="label"
                                valueField='value'
                                onChange={(e) => {
                                    setFilter({
                                        ... filter,
                                        startMonth: e.value
                                    })
                                }}
                            />
                        <div class="mx-1" style={{fontSize: "24px"}}> / </div>
                        <DropdownList
                            data={dayOptions}
                            value={filter.startDay}
                            placeholder="Day"
                            onChange={(e) => {
                            setFilter({
                                ... filter,
                                startDay: e
                            })
                            }}
                        />
                        <span class="mx-1" style={{fontSize: "24px"}}> / </span>
                        <DropdownList
                            data={years}
                            value={filter.startYear}
                            placeholder="Year"
                            onChange={(e) => {
                            setFilter({
                                ... filter,
                                startYear: e
                            })
                            }}
                        />
                        </Col>
                        <Col md="4" className="d-flex d-flex-full pl-0 mt-1">
                            <span class="mx-1" style={{fontSize: "22px"}}>End: </span>
                            <DropdownList
                            data={monthOptions}
                            value={filter.endMonth}
                            placeholder="Month"
                            textField="label"
                            valueField='value'
                            onChange={(e) => {
                                setFilter({
                                ... filter,
                                endMonth: e.value
                                })
                            }}
                            />
                        <div class="mx-1" style={{fontSize: "24px"}}> / </div>
                        <DropdownList
                            data={dayOptions}
                            value={filter.endDay}
                            placeholder="Day"
                            onChange={(e) => {
                            setFilter({
                                ... filter,
                                endDay: e
                            })
                            }}
                        />
                        <span class="mx-1" style={{fontSize: "24px"}}> / </span>
                        <DropdownList
                            data={years}
                            value={filter.endYear}
                            placeholder="Year"
                            onChange={(e) => {
                                setFilter({
                                    ... filter,
                                    endYear: e
                                })
                            }}
                        />
                        </Col>
                        <Col md="3" className="d-flex d-flex-full mt-1 pl-0">
                            <Button
                            id="btn-get-worksheet"
                            size="sm"
                            className="ml-2"
                            onClick={(e) => {
                                setFilter({
                                    ... filter,
                                    page: 0
                                })
                                load({
                                    page: 0
                                });
                            }}
                            color="primary">Submit</Button>
                        </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className="mb-2">
                    <Col md="3">
                        <div className="font-weight-bold" style={{fontSize: '16px'}}>{data.titles['coins3k']}</div>
                        <Table className="table-listing items-list-table high-value-ticket-table" responsive bordered>
                            {renderReferHeadTableData()}
                            <tbody>{renderReferBodyTableData('coins3k', 'C', '/portal/coin-tickets?id=')}</tbody>
                        </Table>
                    </Col>
                    <Col md="3">
                        <div className="font-weight-bold" style={{fontSize: '16px'}}>{data.titles['coins10k']}</div>
                        <Table className="table-listing items-list-table high-value-ticket-table" responsive bordered>
                            {renderReferHeadTableData()}
                            <tbody>{renderReferBodyTableData('coins10k', 'C', '/portal/coin-tickets?id=')}</tbody>
                        </Table>
                    </Col>
                    <Col md="3">
                        <div className="font-weight-bold" style={{fontSize: '16px'}}>{data.titles['tickets3k']}</div>
                        <Table className="table-listing items-list-table high-value-ticket-table" responsive bordered>
                            {renderReferHeadTableData()}
                            <tbody>{renderReferBodyTableData('tickets3k', 'M', '/portal/metro-tickets?id=')}</tbody>
                        </Table>
                    </Col>
                    <Col md="3">
                        <div className="font-weight-bold" style={{fontSize: '16px'}}>{data.titles['tickets10k']}</div>
                        <Table className="table-listing items-list-table high-value-ticket-table" responsive bordered>
                            {renderReferHeadTableData()}
                            <tbody>{renderReferBodyTableData('tickets10k', 'M', '/portal/metro-tickets?id=')}</tbody>
                        </Table>
                    </Col>
                </Row>
                <Row>
                    <Col md="3">
                        <div className="font-weight-bold" style={{fontSize: '16px'}}>{data.titles['receipts3k']}</div>
                        <Table className="table-listing items-list-table high-value-ticket-table" responsive bordered>
                            {renderReferHeadTableData()}
                            <tbody>{renderReferBodyTableData('receipts3k', 'CR', '/invoice-receipt/CR')}</tbody>
                        </Table>
                    </Col>
                    <Col md="3">
                        <div className="font-weight-bold" style={{fontSize: '16px'}}>{data.titles['receipts10k']}</div>
                        <Table className="table-listing items-list-table high-value-ticket-table" responsive bordered>
                            {renderReferHeadTableData()}
                            <tbody>{renderReferBodyTableData('receipts10k', 'CR', '/invoice-receipt/CR')}</tbody>
                        </Table>
                    </Col>
                    <Col md="3">
                        <div className="font-weight-bold" style={{fontSize: '16px'}}>{data.titles['jewelreceipts3k']}</div>
                        <Table className="table-listing items-list-table high-value-ticket-table" responsive bordered>
                            {renderReferHeadTableData()}
                            <tbody>{renderReferBodyTableData('jewelreceipts3k', 'JR', '/invoice-receipt/JR')}</tbody>
                        </Table>
                    </Col>
                    <Col md="3">
                        <div className="font-weight-bold" style={{fontSize: '16px'}}>{data.titles['jewelreceipts10k']}</div>
                        <Table className="table-listing items-list-table high-value-ticket-table" responsive bordered>
                            {renderReferHeadTableData()}
                            <tbody>{renderReferBodyTableData('jewelreceipts10k', 'JR', '/invoice-receipt/JR')}</tbody>
                        </Table>
                    </Col>
                </Row>
              </CardBody>
            </Card>
          </Container>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        userType: state.loginUserReducer.userType
    };
  };
  
const mapDispatchToProps = (dispatch) => {
    return {
        resetState: () => dispatch(resetState())
    };
};
  
export default connect(mapStateToProps, mapDispatchToProps)(HighValueTickets);