import { PostApiWithAuthorizationAction } from "constantFunctions/apiActions";
import React, { useState } from "react";
import { toast } from "react-toastify";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button, Row, Col, FormGroup, Label, Input
} from "reactstrap";
//import "slick-carousel/slick/slick.css"; 
//import "slick-carousel/slick/slick-theme.css";
//import Slider from "react-slick";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

function ViewAttachment(props) {
    const renderSlider = () => {
        console.log(props.data)
        return props.data.map((d, i) => {
            let maxHeight = 'calc(100vh - 120px)';
            return (
                <>
                <a
                    href={d.url}
                    class="fileuploader-action fileuploader-action-download-popup"
                    title="View File"
                    target="_blank"
                    style={{position: 'absolute', right: "20%", top: "5px"}}>
                    <i class="fas fa-search-plus"></i>
                </a>
                <img width="100%" style={{maxHeight}} src={d.url} key={i}/>
                </>
            );
        });
    }
  return (
    <Modal isOpen={props.show} toggle={props.toggle}>
      <ModalHeader toggle={props.toggle}>View Attachments</ModalHeader>
      <ModalBody className="py-0 pb-3">
        <Carousel showThumbs={false} infiniteLoop={true}>
          {renderSlider()}
        </Carousel>
      </ModalBody>
    </Modal>
  );
}

export default ViewAttachment;
