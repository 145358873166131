import Header from "components/Headers/Header";
import React, { useEffect, useState } from "react";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    Form,
    FormGroup,
    Input,
    Label,
    Row,
    Table,
} from "reactstrap";
import { toast } from "react-toastify";
import Unauthorized from "views/Pages/Unauthorized";
import Moment from "react-moment";
import moment from "moment";
import { GetApiActionWithAuthorization, PostApiWithAuthorizationAction } from "../../constantFunctions/apiActions";
import ReactPaginate from "react-paginate";
import { connect } from "react-redux";

function DrawerDisparityLog(props) {
    const [unauthorized, setUnauthorized] = useState(false);
    const [drawerDisparityLogs, setDrawerDisparityLogs] = useState({
        data: [],
        count: 0
    });
    const [filter, setFilter] = useState({
        page: 0
    });

    useEffect(() => {
        load();
    }, []);

    if (drawerDisparityLogs.error && !unauthorized) {
        if (drawerDisparityLogs.error.status === 403) {
          setUnauthorized(true);
        } else if (drawerDisparityLogs.error.status === 440) {
          toast.error(drawerDisparityLogs.error.message, {
            position: toast.POSITION.TOP_CENTER,
          });
          props.history.push("/auth/login");
        }
    }

  const load = (params) => {
    params = Object.assign(filter, params);
    GetApiActionWithAuthorization("common/get-all-disparity-log", filter)
    .then((res) => {
        setDrawerDisparityLogs({
          ... res
        })
    })
    .catch((err) => {
      if (err.status === 403) {
        setUnauthorized(true);
      } else if (err.status === 440) {
        props.resetState();
        toast.error(err.message, { position: toast.POSITION.TOP_CENTER });
        props.history.push("/auth/login");
      }
    });
  };

    const renderTableData = () => {
        return drawerDisparityLogs.data.map((_data, i) => {
            return (
                <>
                    <tr>
                        <td scope="col">{_data.drawerName}</td>
                        <td scope="col">{_data.drawerAmount}</td>
                        <td scope="col">{_data.drawerCount}</td>
                        <td scope="col">
                            <Moment format="MM/DD/YY HH:mm" tz="America/Los_Angeles">
                                {_data.logTime.toString().length === 10
                                    ? new Date(_data.logTime * 1000)
                                    : new Date(_data.logTime)}
                            </Moment>
                        </td>
                    </tr>
                </>
            )
        });
    };

    //
    return unauthorized ? (
        <Unauthorized />
      ) : (
        <>
          <Header />
          {/* Page content */}
          <Container className="mt--9 main-content" fluid>
            <Card className="card-height shadow">
              <CardHeader>
                <div className="text-center">
                    <h1>Drawer Disparity Logs</h1>
                </div>
              </CardHeader>
              <CardBody className="checks-main-content page-main-content">
                <Table className="ticket-table table-listing locks-table" responsive bordered>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Drawer</th>
                      <th scope="col">Amount</th>
                      <th scope="col">Count</th>
                      <th scope="col">Confirmed At</th>
                    </tr>
                  </thead>
                  <tbody>{renderTableData()}</tbody>
                </Table>
                <ReactPaginate
                  previousLabel={<i className="fas fa-chevron-left"></i>}
                  nextLabel={<i className="fas fa-chevron-right"></i>}
                  breakLabel={".."}
                  breakClassName={"break-me"}
                  initialPage={drawerDisparityLogs.page}
                  pageCount={drawerDisparityLogs.count / 50}
                  marginPagesDisplayed={1}
                  pageRangeDisplayed={2}
                  onPageChange={(e) => {
                    if (filter.page !== e.selected) {
                      setFilter({
                        ...filter,
                        page: e.selected
                      })
                      load({
                        page: e.selected
                      });
                    }
                  }}
                  containerClassName={"pagination"}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"active"}
                />
              </CardBody>
            </Card>
          </Container>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        userType: state.loginUserReducer.userType
    };
  };
  
const mapDispatchToProps = (dispatch) => {
    return {};
};
  
export default connect(mapStateToProps, mapDispatchToProps)(DrawerDisparityLog);