import React, { useEffect, useState } from "react";
import {
  Input,
  Label,
  Form,
  Table,
  Col,
  Row,
  Button,
  InputGroup,
  InputGroupAddon
} from "reactstrap";
import {
  sexOptions,
  hairOptions,
  paymentOptions,
  eyesOptions,
  boothOptions,
  stationOptions,
  raceOptions,
} from "../../../constants/ticketConstants";
import { DropdownList } from "react-widgets";
import { GetApiActionWithAuthorization } from "constantFunctions/apiActions";
import { PostApiWithAuthorizationAction } from "constantFunctions/apiActions";
import Autocomplete from "components/AutoComplete/Autocomplete";
import { enableFileUploader } from "libs/common";
import moment from "moment-timezone";

function AddMetroTicketForm(props) {
  const [items, setitems] = useState([]);
  const [keywords, setKeywords] = useState([]);
  const [fromOptions, setFromOption] = useState([]);
  const [ethSpotPrice, setETHSpotPrice] = useState(0);
  const [btcSpotPrice, setBTCSpotPrice] = useState(0);
  const [resetTime, setResetTime] = useState(false);
  const [formBlocked, setFormBlocked] = useState(false);
  const [newItems, setNewItems] = useState([{
    qty: 1,
    name: "",
    unit: "0.00",
    cost: ""
  }])
  const [newBarterItems, setNewBarterItems] = useState([{
    qty: 1,
    name: "",
    unit: "0.00",
    cost: ""
  }])
  const [newItem, setNewItem] = useState({
    qty: 1,
    name: "",
    unit: "0.00",
    cost: "",
  });
  const [newBarterItem, setNewBarterItem] = useState({
    qty: 1,
    name: "",
    unit: "0.00",
    cost: "",
  });
  const [keyLoad, setKeyLoad] = useState(false);
  const [boothOptions, setBoothOptions] = useState([]);
  const [checkNumberPrefix, setCheckNumberPrefix] = useState('');
  useEffect(() => {
    props.fetchAllStoresListing();
    fetchAllBooth();
    fetchAllClerks();
    getFromOptions();
    if (localStorage.getItem("lastUsedPrinter")) {
      props.saveCoinFormDetails({
        name: "station",
        value: stationOptions.find(
          (s) => s.value === localStorage.getItem("lastUsedPrinter")
        ),
      });
    }

    GetApiActionWithAuthorization("common/get-items-select-options").then(
      (res) => {
        setitems(res.data);
      }
    );

    // ___________Get spot prices for ETH and BTC______________
    GetApiActionWithAuthorization("common/get-all-spot-prices").then((res) => {
      setBTCSpotPrice(res.data.find((s) => s.item === "btc").price);
      setETHSpotPrice(res.data.find((s) => s.item === "eth").price);
    });

    //load fileuploader with plain jquery
    window.jQuery(document).ready(function() {
      enableFileUploader();
    });


    GetApiActionWithAuthorization("common/get-check-setting").then((res) => {
      props.saveCoinFormDetails({
        name: "checkNumber",
        value: res.data.coinCheckNumberPrefix,
      });
      //
      setCheckNumberPrefix(res.data.coinCheckNumberPrefix.toString());
    });
  }, []);

  // ___________Get spot prices for ETH and BTC(every hour changes)______________
  useEffect(() => {
    let timer = setTimeout(() => {
      if (new Date().getMinutes() === 2) {
        setResetTime(!resetTime);
        GetApiActionWithAuthorization("common/get-all-spot-prices").then(
          (res) => {
            setBTCSpotPrice(res.data.find((s) => s.item === "btc").price);
            setETHSpotPrice(res.data.find((s) => s.item === "eth").price);
          }
        );
      } else {
        setResetTime(!resetTime);
      }
    }, 30000);
    return () => {
      clearTimeout(timer);
    };
  }, [resetTime]);

  const fetchAllClerks = (e) => {
    //if (e) {
      props.fetchAllClerkListing();
    //}
  };

  const fetchAllBooth = (e) => {
    //if (e) {
      GetApiActionWithAuthorization("common/get-all-booths?displayOrderType=Coin").then((res) => {
        setBoothOptions(res.data);
      });
    //}
  };

  const changeFirstNameAndLastNameHandler = (e) => {
    const { name, value } = e.target;
    props.saveCoinFormDetails({
      name: name,
      value: value,
    });
    let secondName = name === "firstName" ? "lastName" : "firstName";
    if (props.coinFormDetails[secondName] !== "" && value !== "") {
      checkFormBlocked({
        [name]: value.trim(),
        [secondName]: props.coinFormDetails[secondName].trim(),
      });
    }
  };

  const checkFormBlocked = (body) => {
    PostApiWithAuthorizationAction("common/check-block-pass", body).then((res) => {
      props.blocked(res.blocked);
      setFormBlocked(res.blocked);
    });
  };

  const changeDlAndOtherNumberHandler = (e) => {
    const { name, value } = e.target;
    props.saveCoinFormDetails({
      name,
      value,
    });
    if (value !== "") {
      let body = {
        [name]: value.trim()
      };
      let firstName = props.coinFormDetails.firstName;
      if (firstName.length > 0) {
        body.firstName = firstName;
      //}
      PostApiWithAuthorizationAction(
        "common/fetch-other-details",
        body
      ).then((res) => {
        //props.blocked(res.blocked);
        let details = res.otherDetails;
        //
        if (details) {
          checkFormBlocked({
            firstName: details.firstName,
            lastName: details.lastName
          });

          props.saveCoinFormDetails({
            name: "fromx",
            value: fromOptions[1],
          });

          for (const item in details) {
            if (item === "race") {
              props.saveCoinFormDetails({
                name: item,
                value: raceOptions.find((r) => r.value === details[item]),
              });
            }/* else if (item === "sex") {
              props.saveCoinFormDetails({
                name: item,
                value: sexOptions.find((r) => r.value === details[item]),
              });
            } else if (item === "eyes") {
              props.saveCoinFormDetails({
                name: item,
                value: eyesOptions.find((r) => r.value === details[item]),
              });
            } else if (item === "hair") {
              props.saveCoinFormDetails({
                name: item,
                value: hairOptions.find((r) => r.value === details[item]),
              });
            } else {
              props.saveCoinFormDetails({
                name: item,
                value: details[item],
              });
            }*/
          }

          //if not Blocked set from to repeat
          if (!formBlocked) {
            props.saveCoinFormDetails({
              name: "fromx",
              value: { name: "repeat" },
            });
          }
        }
      });
      }
    }
  };

  const changeItemValues = (name, value, index) => {
    let temp = newItems;
    let itemObj = temp[index];
    itemObj[name] = value;
    temp[index] = itemObj;
    if (name === "price") {
        itemObj["price"] = parseFloat(value).toFixed(2);
    }
    props.saveCoinItemDetails(temp);
    setNewItems([... temp]);
  };

  const removeRowInItem = (index) => {
    let olderItems = newItems;
    olderItems.splice(index, 1);
    props.saveCoinItemDetails(olderItems);
    setNewItems([... olderItems]);
  };

  const renderAllItemsHTML = (e) => {
    return newItems.map((newItem, i) => {
      return (
        <tr key={i} id={"property-description-item-row" + i}>
          <td>
            <Input
              id="create-qty"
              type="number"
              value={newItem.qty}
              disabled
            />
          </td>
          <td style={{ width: "35%" }}>
            <DropdownList
              id="create-item"
              data={items}
              dropUp={true}
              textField="name"
              containerClassName="item-drop"
              value={newItem.name}
              onChange={(e) => {
                changeItemValues("name", e, i);
                if (e.name.toLowerCase() === "eth") {
                  changeItemValues("unit", ethSpotPrice, i);
                  changeItemValues("qty", newItem.cost
                  ? (
                      parseFloat(newItem.cost) /
                      parseFloat(ethSpotPrice)
                    ).toFixed(8)
                  : "", i);
                } else if (e.name.toLowerCase() === "btc") {
                  changeItemValues("unit", btcSpotPrice, i);
                  changeItemValues("qty", newItem.cost
                  ? (
                      parseFloat(newItem.cost) /
                      parseFloat(btcSpotPrice)
                    ).toFixed(8)
                  : "", i);
                } else {
                  changeItemValues("unit", newItem.cost
                  ? newItem.cost / newItem.qty
                  : "0.00", i);
                  changeItemValues("qty", newItem.name &&
                  newItem.name.name.toLowerCase() !== "eth" &&
                  newItem.name.name.toLowerCase() !== "btc"
                    ? newItem.qty
                    : 1, i);
                }
              }}
            />
          </td>
          <td>
            ${newItem.unit && parseFloat(newItem.unit).toFixed(2)}
          </td>
          <td>
            <Input
              id="create-cost"
              type="text"
              value={newItem.cost}
              onChange={(e) => {
                changeItemValues("cost", e.target.value, i);
                if (
                  newItem.name &&
                  newItem.name.name.toLowerCase() === "btc"
                ) {
                  changeItemValues("unit", btcSpotPrice, i);
                  changeItemValues("qty", (
                    parseFloat(e.target.value) /
                    parseFloat(btcSpotPrice)
                  ).toFixed(8), i);
                } else if (
                  newItem.name &&
                  newItem.name.name.toLowerCase() === "eth"
                ) {
                  changeItemValues("unit", ethSpotPrice, i);
                  changeItemValues("qty", (
                    parseFloat(e.target.value) /
                    parseFloat(ethSpotPrice)
                  ).toFixed(8), i);
                } else {
                  changeItemValues("unit", e.target.value / newItem.qty, i);
                }
              }}
            />
          </td>
          <td>
            {" "}
            {(newItems.length===(i+1)) && (
            <Button
              className="icon-cross"
              id="add-item"
              color="primary"
              onClick={(e) => {
                //let it = props.items.items;
                //it.push(newItem);
                //props.saveCoinItemDetails(it);
                /*setNewItem({
                  qty: 1,
                  name: "",
                  unit: "0.00",
                  cost: "",
                });*/
                let items = newItems;
                items.push({
                  qty: 1,
                  name: "",
                  unit: "0.00",
                  cost: "",
                });
                setNewItems([... items]);
              }}
              disabled={
                newItem.name === "" || !newItem.cost || !newItem.qty
              }
            >
              <i class="fas fa-plus-square"></i>
            </Button>
          )}
          {(newItems.length > 1)&& (
            <Button
              id="remove"
              className="icon-cross"
              color="danger"
              title="remove"
              onClick={() => {
                removeRowInItem(i);
              }}
            >
              <i className="fas fa-times"></i>
            </Button>)}
          </td>
        </tr>
      );
    });
  }

  /**
   * Barter Items 
   */
   const changeBarterItemValues = (name, value, index) => {
    let temp = newBarterItems;
    let itemObj = temp[index];
    itemObj[name] = value;
    temp[index] = itemObj;
    if (name === "price") {
        itemObj["price"] = parseFloat(value).toFixed(2);
    }
    props.saveCoinBarterItemDetails(temp);
    setNewBarterItems([... temp]);
  };

  const removeRowInBarterItem = (index) => {
    let olderItems = newBarterItems;
    olderItems.splice(index, 1);
    props.saveCoinBarterItemDetails(olderItems);
    setNewBarterItems([... olderItems]);
  };

  const renderAllBarterItemsHTML = (e) => {
    return newBarterItems.map((newItem, i) => {
      return (
        <tr key={i} id={"property-description-item-row" + i}>
          <td>
            <Input
              id="create-qty"
              type="number"
              value={newItem.qty}
              disabled
            />
          </td>
          <td style={{ width: "35%" }}>
            <DropdownList
              id="create-item"
              data={items}
              dropUp={true}
              textField="name"
              containerClassName="item-drop"
              value={newItem.name}
              onChange={(e) => {
                changeBarterItemValues("name", e, i);
                if (e.name.toLowerCase() === "eth") {
                  changeBarterItemValues("unit", ethSpotPrice, i);
                  changeBarterItemValues("qty", newItem.cost
                  ? (
                      parseFloat(newItem.cost) /
                      parseFloat(ethSpotPrice)
                    ).toFixed(8)
                  : "", i);
                } else if (e.name.toLowerCase() === "btc") {
                  changeBarterItemValues("unit", btcSpotPrice, i);
                  changeBarterItemValues("qty", newItem.cost
                  ? (
                      parseFloat(newItem.cost) /
                      parseFloat(btcSpotPrice)
                    ).toFixed(8)
                  : "", i);
                } else {
                  changeBarterItemValues("unit", newItem.cost
                  ? newItem.cost / newItem.qty
                  : "0.00", i);
                  changeBarterItemValues("qty", newItem.name &&
                  newItem.name.name.toLowerCase() !== "eth" &&
                  newItem.name.name.toLowerCase() !== "btc"
                    ? newItem.qty
                    : 1, i);
                }
              }}
            />
          </td>
          <td>
            ${newItem.unit && parseFloat(newItem.unit).toFixed(2)}
          </td>
          <td>
            <Input
              id="create-cost"
              type="text"
              value={newItem.cost}
              onChange={(e) => {
                changeBarterItemValues("cost", e.target.value, i);
                if (
                  newItem.name &&
                  newItem.name.name.toLowerCase() === "btc"
                ) {
                  changeBarterItemValues("unit", btcSpotPrice, i);
                  changeBarterItemValues("qty", (
                    parseFloat(e.target.value) /
                    parseFloat(btcSpotPrice)
                  ).toFixed(8), i);
                } else if (
                  newItem.name &&
                  newItem.name.name.toLowerCase() === "eth"
                ) {
                  changeBarterItemValues("unit", ethSpotPrice, i);
                  changeBarterItemValues("qty", (
                    parseFloat(e.target.value) /
                    parseFloat(ethSpotPrice)
                  ).toFixed(8), i);
                } else {
                  changeBarterItemValues("unit", e.target.value / newItem.qty, i);
                }
              }}
            />
          </td>
          <td>
            {" "}
            {(newBarterItems.length===(i+1)) && (
            <Button
              className="icon-cross"
              id="add-item"
              color="primary"
              onClick={(e) => {
                //let it = props.items.items;
                //it.push(newItem);
                //props.saveCoinItemDetails(it);
                /*setNewItem({
                  qty: 1,
                  name: "",
                  unit: "0.00",
                  cost: "",
                });*/
                let items = newBarterItems;
                items.push({
                  qty: 1,
                  name: "",
                  unit: "0.00",
                  cost: "",
                });
                setNewBarterItems([... items]);
              }}
              disabled={
                newItem.name === "" || !newItem.cost || !newItem.qty
              }
            >
              <i class="fas fa-plus-square"></i>
            </Button>
          )}
          {(newBarterItems.length > 1)&& (
            <Button
              id="remove"
              className="icon-cross"
              color="danger"
              title="remove"
              onClick={() => {
                removeRowInBarterItem(i);
              }}
            >
              <i className="fas fa-times"></i>
            </Button>)}
          </td>
        </tr>
      );
    });
  }

  // const getKeywords = (e) => {
  //   if (keywords.length === 0) {
  //     setKeyLoad(true);
  //     GetApiActionWithAuthorization("common/get-keywords-for-form")
  //       .then((res) => {
  //         setKeyLoad(false);
  //         setKeywords(res.data);
  //       })
  //       .catch((err) => setKeyLoad(false));
  //   }
  // };

  const getFromOptions = (e) => {
    //if (e && fromOptions.length === 0) {
      setKeyLoad(true);
      GetApiActionWithAuthorization("common/get-from-select-options")
        .then((res) => {
          setKeyLoad(false);
          setFromOption(res.data);
        })
        .catch((err) => setKeyLoad(false));
    //}
  };
  return (
    <Form>
      <Table className="add-ticket-table" bordered responsive="sm">
        <tbody>
          <tr className="back-grey">
            <th>Ticket Amount:</th>
            <td className="width-middle">
              <Input
                id="ticketAmount"
                type="number"
                step="1"
                placeholder="Ticket Amount"
                autoComplete="off"
                name="ticketAmount"
                value={props.coinFormDetails.ticketAmount}
                autoFocus={true}
                onChange={(e) => {
                  props.saveCoinFormDetails({
                    name: e.target.name,
                    value: e.target.value,
                  });
                }}
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
              />
            </td>
            <td className="width-last"></td>
          </tr>
          <tr
            className={
              props.coinFormDetails.payment.value === "cash"
                ? "back-green"
                : "back-orange"
            }
          >
            <th>Payment:</th>
            <td
              className="width-middle"
              colSpan={
                props.coinFormDetails.payment.value === "check" ? "2" : 1
              }
            >
              <div className="d-flex align-items-end check">
                <DropdownList
                  id="payment"
                  data={paymentOptions}
                  containerClassName="payment-drop"
                  textField="label"
                  value={props.coinFormDetails.payment}
                  onChange={(e) =>
                    props.saveCoinFormDetails({ name: "payment", value: e })
                  }
                />
                {props.coinFormDetails.payment.value === "check" && (
                  <>
                    <Label className="ml-2">Check Amount:</Label>
                    <Input
                      id="paymentCheckAmount"
                      type="number"
                      className="ml-1"
                      placeholder="Check Amount"
                      autoComplete="off"
                      name="paymentCheckAmount"
                      value={props.coinFormDetails.paymentCheckAmount}
                      onChange={(e) =>
                        props.saveCoinFormDetails({
                          name: e.target.name,
                          value: e.target.value,
                        })
                      }
                    />
                    <Label className="ml-2">Check Number:</Label>
                    {(props.userType === 'admin') && (
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <Button
                            size="sm"
                            className="ml-1"
                            color="danger"
                            title="Set Check Number Prefix"
                            onClick={(e) => {
                              e.preventDefault();
                              //
                              props.history.push("/portal/setting-checks");
                            }}>
                            <i class="fas fa-money-check-alt"></i>
                          </Button>
                        </InputGroupAddon>
                        <Input
                          id="checkNumber"
                          type="text"
                          placeholder="Check Number"
                          autoComplete="off"
                          name="checkNumber"
                          value={props.coinFormDetails.checkNumber}
                          onChange={(e) => {
                            props.saveCoinFormDetails({
                              name: e.target.name,
                              value: e.target.value,
                            });
                          }}
                        />
                    </InputGroup>
                    )}
                    {(props.userType !== 'admin') && (
                      <Input
                        id="checkNumber"
                        type="text"
                        className="ml-1"
                        placeholder="Check Number"
                        autoComplete="off"
                        name="checkNumber"
                        value={props.coinFormDetails.checkNumber}
                        onChange={(e) => {
                          let characterLength = e.target.value.length
                          let checkNumberPrefixLength = checkNumberPrefix.length
                          if (characterLength >= checkNumberPrefixLength) {
                            props.saveCoinFormDetails({
                              name: e.target.name,
                              value: e.target.value,
                            });
                          }
                        }}
                      />
                    )}
                  </>
                )}
              </div>
            </td>
            {props.coinFormDetails.payment.value !== "check" && (
              <td className="width-last"></td>
            )}
          </tr>
          <tr>
            <th>First Name:</th>
            <td className="width-middle">
              <Input
                id="firstName"
                type="text"
                placeholder="First Name"
                name="firstName"
                autoComplete="off"
                value={props.coinFormDetails.firstName}
                onChange={changeFirstNameAndLastNameHandler}
              />
            </td>
            <td className="width-last"></td>
          </tr>
          <tr>
            <th className="back-grey">Middle Name:</th>
            <td className="width-middle">
              <Input
                id="middleName"
                type="text"
                placeholder="Middle Name"
                name="middleName"
                autoComplete="off"
                value={props.coinFormDetails.middleName}
                onChange={(e) =>
                  props.saveCoinFormDetails({
                    name: e.target.name,
                    value: e.target.value,
                  })
                }
              />
            </td>
            <td className="width-last"></td>
          </tr>
          <tr>
            <th>Last Name:</th>
            <td className="width-middle">
              <Input
                id="lastName"
                type="text"
                placeholder="Last Name"
                name="lastName"
                autoComplete="off"
                value={props.coinFormDetails.lastName}
                onChange={changeFirstNameAndLastNameHandler}
              />
            </td>
            <td className="width-last"></td>
          </tr>
          <tr className="back-grey">
            <th>Address:</th>
            <td className="width-middle">
              <Input
                id="address"
                type="text"
                placeholder="Address"
                name="address"
                autoComplete="off"
                value={props.coinFormDetails.address}
                onChange={(e) =>
                  props.saveCoinFormDetails({
                    name: e.target.name,
                    value: e.target.value,
                  })
                }
              />
            </td>
            <td className="width-last"></td>
          </tr>
          <tr>
            <th>City:</th>
            <td className="width-middle">
              <Input
                id="city"
                type="text"
                placeholder="City"
                name="city"
                autoComplete="off"
                value={props.coinFormDetails.city}
                onChange={(e) =>
                  props.saveCoinFormDetails({
                    name: e.target.name,
                    value: e.target.value,
                  })
                }
              />
            </td>
            <td className="width-last"></td>
          </tr>
          <tr className="back-grey">
            <th>State:</th>
            <td className="width-middle">
              <Input
                id="state"
                type="text"
                placeholder="State"
                name="state"
                autoComplete="off"
                onInput={(e) => {
                  e.target.value = e.target.value.toString().slice(0, 2);
                }}
                value={props.coinFormDetails.state}
                onChange={(e) =>
                  props.saveCoinFormDetails({
                    name: e.target.name,
                    value: e.target.value,
                  })
                }
              />
            </td>
            <td className="width-last"></td>
          </tr>
          <tr>
            <th>Zip:</th>
            <td className="width-middle">
              <Input
                id="zip"
                type="number"
                placeholder="Zip"
                name="zip"
                onInput={(e) => {
                  e.target.value = Math.max(0, parseInt(e.target.value))
                    .toString()
                    .slice(0, 12);
                }}
                value={props.coinFormDetails.zip}
                onChange={(e) =>
                  props.saveCoinFormDetails({
                    name: e.target.name,
                    value: e.target.value,
                  })
                }
                autoComplete="off"
              />
            </td>
            <td className="width-last"></td>
          </tr>
          <tr className="back-grey">
            <th>Sex:</th>
            <td className="width-middle">
              <div className="d-flex align-items-end">
                <DropdownList
                  id="sex"
                  data={sexOptions}
                  containerClassName="payment-drop"
                  textField="label"
                  valueField="value"
                  value={props.coinFormDetails.sex}
                  onChange={(e) =>
                    props.saveCoinFormDetails({ name: "sex", value: e })
                  }
                />
              </div>
            </td>
            <td className="width-last"></td>
          </tr>
          <tr>
            <th>DOB:</th>
            <td className="width-middle">
              <Input
                id="dOB"
                type="number"
                placeholder="DOB"
                name="dOB"
                value={props.coinFormDetails.dOB}
                onChange={(e) => {
                  const formattedDOB = moment(e.target.value, 'MMDDYYYY');
                  if (formattedDOB.isValid() && e.target.value.length === 8) {
                    const yearOfBirth = moment().diff(formattedDOB, 'years');
                    //check less than 18
                    props.blocked((yearOfBirth < 18 && yearOfBirth > 0));
                    setFormBlocked((yearOfBirth < 18 && yearOfBirth > 0));
                    props.censored((yearOfBirth < 18 && yearOfBirth > 0))
                  } else {
                    props.blocked(false);
                    setFormBlocked(false);
                    props.censored(false)
                  }
                  //
                  props.saveCoinFormDetails({
                    name: e.target.name,
                    value: e.target.value,
                  })
                }}
                autoComplete="off"
              />
            </td>
            <td className="width-last">
              <span>Example: MMDDYYYY 12311980.</span>
            </td>
          </tr>
          <tr className="back-grey">
            <th>Height:</th>
            <td className="width-middle">
              <Input
                id="height"
                type="number"
                placeholder="Height"
                name="height"
                onInput={(e) => {
                  e.target.value = Math.max(0, parseInt(e.target.value))
                    .toString()
                    .slice(0, 3);
                }}
                value={props.coinFormDetails.height}
                onChange={(e) =>
                  props.saveCoinFormDetails({
                    name: e.target.name,
                    value: e.target.value,
                  })
                }
                autoComplete="off"
              />
            </td>
            <td className="width-last">
              <span>Example: If someone is 6'3" put 603.</span>
            </td>
          </tr>
          <tr>
            <th>Weight:</th>
            <td className="width-middle">
              <Input
                id="weight"
                type="number"
                placeholder="Weight"
                name="weight"
                value={props.coinFormDetails.weight}
                onInput={(e) => {
                  e.target.value = Math.max(0, parseInt(e.target.value))
                    .toString()
                    .slice(0, 3);
                }}
                onChange={(e) =>
                  props.saveCoinFormDetails({
                    name: e.target.name,
                    value: e.target.value,
                  })
                }
                autoComplete="off"
              />
            </td>
            <td className="width-last"></td>
          </tr>
          <tr className="back-grey">
            <th>Eyes:</th>
            <td className="width-middle">
              <div className="d-flex align-items-end">
                <DropdownList
                  id="eyes"
                  data={eyesOptions}
                  containerClassName="payment-drop"
                  textField="label"
                  value={props.coinFormDetails.eyes}
                  onChange={(e) =>
                    props.saveCoinFormDetails({ name: "eyes", value: e })
                  }
                />
              </div>
            </td>
            <td className="width-last"></td>
          </tr>
          <tr>
            <th>Hair:</th>
            <td className="width-middle">
              <div className="d-flex align-items-end">
                <DropdownList
                  id="hair"
                  data={hairOptions}
                  containerClassName="payment-drop"
                  textField="label"
                  value={props.coinFormDetails.hair}
                  onChange={(e) =>
                    props.saveCoinFormDetails({ name: "hair", value: e })
                  }
                />
              </div>
            </td>
            <td className="width-last"></td>
          </tr>
          <tr className="back-red ">
            <th>Race:</th>
            <td className="width-middle">
              <div className="d-flex align-items-end">
                <DropdownList
                  id="race"
                  data={raceOptions}
                  containerClassName="race-drop"
                  textField="label"
                  valueField="value"
                  value={props.coinFormDetails.race}
                  onChange={(e) =>
                    props.saveCoinFormDetails({ name: "race", value: e })
                  }
                />
              </div>
            </td>
            <td className="width-last"></td>
          </tr>
          <tr>
            <th>DL State:</th>
            <td className="width-middle">
              <Input
                id="dLState"
                type="text"
                placeholder="DL State"
                name="dLState"
                value={props.coinFormDetails.dLState}
                onInput={(e) => {
                  e.target.value = e.target.value.slice(0, 2);
                }}
                onChange={(e) => {
                  props.saveCoinFormDetails({
                    name: e.target.name,
                    value: e.target.value,
                  });
                }}
                autoComplete="off"
              />
            </td>
            <td className="width-last"></td>
          </tr>
          <tr className="back-grey">
            <th>DL Number:</th>
            <td className="width-middle">
              <Input
                id="dLNo"
                type="text"
                placeholder="DL Number"
                name="dLNo"
                value={props.coinFormDetails.dLNo}
                onChange={changeDlAndOtherNumberHandler}
                autoComplete="off"
              />
            </td>
            <td className="width-last"></td>
          </tr>
          <tr>
            <th>Other ID Type:</th>
            <td className="width-middle">
              <Input
                id="otherIDType"
                type="text"
                placeholder="Other ID Type"
                name="otherIDType"
                value={props.coinFormDetails.otherIDType}
                onChange={(e) =>
                  props.saveCoinFormDetails({
                    name: e.target.name,
                    value: e.target.value,
                  })
                }
                autoComplete="off"
              />
            </td>
            <td className="width-last"></td>
          </tr>
          <tr className="back-grey">
            <th>Other ID Number:</th>
            <td className="width-middle">
              <Input
                id="otherIDNo"
                type="text"
                placeholder="Other ID Number"
                name="otherIDNo"
                value={props.coinFormDetails.otherIDNo}
                onChange={changeDlAndOtherNumberHandler}
                autoComplete="off"
              />
            </td>
            <td className="width-last"></td>
          </tr>
          <tr className="back-red">
            <th>From:</th>
            <td className="width-middle">
              <div className="d-flex align-items-end">
                <DropdownList
                  id="fromx"
                  data={fromOptions}
                  containerClassName="from-drop"
                  textField="name"
                  onToggle={getFromOptions}
                  value={props.coinFormDetails.fromx}
                  onChange={(e) =>
                    props.saveCoinFormDetails({ name: "fromx", value: e })
                  }
                />
              </div>
            </td>
            <td className="width-last"></td>
          </tr>
          <tr className="back-red ">
            <th>Booth:</th>
            <td className="width-middle">
              <div className="d-flex align-items-end">
                <DropdownList
                  id="booth"
                  data={boothOptions}
                  containerClassName="booth-drop"
                  textField="name"
                  value={props.coinFormDetails.booth}
                  onChange={(e) => {
                    props.saveCoinFormDetails({ name: "booth", value: e });
                  }}
                  onToggle={fetchAllBooth}
                />
              </div>
            </td>
            <td className="width-last"></td>
          </tr>
          {props.userType && !["employee", "employees", "employe"].includes(
           props.userType.toLowerCase()
          ) && (
            <tr>
              <th>Station:</th>
              <td className="width-middle">
                <div className="d-flex align-items-end">
                  <DropdownList
                    id="station"
                    data={stationOptions}
                    containerClassName="station-drop"
                    textField="label"
                    value={props.coinFormDetails.station}
                    onChange={(e) => {
                      props.saveCoinFormDetails({ name: "station", value: e });
                    }}
                  />
                </div>
              </td>
              <td className="width-last"></td>
            </tr>
          )}
          <tr>
            <th>Keyword:</th>
            <td className="width-middle autocomplete">
              {/* <Autocomplete
                suggestions={keywords}
                value={props.coinFormDetails.keyword}
                getKeywords={(e) => {
                  getKeywords();
                }}
                changeValues={(e) =>
                  props.saveCoinFormDetails({
                    name: "keyword",
                    value: e,
                  })
                }
              /> */}
              <Input
                id="keyword"
                type="text"
                placeholder="Keyword"
                name="keyword"
                value={props.coinFormDetails.keyword}
                onChange={(e) =>
                  props.saveCoinFormDetails({
                    name: e.target.name,
                    value: e.target.value,
                  })
                }
                autoComplete="off"
              />
            </td>
            <td className="width-last"></td>
          </tr>
          <tr>
            <th>Coupon:</th>
            <td className="width-middle">
              <Input
                id="coupon"
                type="text"
                placeholder="Coupon"
                name="coupon"
                value={props.coinFormDetails.coupon}
                onChange={(e) =>
                  props.saveCoinFormDetails({
                    name: e.target.name,
                    value: e.target.value,
                  })
                }
                autoComplete="off"
              />
            </td>
            <td className="width-last"></td>
          </tr>
          <tr className="back-red booth-drop">
            <th>Clerk Name:</th>
            <td className="width-middle">
              <div className="d-flex align-items-end">
                <DropdownList
                  id="clerk"
                  data={props.clerks.allClerks}
                  containerClassName="booth-drop"
                  textField="first_name"
                  onToggle={fetchAllClerks}
                  value={props.coinFormDetails.clerk}
                  onChange={(e) =>
                    props.saveCoinFormDetails({ name: "clerk", value: e })
                  }
                />
              </div>
            </td>
            <td className="width-last"></td>
          </tr>
          <tr>
            <th>
              <div class="">Property Description:</div>
              <div class="">(Taking from Customer)</div>
            </th>
            <td className="width-middle px-2" colSpan="2">
              <h5 className="mb-1 mt-2">Add items</h5>
              <Table className="desc-table " borderless>
                <thead>
                  <tr>
                    <td style={{ width: "85px" }}>QTY</td>
                    <td style={{ width: "35%" }}>Desc</td>
                    <td>Unit</td>
                    <td>Total</td>
                    <td></td>
                  </tr>
                </thead>
                <tbody>{renderAllItemsHTML()}</tbody>
              </Table>
            </td>
          </tr>
          { props.coinFormDetails.payment.value == 'barter' && (
            <>
              <tr>
                <th>
                  <div class="">Barter Description:</div>
                  <div class="">(Giving to Customer)</div>
                </th>
                <td className="width-middle px-2" colSpan="2">
                  <h5 className="mb-1 mt-2">Add items</h5>
                  <Table className="desc-table " borderless>
                    <thead>
                      <tr>
                        <td style={{ width: "85px" }}>QTY</td>
                        <td style={{ width: "35%" }}>Desc</td>
                        <td>Unit</td>
                        <td>Total</td>
                        <td></td>
                      </tr>
                    </thead>
                    <tbody>
                      {renderAllBarterItemsHTML()}
                    </tbody>
                  </Table>
                </td>
              </tr>
            </>
          )}
          <tr>
            <th>Print labels:</th>
            <td className="width-middle copies d-flex align-items-center">
              <Label className="mb-0 mr-1">Copies:</Label>
              <Input
                id="copies"
                type="number"
                placeholder="Copies"
                value={props.coinFormDetails.copies}
                onChange={(e) => {
                  props.saveCoinFormDetails({
                    name: "copies",
                    value: e.target.value,
                  });
                }}
              />
            </td>
            <td className="width-last"></td>
          </tr>
          <tr>
            <th>Attachments:</th>
            <td className="width-middle">
              <input type="file" name="files[]" id="fileuploader"/>
            </td>
            <td className="width-last"></td>
          </tr>
        </tbody>
      </Table>
    </Form>
  );
}

export default AddMetroTicketForm;
