import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { GetApiActionWithAuthorization } from "constantFunctions/apiActions";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Table
} from "reactstrap";
import ReactPaginate from "react-paginate";

function ViewMetalItemsModal(props) {
  const [unauthorized, setUnauthorized] = useState(false);
  const [items, setItems] = useState([]);
  const [totalItems, setTotalItems] = useState([]);
  const [page, setPage] = useState();
  const [pageSize, setPageSize] = useState(20);
  useEffect(() => {
    console.log(props)
    let params = Object.assign(props.filter, {
      metal: props.metal.name,
      page,
      pageSize,
      buyer: props.metal.buyer
    });
    //set weight not sold
    if (typeof props.metal.weightNotSold !== "undefined") {
      params.soldTo = props.metal.weightNotSold;
    }
    loadItems(params)
  }, []);
  const loadItems = (params) => {
    GetApiActionWithAuthorization("metals/get-all-metal-items-data", params)
      .then((res) => {
        setItems(res.data)
        setTotalItems(res.totalItems)
      })
      .catch((err) => {
        if (err.status === 403) {
          setUnauthorized(true);
        } else if (err.status === 440) {
          props.resetState();
          toast.error(err.message, { position: toast.POSITION.TOP_CENTER });
          props.history.push("/auth/login");
        }
      });
  }
  const renderTableData = (e) => {
    return items.map((item, i) => {
        return (<tr key={i}>
            <td>
                <a
                    href={'/portal/metro-tickets/view-items/' + item.ticketId + '?itemId=' + item.id}
                    target="_blank">{item.id}</a>
            </td>
            <td>{item.itemType}</td>
            <td>{item.description}</td>
        </tr>)
    })
  } 
  return (
    <Modal size="lg" isOpen={props.show} toggle={props.toggle}>
      <ModalHeader toggle={props.toggle}>View Items</ModalHeader>
      <ModalBody className="py-1">
        <Table className="" bordered responsive="sm">
            <thead>
            <tr>
                <th style={{ width: "80px" }}> Item ID</th>
                <th style={{ width: "60px" }}> Type</th>
                <th style={{ minWidth: "300px" }}>Item Description</th>
            </tr>
            </thead>
            <tbody>
              {renderTableData()}
            </tbody>
        </Table>
        {items.length > 0 && (
                <ReactPaginate
                  previousLabel={<i className="fas fa-chevron-left"></i>}
                  nextLabel={<i className="fas fa-chevron-right"></i>}
                  breakLabel={".."}
                  breakClassName={"break-me"}
                  initialPage={page}
                  pageCount={totalItems / pageSize}
                  marginPagesDisplayed={1}
                  pageRangeDisplayed={2}
                  onPageChange={(e) => {
                    //run only when page changes
                    if (page !== e.selected) {
                      //setChangeInterval(!changeInterval);
                      loadItems({
                        metal: props.metal.name,
                        page: e.selected,
                        pageSize
                      })
                      setPage(e.selected)
                    }
                  }}
                  containerClassName={"pagination"}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"active"}
                />
              )}
      </ModalBody>
      <ModalFooter className="pt-0">
          <Button id="close" color="secondary" onClick={(e) => props.toggle()}>
            Close
          </Button>
        </ModalFooter>
    </Modal>
  );
}
  
export default ViewMetalItemsModal;
