import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { resetState } from "../../redux/common/commonAction";
import Header from "components/Headers/Header";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Table,
} from "reactstrap";
import Unauthorized from "views/Pages/Unauthorized";
import { useLocation, Switch, Link } from "react-router-dom";
import moment from "moment";
import {
    PostApiWithAuthorizationAction,
    GetApiActionWithAuthorization
} from "../../constantFunctions/apiActions";
import Collapsible from 'react-collapsible';
import {
    DropdownList,
    Multiselect
} from "react-widgets";
import {
    monthFullOptions
} from "../../constants/ticketConstants";

function DayStat(props) {
    const [unauthorized, setUnauthorized] = useState(false);
    const [years, setYears] = useState([]);
    const [filterSystemOptions, setFilterSystemOptions] = useState([
        'Metro',
        'Coin',
        'Coin Receipt',
        'Jewelry Receipt'
    ]);
    //const [selectedYear, setSelectedYear] = useState(moment().format('YYYY'));
    //const [selectedMonth, setSelectedMonth] = useState(moment().format('MM'));
    //const [selectedDay, setSelectedDay] = useState(moment().format('DD'));
    const [selectedYear, setSelectedYear] = useState();
    const [selectedMonth, setSelectedMonth] = useState();
    const [selectedDay, setSelectedDay] = useState();
    const [selectedFilterSystemOption, setSelectedFilterSystemOption] = useState(["Metro"]);
    const [months, setMonths] = useState([]);
    const [days, setDays] = useState([]);
    const [data, setData] = useState({});
    const [timestampRange, setTimestampRange] = useState({
        day: { start: 0, end: 0 },
        week: { start: 0, end: 0 },
        month: { start: 0, end: 0 }
    });

    useEffect(() => {
        loadFilterDate().then(res => {
            setYears(res.data);
            return loadFilterDate("month");
        }).then(res => {
            setMonths(res.data);
        }).then(res => {
            return loadFilterDate("day", selectedMonth);
        }).then(res => {
            setDays(res.data)
        });
    }, []);

    const loadFilterDate = (query = "year", filterMonth = "") => {
        return GetApiActionWithAuthorization("common/get-filter-date-params?query=" + query + "&filterMonth=" + filterMonth);
    };

    const reloadDay = (month) => {
        setDays([])
        loadFilterDate("day", month).then(res => {
            setDays(res.data)
        });
    };

    const reloadMonth = () => {
        setMonths([])
        loadFilterDate("month").then(res => {
            setMonths(res.data)
        });
    };

    const clearSelection = () => {
        setSelectedMonth();
        setSelectedDay();
    };

    const loadData = (date) => {
        GetApiActionWithAuthorization("common/get-filter-date-data?years=" + years.join(",") + "&filterSystems=" + selectedFilterSystemOption.join(',') + "&date=" + date).then(res => {
            setData(res.data);
            setTimestampRange(res.timestampRange)
        });
    };

    const render = () => {
        let line;
        return Object.keys(data).map(function(filterSystem) {
            let dates = data[filterSystem];
            line = 0;
            return (
                <>
            <Collapsible
                trigger={filterSystem}
                open={true}>
                <Table
                    className="check-table table-listing"
                    responsive
                    bordered>
                <thead className="thead-light">
                    <tr>
                        <th scope="col" style={{width: "32%"}}>Day
                            <strong style={{color: '#08c', float: 'right'}}>{timestampRange.day.start}</strong>
                        </th>
                        <th scope="col" style={{width: "32%"}}>Week
                            {/*<strong style={{color: '#08c', float: 'right'}}>{timestampRange.week.start} - {timestampRange.week.end}</strong>*/}
                        </th>
                        <th scope="col" style={{width: "32%"}}>Month
                            {/*<strong style={{color: '#08c', float: 'right'}}>{timestampRange.month.start} - {timestampRange.month.end}</strong>*/}
                        </th>
                    </tr>
                </thead>
                <tbody>
                <tr className="date-column-last">
                    <td
                        colSpan="3"
                        style={{borderBottomColor: 'white', padding: 0}}
                        className="col-tm-content">
                    <Row className="mx-0" style={{marginRight: '-1px !important'}}>
                    {Object.keys(dates).map(function(key) {
                        let years = dates[key];
                        line+=1;
                        return (
                            <>
                                <Col md="4" className="px-0">
                                <Table
                                    className="check-table table-listing"
                                    responsive
                                    bordered>
                                    <thead className="thead-light">
                                        <tr>
                                            <th scope="col">Year</th>
                                            <th scope="col">#</th>
                                            <th scope="col">Amount</th>
                                            {!filterSystem.includes('Receipt') && (
                                                <th scope="col">Profit</th>
                                            )}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {Object.keys(years).filter(year => {
                                            return years[year][0].totalCount > 0;
                                        }).map(function(year) {
                                            return (
                                                <tr style={{background: '#f5f6f7'}}>
                                                    <td>
                                                        <strong>{year}</strong>
                                                    </td>
                                                    <td>{years[year][0].totalCount}</td>
                                                    <td>${years[year][0].amount}</td>
                                                    {!filterSystem.includes('Receipt') && (
                                                        <td>${years[year][0].profit}</td>
                                                    )}
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </Table></Col>
                            </>
                        );
                    })}
                </Row></td></tr></tbody></Table></Collapsible></>
            );
        });
    };

    return unauthorized ? (
        <Unauthorized />
    ) : (
        <>
          <Header />
          <Container className="mt--9 main-content date-stat-page" fluid>
            <Card className="card-height shadow">
              <CardHeader className="py-3">
                <Row>
                  <Col sm={{ offset: "5" }} className="text-center">
                    <h1>Date</h1>
                  </Col>
                  <Col className="text-right">
                    <Button
                        type="button"
                        className="py-2 ml-2"
                        color="primary"
                        onClick={() => {
                            props.history.goBack();
                        }}>
                    <i className="fas fa-chevron-left"></i> Back
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody className="page-main-content">
                <Row className="mb-2">
                    <Col md="3">
                        Choose Date:
                        <Row className="mb-2">
                            <Col md="6" className="mr-0 pr-0">
                                <DropdownList
                                    data={monthFullOptions}
                                    value={selectedMonth}
                                    placeholder="Month"
                                    valueField="value"
                                    textField="label"
                                    onChange={(e) => {
                                        setSelectedMonth(e.value)
                                        reloadDay(e.value);
                                    }}
                                />
                            </Col>
                            <Col md="6">
                                <DropdownList
                                    data={days}
                                    value={selectedDay}
                                    placeholder="Day"
                                    onChange={(e) => {
                                        setSelectedDay(e);
                                    }}
                                />
                            </Col>
                        </Row>
                    </Col>
                    <Col md="5">
                        Filter System:
                        <Multiselect
                            data={filterSystemOptions}
                            defaultValue={selectedFilterSystemOption}
                            placeholder="Filter System"
                            onChange={(e) => setSelectedFilterSystemOption(e)}
                        />
                    </Col>
                    <Col md="2" className="pt-3">
                        <Button
                            color="primary"
                            disabled={!selectedMonth || !selectedDay}
                            onClick={(e) => {
                                let date = new Date().getFullYear() + "-" + selectedMonth + "-" + selectedDay;
                                loadData(date);
                            }}>Submit</Button>
                    </Col>
                </Row>
                {(Object.keys(data).length > 0 && data.constructor === Object) && (
                    <>{render()}</>
                )}
              </CardBody>
            </Card>
            </Container>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
      userType: state.loginUserReducer.userType
    };
};
  
const mapDispatchToProps = (dispatch) => {
    return {
      resetState: () => dispatch(resetState()),
    };
};
  
export default connect(mapStateToProps, mapDispatchToProps)(DayStat);