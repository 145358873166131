import Header from "components/Headers/Header";
import React, { useEffect, useState } from "react";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    Form,
    FormGroup,
    Input,
    Label,
    Row,
    Table,
} from "reactstrap";
import { toast } from "react-toastify";
import Unauthorized from "views/Pages/Unauthorized";
import Moment from "react-moment";
import moment from "moment";
import { GetApiActionWithAuthorization, PostApiWithAuthorizationAction } from "../../constantFunctions/apiActions";
import ReactPaginate from "react-paginate";
import { monthOptions, dayOptions, paidOptions } from "../../constants/ticketConstants";
import { DropdownList } from "react-widgets";
import { resetState } from "../../redux/common/commonAction";
import { connect } from "react-redux";
//loading spinner
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import qs from "query-string";

function Locks(props) {
    let defaultTab = qs.parse(window.location.search, { ignoreQueryPrefix: true }).tab || "metro";
    let _moment = moment().tz('US/Pacific');
    let startTimestamp = _moment.subtract(2, 'months');
    const [unauthorized, setUnauthorized] = useState(false);
    const [years, setYears] = useState([]);
    const [paidTicketAll, setPaidTicketAll] = useState(false);
    const [paidCoinTicketAll, setPaidCoinTicketAll] = useState(false);
    const [showPaidLoading, setShowPaidLoading] = useState(false);
    const [showTicketMakeInvoiceButton, setShowTicketMakeInvoiceButton] = useState(false);
    const [showCoinTicketMakeInvoiceButton, setShowCoinTicketMakeInvoiceButton] = useState(false);
    const [selectedTab, setSelectedTab] = useState(defaultTab == "metro" ? 0 : 1);
    const [locks, setLocks] = useState({
        data: [],
        coinData: [],
        count: 0,
        totalTicketAmount: 0,
        totalCoinTicketAmount: 0,
        ticketClerks: [],
        coinClerks: [],
        ticketSoldPersons: []
    });
    const [filter, setFilter] = useState({
        page: 0,
        clerk: '',
        soldTo: 'Ron',
        paidInFull: 0,
        startMonth: monthOptions.find(m=>m.value==(startTimestamp.format('M'))).value,
        startDay: startTimestamp.endOf('months').format('D'),
        startYear: startTimestamp.format('YYYY'),
        endMonth: monthOptions.find(m=>m.value==moment().tz('US/Pacific').format('M')).value,
        endDay: moment().tz('US/Pacific').endOf('months').format('D'),
        endYear: moment().tz('US/Pacific').format('YYYY')
    });

    useEffect(() => {
        load();
        //
        GetApiActionWithAuthorization("invoices/get-all-years").then((res) => {
            setYears(res.data)
        });
    }, []);

    if (locks.error && !unauthorized) {
        if (locks.error.status === 403) {
          setUnauthorized(true);
        } else if (locks.error.status === 440) {
          toast.error(locks.error.message, {
            position: toast.POSITION.TOP_CENTER,
          });
          props.history.push("/auth/login");
        }
    }

    const resetFilter = () => {
        setFilter({
            ... filter,
            page: 0,
            clerk: '',
            soldTo: '',
            paidInFull: null
        })
    };

  const load = (params) => {
    params = Object.assign(filter, params);
    GetApiActionWithAuthorization("locks/get-all", filter)
    .then((res) => {
        setLocks(res)
    })
    .catch((err) => {
      if (err.status === 403) {
        setUnauthorized(true);
      } else if (err.status === 440) {
        props.resetState();
        toast.error(err.message, { position: toast.POSITION.TOP_CENTER });
        props.history.push("/auth/login");
      }
    });
  };

  //checkBox paid all
  const checkboxAllHandler = (e) => {
    let paidInFull = document.getElementById("isPaidTicketAll").checked ? 1 : 0;
    //
    for(const _lock of locks.data) {
        _lock.paidInFull = paidInFull==1 ? true : false;
    }
    setLocks({...locks, data: [...locks.data]});
    setPaidTicketAll(paidInFull==1 ? true : false);
    setShowTicketMakeInvoiceButton(paidInFull==1 ? true : false);
  };

  const checkboxCoinTicketAllHandler = (e) => {
    let paidInFull = document.getElementById("isPaidCoinTicketAll").checked ? 1 : 0;
    //
    for(const _lock of locks.coinData) {
        _lock.paidInFull = paidInFull==1 ? true : false;
    }
    setLocks({...locks, coinData: [...locks.coinData]});
    setPaidCoinTicketAll(paidInFull==1 ? true : false);
    setShowCoinTicketMakeInvoiceButton(paidInFull==1 ? true : false);
  };

  //CheckBox change handler
   const checkboxTicketHandler = (e, id, index) => {
    let body = {
      id: id,
      paidInFull: document.getElementById("isPaid-ticket-" + id).checked ? 1 : 0
    };
      let _locks = locks.data
      _locks[index].paidInFull = body.paidInFull==1 ? true : false
      setLocks({...locks, data: [..._locks]});
      if (body.paidInFull == 1) {
        setShowTicketMakeInvoiceButton(true);
      } else {
        let checked = locks.data.filter(l=>l.paidInFull==true).length;
        setShowTicketMakeInvoiceButton((!checked) ? false : true);
      }
    };

    const checkboxCoinTicketHandler = (e, id, index) => {
        let body = {
          id: id,
          paidInFull: document.getElementById("isPaid-coin-ticket-" + id).checked ? 1 : 0
        };
          let _locks = locks.coinData
          _locks[index].paidInFull = body.paidInFull==1 ? true : false
          setLocks({...locks, coinData: [..._locks]});
          if (body.paidInFull == 1) {
            setShowCoinTicketMakeInvoiceButton(true);
          } else {
            let checked = locks.coinData.filter(l=>l.paidInFull==true).length;
            setShowCoinTicketMakeInvoiceButton((!checked) ? false : true);
          }
    };

    const makeInvoiceFromChecked = (type) => {
        let route = (type==1) ? "metro-tickets" : "coin-tickets";
        let redirectRoute = (type==1) ? "/portal/invoices" : "/portal/coin-invoices";
        let lockData = (type==1) ? locks.data : locks.coinData;
        let checkNumber = prompt("What is the check number ?");
        if (checkNumber !== null) {
            let data = '';
            let billedTo = filter.soldTo;
            let total = 0;
            let ids = [];
            for(const item of lockData) {
                let amount = item.soldToAmount ? item.soldToAmount : item.ticketAmount;
                if (item.paidInFull) {
                    data += item.id + "|" + moment(item.ticketTime*1000).format('M/D/Y HH:mm') + '|' + amount.toFixed(2) + "\n";
                    total += item.soldToAmount;
                    ids.push(item.id);
                }
            }
            //
            PostApiWithAuthorizationAction(route + "/generate-invoice-from-items", {
                total: total.toFixed(2),
                billedTo,
                data,
                checkNumber,
                ids
            }).then((res) => {
                props.history.push(redirectRoute);
            })
            .catch((err) => {
                if (err.status === 403) {
                    setUnauthorized(true);
                } else if (err.status === 440) {
                    props.resetState();
                    toast.error(err.message, { position: toast.POSITION.TOP_CENTER });
                    props.history.push("/auth/login");
                }
            });
        }
    };

    const renderLocksCoinTicketTableData = () => {
        return locks.coinData.map((_data, i) => {
            return (
                <>
                    <tr>
                        <td scope="col">C{_data.id}</td>
                        <td scope="col">
                        <Moment format="MM/DD/YY HH:mm" tz="America/Los_Angeles">
                            {_data.ticketTime.toString().length === 10
                                ? new Date(_data.ticketTime * 1000)
                                : new Date(_data.ticketTime)}
                        </Moment>
                        </td>
                        <td scope="col">{parseInt(_data.ticketAmount)}</td>
                        <td scope="col">{_data.clerk}</td>
                        <td scope="col">
                            {(!showPaidLoading) && (
                                <input
                                    id={"isPaid-coin-ticket-"+_data.id}
                                    type="checkbox"
                                    checked={_data.paidInFull}
                                    onChange={(e) => checkboxCoinTicketHandler(e, _data.id, i)}
                                    />
                            )}
                            <Loader visible={showPaidLoading} type="ThreeDots" color="#5e72e4" height={15} width={40} timeout={0}/>
                        </td>
                        <td scope="col">{_data.comment}</td>
                    </tr>
                </>
            )
        });
    };

    const renderLocksTicketTableData = () => {
        return locks.data.map((_data, i) => {
            return (
                <>
                    <tr>
                        <td scope="col">M{_data.id}</td>
                        <td scope="col">
                        <Moment format="MM/DD/YY HH:mm" tz="America/Los_Angeles">
                            {_data.ticketTime.toString().length === 10
                                ? new Date(_data.ticketTime * 1000)
                                : new Date(_data.ticketTime)}
                        </Moment>
                        </td>
                        <td scope="col">{parseInt(_data.ticketAmount)}</td>
                        <td scope="col">{_data.clerk}</td>
                        <td scope="col">{_data.soldTo}</td>
                        <td scope="col">{parseInt(_data.soldToAmount)}</td>
                        <td scope="col">
                            {(!showPaidLoading) && (
                                <input
                                    id={"isPaid-ticket-"+_data.id}
                                    type="checkbox"
                                    checked={_data.paidInFull}
                                    onChange={(e) => checkboxTicketHandler(e, _data.id, i)}
                                    />
                            )}
                            <Loader visible={showPaidLoading} type="ThreeDots" color="#5e72e4" height={15} width={40} timeout={0}/>
                        </td>
                        <td scope="col">{_data.comment}</td>
                    </tr>
                </>
            )
        });
    };

    //
    return unauthorized ? (
        <Unauthorized />
      ) : (
        <>
          <Header />
          {/* Page content */}
          <Container className="mt--9 main-content" fluid>
            <Card className="card-height shadow">
              <CardHeader>
                <div className="text-center">
                    <h1>Locks</h1>
                </div>
              </CardHeader>
              <CardBody className="checks-main-content page-main-content">
                <Row>
                    <Col md={'9'}>
                        <Row>
                        <Col md="6" className="d-flex d-flex-full mt-1">
                            <span class="mx-1" style={{fontSize: "22px"}}>Start: </span>
                            <DropdownList
                                data={monthOptions}
                                value={filter.startMonth}
                                placeholder="Month"
                                textField="label"
                                valueField='value'
                                onChange={(e) => {
                                    setFilter({
                                        ... filter,
                                        startMonth: e.value
                                    })
                                }}
                            />
                        <div class="mx-1" style={{fontSize: "24px"}}> / </div>
                        <DropdownList
                            data={dayOptions}
                            value={filter.startDay}
                            placeholder="Day"
                            onChange={(e) => {
                            setFilter({
                                ... filter,
                                startDay: e
                            })
                            }}
                        />
                        <span class="mx-1" style={{fontSize: "24px"}}> / </span>
                        <DropdownList
                            data={years}
                            value={filter.startYear}
                            placeholder="Year"
                            onChange={(e) => {
                            setFilter({
                                ... filter,
                                startYear: e
                            })
                            }}
                        />
                        </Col>
                        <Col md="6" className="d-flex d-flex-full pl-0 mt-1">
                            <span class="mx-1" style={{fontSize: "22px"}}>End: </span>
                            <DropdownList
                            data={monthOptions}
                            value={filter.endMonth}
                            placeholder="Month"
                            textField="label"
                            valueField='value'
                            onChange={(e) => {
                                setFilter({
                                ... filter,
                                endMonth: e.value
                                })
                            }}
                            />
                        <div class="mx-1" style={{fontSize: "24px"}}> / </div>
                        <DropdownList
                            data={dayOptions}
                            value={filter.endDay}
                            placeholder="Day"
                            onChange={(e) => {
                            setFilter({
                                ... filter,
                                endDay: e
                            })
                            }}
                        />
                        <span class="mx-1" style={{fontSize: "24px"}}> / </span>
                        <DropdownList
                            data={years}
                            value={filter.endYear}
                            placeholder="Year"
                            onChange={(e) => {
                                setFilter({
                                    ... filter,
                                    endYear: e
                                })
                            }}
                        />
                        <Button
                            id="btn-get-worksheet"
                            size="sm"
                            className="ml-2"
                            onClick={(e) => {
                                setFilter({
                                    ... filter,
                                    page: 0
                                })
                                load({
                                    page: 0
                                });
                            }}
                            color="primary">Apply</Button>
                        </Col>
                        </Row>
                    </Col>
                </Row>
                <Tabs className="mt-2" defaultIndex={selectedTab}>
                    <TabList>
                        <Tab onClick={() => {
                            props.history.push("/portal/locks?tab=metro");
                        }}>Metro</Tab>
                        {/*<Tab onClick={() => {
                            props.history.push("/portal/locks?tab=coin");
                        }}>Coin</Tab>*/}
                    </TabList>
                    <TabPanel>
                        <Row>
                            <Col md="4" className="my-1 mb-2">
                            <div className="d-flex d-flex-full">
                                {(showTicketMakeInvoiceButton) && (filter.paidInFull.value==0 || !filter.paidInFull) && (
                                    <Button
                                        id="print_check"
                                        color="info"
                                        size="sm"
                                        className="custom-btn-info"
                                        onClick={() => {
                                            makeInvoiceFromChecked(1);
                                        }}>Make Invoice</Button>
                                )}
                                <span className="pt-1" style={{fontSize: '16px'}}>Filter:&nbsp;</span>
                                <DropdownList
                                    data={locks.ticketSoldPersons}
                                    value={filter.soldTo}
                                    placeholder="Sold To"
                                    className="mr-1"
                                    onChange={(e) => {
                                        setFilter({
                                            ... filter,
                                            soldTo: e,
                                            clerk: '',
                                            page: 0
                                        })
                                        //
                                        load({
                                            soldTo: e,
                                            clerk: '',
                                            page: 0
                                        });
                                    }}
                                />
                                <DropdownList
                                    data={paidOptions}
                                    value={filter.paidInFull}
                                    valueField='value'
                                    textField="label"
                                    placeholder=""
                                    onChange={(e) => {
                                        setShowTicketMakeInvoiceButton(false)
                                        setFilter({
                                            ... filter,
                                            paidInFull: e.value,
                                            page: 0
                                        })
                                        //
                                        load({
                                            paidInFull: e.value,
                                            page: 0
                                        });
                                    }}
                                />
                            </div>
                        </Col>
                        <Col md="8" className="mt-1 text-right">
                            <div className="text--red font-weight-900" style={{color: 'red', fontSize: '16px'}}>
                                Total: ${locks.totalTicketAmount}
                            </div>
                        </Col>
                        </Row>
                        <Table
                            className="ticket-table table-listing locks-table"
                            responsive
                            bordered>
                            <thead className="thead-light">
                                <tr>
                                    <th scope="col">Ticket</th>
                                    <th scope="col">Date</th>
                                    <th scope="col">Amount</th>
                                    <th scope="col">Clerk</th>
                                    <th scope="col">Sold To</th>
                                    <th scope="col">Sold To Amount</th>
                                    <th scope="col">
                                        <div>
                                        <input
                                            id={"isPaidTicketAll"}
                                            type="checkbox"
                                            checked={paidTicketAll}
                                            className="db-sized-checkbox"
                                            onChange={(e) => {
                                                checkboxAllHandler(e);
                                            }}
                                        />
                                        </div>
                                        Paid
                                    </th>
                                    <th scope="col">Comment</th>
                                </tr>
                            </thead>
                            <tbody>{renderLocksTicketTableData()}</tbody>
                        </Table>
                    </TabPanel>
                    <TabPanel>
                        <Row>            
                            <Col md={((showCoinTicketMakeInvoiceButton) && (filter.paidInFull.value==0 || !filter.paidInFull) ? '3' : '2')}>
                                <div className="d-flex d-flex-full">
                                {(showCoinTicketMakeInvoiceButton) && (filter.paidInFull.value==0 || !filter.paidInFull) && (
                                    <Button
                                        id="print_check"
                                        color="info"
                                        size="sm"
                                        className="custom-btn-info"
                                        onClick={() => {
                                            makeInvoiceFromChecked(2);
                                        }}>Make Invoice</Button>
                                )}
                                    <span className="pt-1" style={{fontSize: '16px'}}>Filter:&nbsp;</span>
                                    <DropdownList
                                        data={paidOptions}
                                        value={filter.paidInFull}
                                        valueField='value'
                                        textField="label"
                                        placeholder=""
                                        onChange={(e) => {
                                            setShowCoinTicketMakeInvoiceButton(false)
                                            setFilter({
                                                ... filter,
                                                paidInFull: e.value,
                                                page: 0
                                            })
                                            //
                                            load({
                                                paidInFull: e.value,
                                                page: 0
                                            });
                                        }}
                                    />
                                </div>
                            </Col>
                            <Col md={((showCoinTicketMakeInvoiceButton) && (filter.paidInFull.value==0 || !filter.paidInFull) ? '9' : '10')}
                                className="mt-1 mb-3 text-right">
                                <div className="text--red font-weight-900" style={{color: 'red', fontSize: '16px'}}>
                                    Total: ${locks.totalCoinTicketAmount}
                                </div>
                            </Col>  
                        </Row>
                        <Table
                            className="ticket-table table-listing locks-table"
                            responsive
                            bordered>
                            <thead className="thead-light">
                                <tr>
                                <th scope="col">Ticket</th>
                                <th scope="col">Date</th>
                                <th scope="col">Amount</th>
                                <th scope="col">Clerk</th>
                                <th scope="col">
                                    <div>
                                    <input
                                        id={"isPaidCoinTicketAll"}
                                        type="checkbox"
                                        checked={paidCoinTicketAll}
                                        className="db-sized-checkbox"
                                        onChange={(e) => {
                                            checkboxCoinTicketAllHandler(e);
                                            //setPaidAll(e.target.checked)
                                        }}
                                        />
                                    </div>
                                    Paid
                                </th>
                                <th scope="col">Comment</th>
                                </tr>
                            </thead>
                            <tbody>{renderLocksCoinTicketTableData()}</tbody>
                        </Table>
                    </TabPanel>
                </Tabs>
              </CardBody>
            </Card>
          </Container>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        userType: state.loginUserReducer.userType
    };
  };
  
const mapDispatchToProps = (dispatch) => {
    return {
        resetState: () => dispatch(resetState())
    };
};
  
export default connect(mapStateToProps, mapDispatchToProps)(Locks);