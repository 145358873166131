import Header from "components/Headers/Header";
import React, { useEffect, useState } from "react";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    Form,
    FormGroup,
    Input,
    Label,
    Row,
    Table,
} from "reactstrap";
import { toast } from "react-toastify";
import Unauthorized from "views/Pages/Unauthorized";
import {
    GetApiActionWithAuthorization,
    PostApiWithAuthorizationAction
} from "../../constantFunctions/apiActions";
import ReactPaginate from "react-paginate";
import { resetState } from "../../redux/common/commonAction";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import ConfirmationDeleteModal from "views/Modal/ConfirmationDeleteModal";

function MetricIgnore(props) {
    const [unauthorized, setUnauthorized] = useState(false);
    const [delId, setDelId] = useState("");
    const [confirm, setConfirm] = useState(false);
    const [metricFilters, setMetricFilters] = useState({
        data: [],
        count: 0,
        pageSize: 0
    });
    const [filter, setFilter] = useState({
        page: 0
    });

    useEffect(() => {
        load();
    }, []);

    if (metricFilters.error && !unauthorized) {
        if (metricFilters.error.status === 403) {
          setUnauthorized(true);
        } else if (metricFilters.error.status === 440) {
          toast.error(metricFilters.error.message, {
            position: toast.POSITION.TOP_CENTER,
          });
          props.history.push("/auth/login");
        }
    }

    const toggleConfirm = (id = "") => {
        setDelId(id);
        setConfirm(!confirm);
    };

    const load = (params) => {
        params = Object.assign(filter, params);
        GetApiActionWithAuthorization("metrics/get-all-ignore-data", filter)
        .then((res) => {
            setMetricFilters(res)
        })
        .catch((err) => {
          if (err.status === 403) {
            setUnauthorized(true);
          } else if (err.status === 440) {
            props.resetState();
            toast.error(err.message, { position: toast.POSITION.TOP_CENTER });
            props.history.push("/auth/login");
          }
        });
    };

    const confirmed = (e) => {
        PostApiWithAuthorizationAction("metrics/metric-ignore-remove/" + delId)
          .then((res) => {
            load({
                page: 0
            });
            //toast.success(res.message, { position: toast.POSITION.TOP_CENTER });
            setConfirm(false);
          })
          .catch((err) => {
            console.log(err);
            if (err.status === 403) {
              setUnauthorized(true);
            } else if (err.status === 440) {
              props.resetState();
              toast.error(err.message, { position: toast.POSITION.TOP_CENTER });
              props.history.push("/auth/login");
            }
          });
    };

    const renderTableData = () => {
        return metricFilters.data.map((_data, i) => {
            return (
                <>
                    <tr>
                        <td scope="col">{_data.value}</td>
                        <td scope="col">{_data.type}</td>
                        <td scope="col">
                        [
                            <span
                                id={"delete-metric-filter"+i}
                                className="delete-item"
                                onClick={(e) => toggleConfirm(_data.id)}>
                            Remove
                            </span>
                        ]{" "}
                        </td>
                    </tr>
                </>
            );
        });
    }

    return unauthorized ? (
        <Unauthorized />
      ) : (
        <>
          <Header />
          {/* Page content */}
            <Container className="mt--9 main-content" fluid>
                <Card className="card-height shadow">
                    <CardHeader className="bg-white d-flex justify-content-between">
                        <h1>Keyword Ignore List</h1>
                        <Button
                        type="button"
                        color="primary"
                        onClick={() => {
                            props.history.goBack();
                        }}
                        >
                        <i className="fas fa-chevron-left"></i> Back
                        </Button>
                    </CardHeader>
                    <CardBody>
                        <Table className="ticket-table table-listing" responsive bordered>
                            <thead className="thead-light">
                                <tr>
                                    <th scope="col">Value</th>
                                    <th scope="col">Type</th>
                                    <th scope="col">Action</th>
                                </tr>
                            </thead>
                            <tbody>{renderTableData()}</tbody>
                        </Table>
                        {metricFilters.data.length > 0 && (
                            <ReactPaginate
                                previousLabel={
                                    <i className="fas fa-chevron-left text-white"></i>
                                }
                                nextLabel={
                                    <i className="fas fa-chevron-right text-white"></i>
                                }
                                breakLabel={".."}
                                breakClassName={"break-me"}
                                initialPage={filter.page}
                                forcePage={filter.page}
                                pageCount={metricFilters.count / metricFilters.pageSize}
                                marginPagesDisplayed={1}
                                pageRangeDisplayed={2}
                                onPageChange={(e) => {
                                    if (filter.page !== e.selected) {
                                        load({
                                            page: e.selected
                                        });
                                        setFilter({
                                            ... filter,
                                            page: e.selected
                                        })
                                    }
                                }}
                                containerClassName={"pagination"}
                                subContainerClassName={"pages pagination"}
                                activeClassName={"active"}
                            />
                        )}
                    </CardBody>
                </Card>
            </Container>
            {confirm && (
                <ConfirmationDeleteModal
                show={confirm}
                toggle={toggleConfirm}
                confirmed={confirmed}
                />
            )}
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        userType: state.loginUserReducer.userType
    };
};
  
const mapDispatchToProps = (dispatch) => {
    return {
        resetState: () => dispatch(resetState())
    };
};
  
export default connect(mapStateToProps, mapDispatchToProps)(MetricIgnore);