import Header from "components/Headers/Header";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { DropdownList } from "react-widgets";
import classnames from "classnames";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Container,
  Input,
  Table,
  Row,
  Label,
  Col
} from "reactstrap";
import Unauthorized from "views/Pages/Unauthorized";
import {
    quoteStatusOptions
} from "../../constants/ticketConstants";
import { resetState } from "redux/common/commonAction";
import {
    saveQuoteFormDetails,
    saveQuoteItemDetails,
    resetSaveQuoteForm
} from "../../redux/Quotes/quoteAction";
import { GetApiActionWithAuthorization } from "constantFunctions/apiActions";
import { PostApiWithAuthorizationAction } from "constantFunctions/apiActions";
import { validateQuoteForm } from "../../constantFunctions/formValidations";
import Moment from "react-moment";

function QuoteSave(props) {
    const [unauthorized, setUnauthorized] = useState(false);
    const [allClerks, setAllClerks] = useState([]);
    const [contactLogs, setContactLogs] = useState('[]');
    const [loading, setLoading] = useState(false);
    const { match: { params } } = props;

    useEffect(() => {
        props.resetSaveQuoteForm();
        if (!params.id) {
            startRowInItem();
        } else {
            GetApiActionWithAuthorization("quotes/get-single-quote-data/" + params.id).then((res) => {
                props.saveQuoteFormDetails({
                    name: "clerk",
                    value: res.data.clerk,
                });
                props.saveQuoteFormDetails({
                    name: "contactValue",
                    value: res.data.contactValue
                });
                props.saveQuoteFormDetails({
                    name: "contactMethod",
                    value: res.data.contactMethod
                });
                props.saveQuoteFormDetails({
                    name: "customerName",
                    value: res.data.customerName
                });
                if (res.items.length > 0) {
                    props.saveQuoteItemDetails(res.items);
                } else {
                    startRowInItem();
                }
                if (res.data.contactLog.length > 0) {
                    setContactLogs(res.data.contactLog);
                }
            });
        }
    }, []);

    const startRowInItem = (e) => {
        props.saveQuoteItemDetails([{
            itemDescription: "",
            price: "0.00",
            comment: ""
        }]);
    };

    const addRowInItem = (e) => {
        let obj = {
          itemDescription: "",
          price: "0.00",
          comment: ""
        };
        let olderItems = props.quoteItemsDetails.items;
        olderItems.push(obj);
        props.saveQuoteItemDetails(olderItems);
    };
    
      const removeRowInItem = (index) => {
        let olderItems = props.quoteItemsDetails.items;
        olderItems.splice(index, 1);
        props.saveQuoteItemDetails(olderItems);
      };
    
      const changeItemValues = (name, value, index) => {
        let temp = props.quoteItemsDetails.items;
        let itemObj = temp[index];
        itemObj[name] = value;
        temp[index] = itemObj;

        if (name === "price") {
            itemObj["price"] = parseFloat(value).toFixed(2);
        }
        props.saveQuoteItemDetails(temp);
      };    

    const formSubmitHandler = (e) => {
        let validation = validateQuoteForm(
            { ...props.quoteFormDetails },
            props.quoteItemsDetails.items
        );
        if (validation.success) {
            let body = {
              form: props.quoteFormDetails,
              items: props.quoteItemsDetails.items,
            };
            //
            setLoading(true);
            PostApiWithAuthorizationAction("quotes/save/" + (params.id ? params.id : ""), body)
              .then((res) => {
                setLoading(false);
                //toast.success(res.message, { position: toast.POSITION.TOP_CENTER });
                props.history.push("/portal/quotes/edit/" + res.data.id);
              })
              .catch((err) => {
                setLoading(false);
                if (err.status === 403) {
                  setUnauthorized(true);
                } else if (err.status === 440) {
                  props.resetState();
                  toast.error(err.message, { position: toast.POSITION.TOP_CENTER });
                  props.history.push("/auth/login");
                }
              });
        } else {
            toast.error(validation.err, {
              position: toast.POSITION.TOP_CENTER,
              closeOnClick: true,
            });
        }
    };

    const fetchAllClerks = (e) => {
        if (e && allClerks.length <= 0) {
          GetApiActionWithAuthorization("common/get-clerk-listing")
            .then((res) => {
              setAllClerks(res.data);
            })
            .catch((err) => {
              if (err.status === 403) {
                setUnauthorized(true);
              } else if (err.status === 440) {
                props.resetState();
                toast.error(err.message, { position: toast.POSITION.TOP_CENTER });
                props.history.push("/auth/login");
              }
            });
        }
    };

    const renderContactLog = (e) => {
        let logs = JSON.parse(contactLogs);
        return logs.map((log, i) => {
            return (
                <tr>
                    <td>
                        <Moment format="MMM Do YYYY" tz="America/Los_Angeles">
                            {log.time.toString().length === 10
                                ? log.time * 1000
                                : log.time}
                        </Moment>
                    </td>
                    <td>
                        {log.comment}
                    </td>
                </tr>
            );
        });
    };

    const renderAllItemsHTML = (e) => {
        return props.quoteItemsDetails.items.map((item, i) => {
            return (
              <tr key={i} id={"invoice-item-row" + i}>
                <td className="input-td">
                    <Input
                        id="description"
                        type="text"
                        value={item.itemDescription}
                        className={classnames({
                            "input-null": item.itemDescription === "",
                        })}
                        onChange={(e) => {
                            changeItemValues("itemDescription", e.target.value, i);
                        }}
                    />
                </td>
                <td className="input-td">
                    <Input
                        id="comment"
                        type="text"
                        value={item.itemComment}
                        className={classnames({
                            "input-null": item.itemComment === "",
                        })}
                        onChange={(e) => {
                            changeItemValues("itemComment", e.target.value, i);
                        }}
                    />
                </td>
                <td className="input-td">
                  <Input
                    id="price"
                    type="number"
                    className={classnames({ "input-null": item.price === "" })}
                    value={item.price}
                    onChange={(e) => {
                      changeItemValues("price", e.target.value, i);
                    }}
                  />
                </td>
                <td className="action-col text-center">
                  <Button
                    id="add-more"
                    className="icon-cross"
                    color="primary"
                    title="Add more"
                    onClick={() => {
                      if (item.price !== "" && item.itemDescription !== "") {
                        addRowInItem();
                      }
                    }}
                  >
                    <i className="fas fa-check-square"></i>
                  </Button>
                  {(i >= 1 || params.id && props.quoteItemsDetails.items.length > 1)&& (
                  <Button
                    id="remove"
                    className="icon-cross"
                    color="danger"
                    title="remove"
                    onClick={() => {
                      removeRowInItem(i);
                    }}
                  >
                    <i className="fas fa-times"></i>
                  </Button>)}
                </td>
              </tr>
            );
        });
    };

    return unauthorized ? (
        <Unauthorized />
      ) : (
        <>
          <Header />
          {/* Page content */}
          <Container className="mt--9 main-content" fluid>
            <Card className="card-height shadow">
              <CardHeader className="bg-white d-flex justify-content-between">
                <h1>{params.id ? 'Editing Quote:' + params.id : 'Create New Quote'}</h1>
                <Button
                  type="button"
                  color="primary"
                  onClick={() => {
                    props.history.push('/portal/quotes');
                  }}
                >
                  <i className="fas fa-chevron-left"></i> Back
                </Button>
              </CardHeader>
              <CardBody>
                  <div className="px-0">
                    <div className="pull-right">
                        <div className="text-right my-2">
                            <Button
                                id="save-receipt"
                                color="primary"
                                onClick={formSubmitHandler}
                                >Save Quote</Button>
                        </div>
                        <Table bordered striped className="quote-form-table">
                            <tbody>
                                <tr>
                                    <td>Clerk Name</td>
                                    <td className="">
                                        <DropdownList
                                            id="clerk"
                                            data={allClerks}
                                            textField="first_name"
                                            valueField="id"
                                            onToggle={fetchAllClerks}
                                            value={props.quoteFormDetails.clerk}
                                            onChange={(e) =>
                                                props.saveQuoteFormDetails({
                                                    name: "clerk",
                                                    value: e.first_name,
                                                })
                                            }
                                            />
                                    </td>
                                </tr>
                                <tr>
                                    <td>Customer Full Name</td>
                                    <td className="">
                                        <Input
                                        id="customerName"
                                        type="text"
                                        placeholder="Customer Name"
                                        autoComplete="off"
                                        name="customerName"
                                        value={props.quoteFormDetails.customerName}
                                        onChange={(e) =>
                                            props.saveQuoteFormDetails({
                                                name: "customerName",
                                                value: e.target.value,
                                            })
                                        }
                                        autoFocus={true}/>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Contact Method</td>
                                    <td>
                                        <Row>
                                            <Col md="4">
                                                <Input
                                                id="contactValue"
                                                type="text"
                                                placeholder="Phone# / Email"
                                                autoComplete="off"
                                                name="contactValue"
                                                value={props.quoteFormDetails.contactValue}
                                                onChange={(e) =>
                                                    props.saveQuoteFormDetails({
                                                        name: "contactValue",
                                                        value: e.target.value,
                                                    })
                                                }
                                                autoFocus={true}/>
                                            </Col>
                                            <Col md="6" className="pt-1 contact-method-options">
                                            <Label check className="mr-4">
                                                <Input
                                                    type="radio"
                                                    name="contactMethod"
                                                    value="text"
                                                    checked={props.quoteFormDetails.contactMethod==="text"}
                                                    onChange={(e) =>
                                                        props.saveQuoteFormDetails({
                                                            name: "contactMethod",
                                                            value: e.target.value,
                                                        })
                                                    }/> Text
                                            </Label>
                                            <Label check className="mr-4">
                                                <Input
                                                    type="radio"
                                                    name="contactMethod"
                                                    value="email"
                                                    checked={props.quoteFormDetails.contactMethod==="email"}
                                                    onChange={(e) =>
                                                        props.saveQuoteFormDetails({
                                                            name: "contactMethod",
                                                            value: e.target.value,
                                                        })
                                                    }/> Email
                                            </Label>
                                            <Label check>
                                                <Input
                                                    type="radio"
                                                    name="contactMethod"
                                                    value="phone"
                                                    checked={props.quoteFormDetails.contactMethod==="phone"}
                                                    onChange={(e) =>
                                                        props.saveQuoteFormDetails({
                                                            name: "contactMethod",
                                                            value: e.target.value,
                                                        })
                                                    }/> Phone
                                            </Label> 
                                            </Col>
                                        </Row>
                                    </td>                                    
                                </tr>
                                <tr>
                                    <td>Status</td>
                                    <td className="">
                                        <DropdownList
                                            id="status"
                                            data={quoteStatusOptions}
                                            textField="label"
                                            valueField="value"
                                            value={props.quoteFormDetails.status}
                                            onChange={(e) =>
                                                props.saveQuoteFormDetails({
                                                    name: "status",
                                                    value: e,
                                                })
                                            }
                                            />
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                    <Table className="create-invoice-table add-receipt-item-table" bordered responsive="sm">
                        <thead className="thead-light">
                            <tr>
                                <th scope="col">Description</th>
                                <th scope="col" className="comment-col">Comment</th>
                                <th scope="col" className="quoted-price-col">Quoted Price</th>
                                <th scope="col" className="quoted-price-col">Actions</th>
                            </tr>
                        </thead>
                        <tbody>{renderAllItemsHTML()}</tbody>
                        <tfoot className="tfoot-light">
                            <tr>
                                <td scope="col" colSpan="2">Total:</td>
                                <td scope="col" colSpan="2">
                                ${props.quoteItemsDetails.items
                                    .reduce((prev, curr) => {
                                        let price = !isNaN(curr.price) ? parseFloat(curr.price) : 0.0
                                        return prev + price;
                                    }, 0)
                                    .toFixed(2)}
                                </td>
                            </tr>
                        </tfoot>
                    </Table>
                    <h1 className="text-center my-1">Contact Log</h1>
                    <Table className="create-invoice-table add-receipt-item-table" bordered responsive="sm">
                        <thead className="thead-light">
                            <tr>
                                <th scope="col">Date</th>
                                <th scope="col" className="comment-col">Comment</th>
                            </tr>
                        </thead>
                        <tbody>{renderContactLog()}</tbody>
                    </Table>
                  </div>
              </CardBody>
            </Card>
          </Container>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        quoteFormDetails: state.quoteReducer.quoteFormDetails,
        quoteItemsDetails: state.quoteReducer.quoteItemsDetails,
        userType: state.loginUserReducer.userType
    };
  };
  
const mapDispatchToProps = (dispatch) => {
    return {
        saveQuoteFormDetails: (body) => dispatch(saveQuoteFormDetails(body)),
        saveQuoteItemDetails: (body) => dispatch(saveQuoteItemDetails(body)),
        resetSaveQuoteForm: () => dispatch(resetSaveQuoteForm()),
        resetState: () => dispatch(resetState())
    };
};
  
export default connect(mapStateToProps, mapDispatchToProps)(QuoteSave);