import React, { useEffect, useState } from "react";
// reactstrap components
import {
  Card,
  Container,
  CardHeader,
  CardBody,
  Table,
  Row,
  Col,
  Button,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import { connect } from "react-redux";
import Unauthorized from "views/Pages/Unauthorized";
import {
  fetchBoothListing,
  fetchBoothListingSuccess,
} from "../../redux/Booth/boothAction";
import { GetApiActionWithAuthorization, PostApiWithAuthorizationAction } from "../../constantFunctions/apiActions";
import EditBoothModal from "views/Modal/Booth/EditBoothModal";
import { toast } from "react-toastify";
import { resetState } from "../../redux/common/commonAction";
import AddNewBoothModal from "views/Modal/Booth/AddNewBoothModal";
import ConfirmationDeleteModal from "views/Modal/ConfirmationDeleteModal";
import { DropdownList } from "react-widgets";
import { displayOrderTypeOptions } from "constants/ticketConstants";

function Booth(props) {
  const [unauthorized, setUnauthorized] = useState(false);
  const [addModal, setAddModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [editData, setEditData] = useState({});
  const [confirm, setConfirm] = useState(false);
  const [delId, setDelId] = useState("");
  const [displayOrderType, setDisplayOrderType] = useState("");

  useEffect(() => {
    props.fetchBoothListing({ displayOrderType });
  }, []);

  if (props.booths.error && !unauthorized) {
    if (props.booths.error.status === 403) {
      setUnauthorized(true);
    } else if (props.booths.error.status === 440) {
      props.resetState();
      toast.error(props.booths.error.message, {
        position: toast.POSITION.TOP_CENTER,
      });
      props.history.push("/auth/login");
    }
  }

  const toggleAdd = (e) => {
    setAddModal(!addModal);
  };

  const toggleEdit = (id = "", name = "") => {
    setEditData({ id, name });
    setEditModal(!editModal);
  };
  const changeOrder = (decrease, id, order) => {
    let body = { decrease, id, order, type: "booth", displayOrderType };
    PostApiWithAuthorizationAction("common/change-order", body)
      .then((res) => {
        props.addUpdatedData(res.data);
      })
      .catch((err) => {
        if (err.status === 403) {
          setUnauthorized(true);
        } else if (err.status === 440) {
          props.resetState();
          toast.error(err.message, { position: toast.POSITION.TOP_CENTER });
          props.history.push("/auth/login");
        }
      });
  };

  const toggleConfirm = (id = "") => {
    setDelId(id);
    setConfirm(!confirm);
  };

  const confirmed = (e) => {
    GetApiActionWithAuthorization("common/delete-booth/" + delId)
      .then((res) => {
        props.addUpdatedData(res.data);
        //toast.success(res.message, { position: toast.POSITION.TOP_CENTER });
        setConfirm(false);
      })
      .catch((err) => {
        console.log(err);
        if (err.status === 403) {
          setUnauthorized(true);
        } else if (err.status === 440) {
          props.resetState();
          toast.error(err.message, { position: toast.POSITION.TOP_CENTER });
          props.history.push("/auth/login");
        }
      });
  };

  const renderTicketsTableData = (e) => {
    return props.booths.allBooths.map((booth, i) => {
      return (
        <tr key={i}>
          <td>
            [
            <span
              id={"update-booth" + i}
              className="delete-item"
              onClick={(e) => {
                toggleEdit(booth.id, booth.name);
              }}
            >
              Edit
            </span>
            ]{" "}[
            <span
              id={"delete-booth" + i}
              className="delete-item"
              onClick={(e) => toggleConfirm(booth.id)}
            >
              Delete
            </span>
            ]
          </td>
          <td>{booth.name}</td>
          <td className="display-order">
            <Button
              className="px-2 py-2"
              onClick={(e) => {
                if (i !== 0) {
                  changeOrder(true, booth.id, booth.displayOrder);
                }
              }}
            >
              <i className="fas fa-arrow-up"></i> Up
            </Button>
            <Button
              className="px-2 py-2"
              onClick={(e) => {
                if (i !== props.booths.allBooths.length - 1) {
                  changeOrder(false, booth.id, booth.displayOrder);
                }
              }}
            >
              {" "}
              <i className="fas fa-arrow-down"></i> Down
            </Button>
          </td>
        </tr>
      );
    });
  };

  return unauthorized ? (
    <Unauthorized />
  ) : (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--9 main-content" fluid>
        <Card className="card-height shadow">
          <CardHeader>
            <div className="text-center">
              <h1>Booth List</h1>
            </div>
          </CardHeader>
          <CardBody>
            <Row className="mb-3">
              <Col md="9" className="search-left-btn">
                <Button id="add-new" color="primary" onClick={toggleAdd}>
                  Add New+
                </Button>
              </Col>
              <Col md="3" className="search-left-btn">
                <DropdownList
                  data={displayOrderTypeOptions}
                  containerClassName=""
                  value={displayOrderType}
                  valueField="value"
                  textField="label"
                  placeholder="Select"
                  onChange={(e) => {
                    props.fetchBoothListing({ displayOrderType: e.value });
                    setDisplayOrderType(e.value)
                  }}
                />
              </Col>
            </Row>
            <Table className="ticket-table table-listing" responsive bordered>
              <thead className="thead-light">
                <tr>
                  <th scope="col">Action</th>
                  <th scope="col">Name</th>
                  <th scope="col">Order</th>
                </tr>
              </thead>
              <tbody>{renderTicketsTableData()}</tbody>
            </Table>
          </CardBody>
        </Card>
      </Container>
      {addModal && (
        <AddNewBoothModal {...props} show={addModal} toggle={toggleAdd} />
      )}
      {editModal && (
        <EditBoothModal
          {...props}
          show={editModal}
          editData={editData}
          toggle={toggleEdit}
        />
      )}
      {confirm && (
        <ConfirmationDeleteModal
          show={confirm}
          toggle={toggleConfirm}
          confirmed={confirmed}
        />
      )}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    booths: state.boothReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchBoothListing: (params) => dispatch(fetchBoothListing(params)),
    addUpdatedData: (data) => dispatch(fetchBoothListingSuccess(data)),
    resetState: () => dispatch(resetState()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Booth);
