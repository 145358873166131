import { PostApiWithAuthorizationAction } from "constantFunctions/apiActions";
import React, { useState } from "react";
import { toast } from "react-toastify";
import {
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button, Row, Col, FormGroup, Label, Input
} from "reactstrap";

function ViewReceiptChangeValue(props) {
    const renderAllItemsHTML = (items) => {
        return items.map((item, i) => {
            return (
              <tr key={i}>
                <td className="input-td">{item.qty}</td>
                <td className="input-td">
                    {(typeof item.item !== "undefined") ? item.item.name : item.itemDescription}
                </td>
                <td className="input-td">${item.unitPrice}</td>
                <td className="input-td">${item.price}</td>
              </tr>
            );
          });
    };

    const renderAttachments = (data) => {
      return  data.attachments.map((d, i) => {
        return (
          <>
            <img
              src={d.thumbnailUrl}
              style={{maxWidth: '150px', marginRight: '10px'}}
            />
          </>
        )
      });
    };

    const render = () => {
        let data = props.data;
        if (typeof data !== 'object') {
          data = JSON.parse(data);
        }
        let showColumns = [
          {
            col: 'ticketAmount',
            label: 'Ticket Amount',
            sign: '$'
          },
          {
            col: 'firstName',
            label: 'First Name'
          },
          {
            col: 'lastName',
            label: 'Last Name'
          },
          {
            col: 'paidBy',
            label: 'Paid By'
          },
          {
            col: 'tendered',
            label: 'Cash Tendered',
            sign: '$'
          },
          {
            col: 'clerk',
            label: 'Clerk'
          },
          {
            col: 'booth',
            label: 'Booth'
          },
          {
            col: 'station',
            label: 'Station'
          },
          {
            col: 'note',
            label: 'Note'
          }
        ];
        return (
          <>
            <Row style={{ fontWeight: 'bold'}}>
              {(
                Object.keys(data).map(function(key) {
                  let columnIndex = showColumns.findIndex(v=>v.col==key);
                  return (
                    <>
                      {(typeof data[key] !== "undefined" && columnIndex >= 0) && (
                        <Col md="6"><b>{showColumns[columnIndex].label}</b>:
                            <span style={{color: 'red'}}>
                                {showColumns[columnIndex].sign ? showColumns[columnIndex].sign : ""}{data[key]}
                            </span>
                        </Col>
                      )}
                    </>
                  );
                })
              )}
            </Row>
            {(typeof data.shipment !== 'undefined') && (
                <>
                <div
                    className="text-center"
                    style={{fontWeight: 'bold'}}><u>Shipment</u></div>
                <Table
                    className="add-receipt-item-table mt-2"
                    bordered
                    striped>
                    <thead>
                        <tr>
                            <th className="qty-col">Address</th>
                            <th className="desc-col">City</th>
                            <th className="unit-col">State</th>
                            <th className="unit-col">Phone</th>
                            <th className="amount-col">Postal Code</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="input-td">{data.shipment.address}</td>
                            <td className="input-td">{data.shipment.city}</td>
                            <td className="input-td">{data.shipment.state}</td>
                            <td className="input-td">{data.shipment.phone}</td>
                            <td className="input-td">{data.shipment.postalCode}</td>
                        </tr>
                    </tbody>
                </Table>
              </>
            )}
            <div
                className="text-center"
                style={{fontWeight: 'bold'}}>
                <u>Items</u>
            </div>
            <Table
                className="add-receipt-item-table mt-2"
                bordered
                striped>
              <thead>
                <tr>
                  <th className="qty-col">Qty</th>
                  <th className="desc-col">DESC</th>
                  <th className="unit-col">UNIT($)</th>
                  <th className="amount-col">Amount($)</th>
                </tr>
              </thead>
              <tbody>
                {renderAllItemsHTML(data.items)}
              </tbody>
            </Table>
            {(typeof data.attachments !== 'undefined')
                && data.attachments.length > 0
                && (
              <div style={{textAlign: 'center', marginTop: '5px'}}>
                <div>
                  <strong>Attachments:</strong>
                </div>
                {renderAttachments(data)}
              </div>
            )}
          </>
        );
    }
  return (
    <Modal isOpen={props.show} toggle={props.toggle} size="lg">
      <ModalHeader toggle={props.toggle}>View Changes</ModalHeader>
      <ModalBody className="py-0 pb-3">
        {render()}
      </ModalBody>
    </Modal>
  );
}

export default ViewReceiptChangeValue;

