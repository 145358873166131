import React, { useEffect, useState } from "react";
// reactstrap components
import {
  Card,
  Container,
  CardHeader,
  CardBody,
  Table,
  Row,
  Col,
  Button,
  CustomInput,
  Input,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import { connect } from "react-redux";
import Unauthorized from "views/Pages/Unauthorized";
import { toast } from "react-toastify";
import { resetState } from "../../redux/common/commonAction";
import Moment from "react-moment";
import { GetApiActionWithAuthorization } from "constantFunctions/apiActions";
import ReactHtmlParser from 'react-html-parser'; 
import ReactPaginate from "react-paginate";

function CoinReceipt(props) {
  const [unauthorized, setUnauthorized] = useState(false);
  const [data, setData] = useState({
    count: 0,
    page: 0,
    data: [],
    date: ""
  });

  useEffect(() => {
    load({
        page: 0
    })
  }, []);

  const load = (params) => {
    GetApiActionWithAuthorization("common/get-coin-receipt-deposits", params)
    .then((res) => {
        setData(res)
    })
    .catch((err) => {
      if (err.status === 403) {
        setUnauthorized(true);
      } else if (err.status === 440) {
        props.resetState();
        toast.error(err.message, { position: toast.POSITION.TOP_CENTER });
        props.history.push("/auth/login");
      }
    });
  };

  const renderTableData = (e) => {
    return data.data.map((_data, i) => {
      return (
        <tr key={i}>
          <td>
            <Moment
                format="MM/DD/YY"
                tz="America/Los_Angeles">
              {_data.depositDate.toString().length === 10
                ? _data.depositDate * 1000
                : _data.depositDate}
            </Moment>
          </td>
          <td>{ReactHtmlParser(_data.dates.replaceAll(',', '<br/>'))}</td>
          <td>{_data.comments ? ReactHtmlParser(_data.comments.replaceAll(',', '<br/>')) : ""}</td>
          <td>${_data.total}</td>
        </tr>
      );
    });
  };

  return unauthorized ? (
    <Unauthorized />
  ) : (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--9 main-content" fluid>
        <Card className="card-height shadow">
          <CardHeader>
            <div className="text-center">
              <h1>Coin Receipt Deposit</h1>
            </div>
          </CardHeader>
          <CardBody>
          <Row>
            <Col md={4} className="d-flex align-items-center mb-2">
                Deposit&nbsp;Date:&nbsp;
                <Input
                    type="date"
                    value={data.date}
                    onChange={(e) => {
                        setData({
                            ... data,
                            date: e.target.value
                        })
                        load({
                            page: 0,
                            date: e.target.value
                        })
                    }}
                />
            </Col>
            </Row>
            <Table
                className="ticket-table table-listing mb-5"
                responsive
                bordered>
              <thead
                className="thead-light">
                <tr>
                  <th scope="col">Dep Date</th>
                  <th scope="col">Days</th>
                  <th scope="col">Comments</th>
                  <th scope="col">Total</th>
                </tr>
              </thead>
              <tbody>{renderTableData()}</tbody>
            </Table>
            {data.data.length > 0 && (
                <ReactPaginate
                  previousLabel={<i className="fas fa-chevron-left"></i>}
                  nextLabel={<i className="fas fa-chevron-right"></i>}
                  breakLabel={".."}
                  breakClassName={"break-me"}
                  initialPage={data.page}
                  pageCount={data.count / 50}
                  marginPagesDisplayed={1}
                  pageRangeDisplayed={2}
                  onPageChange={(e) => {
                    if (data.page !== e.selected) {
                        load({
                            page: e.selected
                        })
                    }
                  }}
                  containerClassName={"pagination"}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"active"}
                />
              )}
          </CardBody>
        </Card>
      </Container>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    userType: state.loginUserReducer.userType
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    resetState: () => dispatch(resetState())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CoinReceipt);
