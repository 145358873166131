import { PostApiWithAuthorizationAction } from "constantFunctions/apiActions";
import { GetApiActionWithAuthorization } from "constantFunctions/apiActions";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Table,
  Input,
} from "reactstrap";

function EditDailyTransactionModal(props) {
  const [denominationData, setDenominationData] = useState({});
  const [totalCashAmount, setTotalCashAmount] = useState(0);
  useEffect(() => {
    GetApiActionWithAuthorization("common/get-particular-transaction-data-with-date", {
      date: props.date,
    }).then((res) => {
      let denominationData = res.data || {
        denom100: 0,
        denom50: 0,
        denom20: 0,
        denom10: 0,
        denom5: 0,
        denom2: 0,
        denom1: 0,
        comment: '',
        id: 0,
      }
      setDenominationData({
        denom100: denominationData.denom100 || 0,
        denom50: denominationData.denom50 || 0,
        denom20: denominationData.denom20 || 0,
        denom10: denominationData.denom10 || 0,
        denom5: denominationData.denom5 || 0,
        denom1: denominationData.denom1 || 0,
        denom2: denominationData.denom2 || 0,
        comment: denominationData.comment || '',
        id: denominationData.id || 0,
      });
      setTotalCashAmount(res.totalCashAmount)
    });
  }, []);

  const getTotal = (data) => {
    return data.denom100 * 100 +
    data.denom50 * 50 +
    data.denom20 * 20 +
    data.denom10 * 10 +
    data.denom5 * 5 +
    data.denom2 * 2 +
    data.denom1 * 1
  }

  const submitHandler = (e) => {
    let obj = {
      denom100: denominationData.denom100,
      denom50: denominationData.denom50,
      denom20: denominationData.denom20,
      denom10: denominationData.denom10,
      denom5: denominationData.denom5,
      denom2: denominationData.denom2,
      denom1: denominationData.denom1,
      comment: denominationData.comment,
      id: denominationData.id,
    };
    let denominationTotal = getTotal(denominationData)
    //let cashTransactionTotal = getTotal(transaction)
    if (denominationTotal == totalCashAmount) {
        PostApiWithAuthorizationAction("common/update-denomination-details", obj).then((res) => {
            props.toggle();
        });
    } else {
        toast.error("Denomination Total Count don't match Cash Total!", { position: toast.POSITION.TOP_CENTER });
    }
  };

  return (
    <Modal isOpen={props.show} toggle={props.toggle}>
      <ModalHeader toggle={props.toggle}>
        Edit Denomination
      </ModalHeader>
      <ModalBody>
        <Table className="transaction-table" striped bordered>
          <tbody>
          <tr>
              <td style={{ width: "100px" }}>Comment:</td>
              <td colSpan="2" className="comment">
                <Input
                  type="text"
                  placeholder="Comment"
                  value={denominationData.comment}
                  onChange={(e) => {
                    setDenominationData({
                      ...denominationData,
                      comment: e.target.value,
                    });
                  }}
                />
              </td>
            </tr>
            <tr>
              <td>$100</td>
              <td>
                <Input
                  type="number"
                  value={denominationData.denom100}
                  onChange={(e) => {
                    setDenominationData({
                      ...denominationData,
                      denom100: e.target.value,
                    });
                  }}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                />
              </td>
              <td> ${100 * denominationData.denom100}</td>
            </tr>
            {<tr>
              <td>$50</td>
              <td>
                <Input
                  type="number"
                  value={denominationData.denom50}
                  onChange={(e) => {
                    setDenominationData({
                      ...denominationData,
                      denom50: e.target.value,
                    });
                  }}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                />
              </td>
              <td>${50 * denominationData.denom50} </td>
                </tr>}
            <tr>
              <td>$20</td>
              <td>
                <Input
                  type="number"
                  value={denominationData.denom20}
                  onChange={(e) => {
                    setDenominationData({
                      ...denominationData,
                      denom20: e.target.value,
                    });
                  }}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                />
              </td>
              <td>${20 * denominationData.denom20} </td>
            </tr>
            <tr>
              <td>$10</td>
              <td>
                <Input
                  type="number"
                  value={denominationData.denom10}
                  onChange={(e) => {
                    setDenominationData({
                      ...denominationData,
                      denom10: e.target.value,
                    });
                  }}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                />
              </td>
              <td>${10 * denominationData.denom10} </td>
            </tr>
            <tr>
              <td>$5</td>
              <td>
                <Input
                  type="number"
                  value={denominationData.denom5}
                  onChange={(e) => {
                    setDenominationData({
                      ...denominationData,
                      denom5: e.target.value,
                    });
                  }}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                />
              </td>
              <td> ${5 * denominationData.denom5} </td>
            </tr>
            <tr>
              <td>$2</td>
              <td>
                <Input
                  type="number"
                  value={denominationData.denom2}
                  onChange={(e) => {
                    setDenominationData({
                      ...denominationData,
                      denom2: e.target.value,
                    });
                  }}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                />
              </td>
              <td> ${2 * denominationData.denom2}</td>
            </tr>
            <tr>
              <td>$1</td>
              <td>
                <Input
                  type="number"
                  value={denominationData.denom1}
                  onChange={(e) => {
                    setDenominationData({
                      ...denominationData,
                      denom1: e.target.value,
                    });
                  }}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                />
              </td>
              <td> ${1 * denominationData.denom1}</td>
            </tr>
            <tr>
              <td colSpan="2">Denomination Total:</td>
              <td>
                $
                {getTotal(denominationData)}{" "}
              </td>
            </tr>
            <tr>
              <td colSpan="2">Cash Total:</td>
              <td>
                $
                {totalCashAmount}{" "}
              </td>
            </tr>
          </tbody>
        </Table>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={(e) => submitHandler()}>
          Save
        </Button>{" "}
        <Button color="secondary" onClick={(e) => props.toggle()}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default EditDailyTransactionModal;
