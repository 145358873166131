import { PostApiWithAuthorizationAction } from "constantFunctions/apiActions";
import React, { useState } from "react";
import { toast } from "react-toastify";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button, Row, Col, FormGroup, Label, Input
} from "reactstrap";

function EditTicketNote(props) {
    const [note, setNote] = useState(props.receipt.note);
    const formSubmitHandler = () => {
        if (note.length == 0) {
            toast.error('Note field is required.', {
                position: toast.POSITION.TOP_CENTER,
                closeOnClick: true,
            });
        } else {
            PostApiWithAuthorizationAction("jewelry-receipts/edit-receipt-note", {
                id: props.receipt.id,
                formDetails: { note }
            })
            .then((res) => {
                props.toggle()
            })
            .catch((err) => {
                toast.error(err.message, { position: toast.POSITION.TOP_CENTER });
            });
        }
    }
  return (
    <Modal
        isOpen={props.show}
        toggle={props.toggle}
        autoFocus={false}>
      <ModalHeader toggle={props.toggle}>Save Note</ModalHeader>
      <ModalBody className="py-0 pb-3">
        <Input
            id="exampleText"
            name="text"
            type="textarea"
            value={note}
            onChange={(e) => setNote(e.target.value)}
            autoFocus
        />
      </ModalBody>
      <ModalFooter className="pt-0">
        <Button id="print" color="primary" onClick={formSubmitHandler}>
          Save Changes
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default EditTicketNote;
