import React, { useEffect, useState } from "react";
// reactstrap components
import {
  Card,
  Container,
  CardHeader,
  CardBody,
  Table,
  Row,
  Col,
  Button
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Unauthorized from "views/Pages/Unauthorized";
import AddVoidReasonModal from "views/Modal/VoidLogger/AddVoidReasonModal";
import EditVoidReasonModal from "views/Modal/VoidLogger/EditVoidReasonModal";
import { toast } from "react-toastify";
import {
    GetApiActionWithAuthorization,
    PostApiWithAuthorizationAction
} from "../../constantFunctions/apiActions";
import ConfirmationDeleteModal from "views/Modal/ConfirmationDeleteModal";

function VoidReason(props) {
  const [unauthorized, setUnauthorized] = useState(false);
  const [addModal, setAddModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [editData, setEditData] = useState({});
  const [confirm, setConfirm] = useState(false);
  const [delId, setDelId] = useState("");
  const [voidReasons, setVoidReasons] = useState({
    data: []
  });

  useEffect(() => {
    fetchAllVoidReasons();
  }, []);

  if (voidReasons.error && !unauthorized) {
    if (voidReasons.error.status === 403) {
      setUnauthorized(true);
    } else if (voidReasons.error.status === 440) {
      toast.error(voidReasons.error.message, {
        position: toast.POSITION.TOP_CENTER,
      });
      props.history.push("/auth/login");
    }
  }

  const toggleAdd = (e) => {
    setAddModal(!addModal);
  };

  const toggleEdit = (id = "", reason = "") => {
    setEditData({ id, reason });
    setEditModal(!editModal);
  };

  const toggleConfirm = (id = "") => {
    setDelId(id);
    setConfirm(!confirm);
  };

  const confirmed = (e) => {
    PostApiWithAuthorizationAction("common/delete-void-reason/" + delId)
      .then((res) => {
        fetchAllVoidReasons();
        setConfirm(false);
      })
      .catch((err) => {
        console.log(err);
        if (err.status === 403) {
          setUnauthorized(true);
        } else if (err.status === 440) {
          props.resetState();
          toast.error(err.message, { position: toast.POSITION.TOP_CENTER });
          props.history.push("/auth/login");
        }
      });
  };

  const fetchAllVoidReasons = () => {
    GetApiActionWithAuthorization("common/get-all-void-reasons").then(res =>{
        setVoidReasons(res);
    })
    .catch((err) => {
        if (err.status === 403) {
            setUnauthorized(true);
        } else if (err.status === 440) {
            props.resetState();
            toast.error(err.message, { position: toast.POSITION.TOP_CENTER });
            props.history.push("/auth/login");
        }
    });
  };

  const renderTableData = (e) => {
    return voidReasons.data.map((item, i) => {
      return (
        <tr key={i}>
          <td>{item.reason}</td>
          <td>
            <span className="mr-1">
                [<Link
                    href="#"
                    id={"update-reason"+i}
                    className="update-item"
                    onClick={(e) => {
                        toggleEdit(item.id, item.reason);
                    }}
                >
                Edit
                </Link>]
            </span>
            [<Link href="#" onClick={(e) => toggleConfirm(item.id)}>
                Delete
            </Link>]
          </td>
        </tr>
      );
    });
  };

  return unauthorized ? (
    <Unauthorized />
  ) : (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--9 main-content" fluid>
        <Card className="card-height shadow">
          <CardHeader className="bg-white d-flex justify-content-between">
            <h1>Void Reasons</h1>
            <Button
              type="button"
              color="primary"
              onClick={() => {
                props.history.goBack();
              }}
            >
              <i className="fas fa-chevron-left"></i> Back
            </Button>
          </CardHeader>
          <CardBody>
            <Row className="mb-3">
              <Col md="6" className="search-left-btn">
                <Button id="add-new" color="primary" onClick={toggleAdd}>
                  Add New+
                </Button>
              </Col>
            </Row>
            <Table className="ticket-table table-listing" responsive bordered>
              <thead className="thead-light">
                <tr>
                  <th scope="col">Reasons</th>
                  <th scope="col">Actions</th>
                </tr>
              </thead>
              <tbody>{renderTableData()}</tbody>
            </Table>
          </CardBody>
        </Card>
      </Container>
      {addModal && (
        <AddVoidReasonModal
            {...props}
            show={addModal}
            toggle={toggleAdd}
            fetchAllVoidReasons={fetchAllVoidReasons}/>
      )}
      {editModal && (
        <EditVoidReasonModal
          {...props}
          show={editModal}
          editData={editData}
          toggle={toggleEdit}
          fetchAllVoidReasons={fetchAllVoidReasons}
        />
      )}
      {confirm && (
        <ConfirmationDeleteModal
          show={confirm}
          toggle={toggleConfirm}
          confirmed={confirmed}
        />
      )}
    </>
  );
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(VoidReason);
