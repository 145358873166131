import React, { useEffect, useState } from "react";
// reactstrap components
import {
  Card,
  Container,
  CardHeader,
  CardBody,
  Table,
  Row,
  Col,
  Button,
} from "reactstrap";
import Header from "components/Headers/Header.js";
import Unauthorized from "views/Pages/Unauthorized";
import { PostApiWithAuthorizationAction } from "../../constantFunctions/apiActions";
import queryString from 'query-string';

function TransactionWorksheet(props) {
    const [unauthorized, setUnauthorized] = useState(false);
    const params = queryString.parse(props.location.search);
    const [data, setData] = useState({
        transactionOptions: [],
        dateStart: params.dateStart,
        dateEnd: params.dateEnd,
        checks: {
            "Coin Drawer": 0,
            "Metro Drawer": 0
        }
    });
    //
    useEffect(() => {
        PostApiWithAuthorizationAction("transactions/get-worksheet-data", params).then((res) => {
            setData(res.data)
        });
    }, []);

    const renderWorksheetData = () => {
        return data.transactionOptions.map((_data, i) => {
            let additions = Object.fromEntries(Object.entries(_data.worksheet.additions));
            let subtractions = Object.fromEntries(Object.entries(_data.worksheet.subtractions));
            return (
                <Table className="table-listing table-striped table-bordered table-worksheet mb-4" responsive bordered id={"table-" + i}>
                    <tbody>
                        <tr>
                            <td>{_data.worksheet.friendlyName}</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>Date:</td>
                            <td>{data.dateStart} to {data.dateEnd}</td>
                        </tr>
                        <tr>
                            <td><strong></strong></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td><strong>Opening Balance</strong></td>
                            <td>${_data.worksheet.openingBalance}</td>
                        </tr>
                        <tr>
                            <td><strong></strong></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td><strong>Additions</strong></td>
                            <td></td>
                        </tr>
                        {
                            Object.keys(additions).map(function(key, index) {
                                return (
                                    <>
                                        <tr>
                                            <td>{data.transactionOptionsOnly[key]}</td>
                                            <td></td>
                                        </tr>
                                        {Object.keys(_data.worksheet.dailyAdditions[key]).map(function(date, index) {
                                            return (
                                                <tr>
                                                    <td>{date}</td>
                                                    <td>${_data.worksheet.dailyAdditions[key][date]}</td>
                                                </tr>
                                            );
                                        })}
                                        <tr>
                                            <td>{data.transactionOptionsOnly[key]} Total:</td>
                                            <td>${Object.keys(_data.worksheet.dailyAdditions[key]).map((date) => _data.worksheet.dailyAdditions[key][date]).reduce((prev, curr) => prev + curr, 0)}</td>
                                        </tr>
                                        <tr><td></td><td></td></tr>
                                    </>
                                )
                            })
                        }
                        <tr>
                            <td>Total Additions:</td>
                            <td>${Object.keys(additions).map((key) => additions[key]).reduce((prev, curr) => prev + curr, 0)}</td>
                        </tr>
                        <tr>
                            <td><strong></strong></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td><strong>Subtractions</strong></td>
                            <td></td>
                        </tr>
                        {
                            Object.keys(subtractions).map(function(key, index) {
                                return (
                                    <>
                                        <tr>
                                            <td>{data.transactionOptionsOnly[key]}</td>
                                            <td></td>
                                        </tr>
                                        {Object.keys(_data.worksheet.dailySubtractions[key]).map(function(date, index) {
                                            return (
                                                <tr>
                                                    <td>{date}</td>
                                                    <td>${_data.worksheet.dailySubtractions[key][date]}</td>
                                                </tr>
                                            );
                                        })}
                                        <tr>
                                            <td>{data.transactionOptionsOnly[key]} Total:</td>
                                            <td>${Object.keys(_data.worksheet.dailySubtractions[key]).map((date) => _data.worksheet.dailySubtractions[key][date]).reduce((prev, curr) => prev + curr, 0)}</td>
                                        </tr>
                                        <tr><td></td><td></td></tr>
                                    </>
                                )
                            })
                        }
                        <tr>
                            <td>Total Subtractions:</td>
                            <td>${Object.keys(subtractions).map((key) => subtractions[key]).reduce((prev, curr) => prev + curr, 0)}</td>
                        </tr>
                        <tr>
                            <td><strong></strong></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td><strong>Ending Balance:</strong></td>
                            <td>${_data.worksheet.endingBalance}</td>
                        </tr>
                    </tbody>
                </Table>
            )
        });
    };

    return unauthorized ? (
        <Unauthorized />
      ) : (
        <>
          <Header />
          {/* Page content */}
          <Container className="mt--9 main-content" fluid>
            <Card className="card-height shadow">
              <CardHeader className="bg-white d-flex justify-content-between">
                <h1>Transactions</h1>
                <Button
                type="button"
                color="primary"
                onClick={() => {
                    props.history.goBack();
                }}
                >
                <i className="fas fa-chevron-left"></i> Back
                </Button>
              </CardHeader>
              <CardBody>
                  <div style={{fontSize: '18px'}} className="px-lg-5">
                    {renderWorksheetData()}
                  </div>
              </CardBody>
            </Card>
          </Container>
        </>
    );

}

export default TransactionWorksheet;
