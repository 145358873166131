
import React from "react";
// reactstrap components
import {
  Button,
  Container,
} from "reactstrap";

class CustomerHome extends React.Component {
  constructor(props){
    super(props);
  };
  render() {
    return (
      <>
        {/* Page content */}
        <Container className="main-content text-center" fluid>
          <div className="customer-content">
            <div style={{fontSize: '80px', fontWeight: 'bold'}}>METRO&nbsp;CUSTOMER</div><br/>
            <div className="" >
              <a href="javascript:;"
              style={{
                fontSize: '20px',
                fontWeight: 'bold'
              }} 
              onClick={() => {
                localStorage.clear(); 
                window.location.href = '/auth/login';
              }}>LOGOUT</a>
            </div>
          </div>
        </Container>
      </>
    );
  }
}

export default CustomerHome;
