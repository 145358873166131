import React, { useEffect, useState } from "react";

// reactstrap components
import {
    Card,
    Container,
    CardHeader,
    CardBody,
    Table,
    Row,
    Col,
    Button,
    CustomInput,
    Form,
    Input,
    FormGroup,
    Label
  } from "reactstrap";

import { DropdownList } from "react-widgets";

// core components
import Header from "components/Headers/Header.js";
import { connect } from "react-redux";
import Unauthorized from "views/Pages/Unauthorized";
import Moment from "react-moment";
import moment from "moment-timezone";

import { GetApiActionWithAuthorization } from "constantFunctions/apiActions";
import { PostApiWithAuthorizationAction } from "constantFunctions/apiActions";

import { Link } from "react-router-dom";
import { resetState } from "../../redux/common/commonAction";
import ReactPaginate from "react-paginate";

import {
    fetchInvoicesListing,
    fetchInvoiceListingSuccess,
    updateInvoiceTableAfterUpdate
} from "../../redux/Invoices/InvoicesAction";

import { toast } from "react-toastify";
import ViewInvoicePrint from "views/Modal/ViewInvoicePrint";

function Invoice(props) {
    const [unauthorized, setUnauthorized] = useState(false);
    const [search, setSearch] = useState("");
    const [filterBy, setFilterBy] = useState("Invoice");
    const [changeInterval, setChangeInterval] = useState(false);
    const [todayOnly, setTodayOnly] = useState(false);
    const [sortId, setSortId] = useState("creationDate");
    const [sortOrder, setSortOrder] = useState("-1");
    const [dateFilter, setDateFilter] = useState("");
    const [printUrl, setPrintUrl] = useState("");
    const [viewPrintModal, setViewPrintModal] = useState(false);

    useEffect(() => {
        props.fetchInvoicesListing({
            page: 0,
            todayOnly
        });
    }, []);

    if (props.invoices.error && !unauthorized) {
        if (props.invoices.error.status === 403) {
          setUnauthorized(true);
        } else if (props.invoices.error.status === 440) {
          props.resetState();
          toast.error(props.invoices.error.message, {
            position: toast.POSITION.TOP_CENTER,
          });
          props.history.push("/auth/login");
        }
    }

    const deleteItem = (id) => {
        if (window.confirm('WARNING: YOU CANNOT UNDO THIS DELETE (YOU WILL LOSE ALL DATA)  ARE SURE YOU WANT TO DELETE THIS INVOICE?')) {
            PostApiWithAuthorizationAction( "invoices/delete-item/" + id).then((res) => {
                props.fetchInvoicesListing({
                    page: 0,
                    todayOnly
                });
            }).catch((err) => {
                if (err.status === 403) {
                    setUnauthorized(true);
                } else if (err.status === 440) {
                    props.resetState();
                    toast.error(err.message, { position: toast.POSITION.TOP_CENTER });
                    props.history.push("/auth/login");
                }
            });
        }
    };
    
  //CheckBox change handler
  const checkboxHandler = (e, id, key) => {
    props.invoices.allInvoices[key].pickedUp = true;
    let body = {
      id: id,
      pickedUp: document.getElementById("isPickedUp-" + id).checked ? 1 : 0,
      page: props.invoices.page,
      sortBy: sortId,
      sortOrder: sortOrder,
      search: search.trim(),
      filterByItem: filterBy === "Ticket" ? 0 : 1,
      todayOnly,
      dateFilter
    };
    PostApiWithAuthorizationAction(
      "invoices/edit-invoice-render-table-data",
      body
    ).then((res) => {
        props.updateInvoiceTableAfterUpdate(res.data);
    }).catch((err) => {
        if (err.status === 403) {
            setUnauthorized(true);
        } else if (err.status === 440) {
            props.resetState();
            toast.error(err.message, { position: toast.POSITION.TOP_CENTER });
            props.history.push("/auth/login");
        }
    });
  };
  const togglePrintView = () => {
    setViewPrintModal(!viewPrintModal);
  };
    const renderInvoicesTableData = (e) => {
        let lastInvoice = props.invoices.allInvoices[0];
        return props.invoices.allInvoices.map((invoice, i) => {
            return (
                <tr key={i}>
                    <td>{invoice.id}</td>
                    <td>
                        <Moment format="MM/DD/YY HH:mm" tz="America/Los_Angeles">
                        {invoice.creationDate.toString().length === 10
                            ? new Date(invoice.creationDate * 1000)
                            : new Date(invoice.creationDate)}
                        </Moment>
                    </td>
                    <td>
                        <Moment format="MM/DD/YY HH:mm" tz="America/Los_Angeles">
                        {invoice.deliveryDate.toString().length === 10
                            ? new Date(invoice.deliveryDate * 1000)
                            : new Date(invoice.deliveryDate)}
                        </Moment>
                    </td>
                    <td>{invoice.billedTo}</td>
                    <td>{parseInt(invoice.total)}</td>
                    <td>
                        <input
                            id={"isPickedUp-"+invoice.id}
                            type="checkbox"
                            checked={props.invoices.allInvoices[i].pickedUp}
                            onChange={(e) => checkboxHandler(e, invoice.id, i)}
                            />
                        <small className="ml-2"></small>
                    </td>
                    <td>
                        [<Link id={'edit-invoice-'+invoice.id} to={'/portal/invoices/edit/' + invoice.id }>Edit</Link>]
                        [<Link id={'edit-invoice-'+invoice.id} to={'/invoices/v/' + invoice.id } target="_blank">View</Link>]
                        [<Link id={'edit-invoice-'+invoice.id} to={'/invoices/v/' + invoice.id + '?interactive=true'}>Checkout</Link>]
                        {(props.userType === "admin") && (moment(invoice.creationDate*1000).isSame(moment(), 'day')) && (
                            <>
                            &nbsp;[<Link 
                                id={'delete-invoice-'+invoice.id}
                                onClick={(e) => {
                                e.preventDefault();
                                deleteItem(invoice.id);
                            }}>Delete</Link>]
                            </>
                        )}&nbsp;
                        {(props.userType === "admin") && (
                            <>
                            [<a
                                id={'print-invoice-'+invoice.id}
                                href='javascript:;'
                                onClick={(e) => {
                                    e.preventDefault();
                                    setPrintUrl(process.env.REACT_APP_printUrl + "label/invoice/" + invoice.id);
                                    setViewPrintModal(true);
                                }}>Print</a>
                            ]
                            </>
                        )}
                    </td>
                </tr>
            )
        });
    };
    return unauthorized ? (
        <Unauthorized />
      ) : (
        <>
          <Header />
          {/* Page content */}
          <Container className="mt--9 main-content" fluid>
            <Card className="card-height shadow">
            <CardHeader>
                <div className="text-center">
                <h1>Invoices</h1>
                </div>
            </CardHeader>
            <CardBody className="page-main-content">
                <Row className="align-items-center search-container mb-2">
                    <Col md="7" className="search-left-btn">
                        <Link id="category" to="/portal/invoices/create-new">
                            <Button color="primary">New Invoice</Button>
                        </Link>
                    </Col>
                    <Col md="5" lg="6" xl="5">
                        <Form
                        className="d-flex"
                        onSubmit={(e) => {
                            e.preventDefault();
                            setChangeInterval(!changeInterval);
                            setTodayOnly(false);
                            props.fetchInvoicesListing({
                                page: 0,
                                search: search.trim(),
                                sortId,
                                sortOrder,
                                filterBy,
                                todayOnly: e.target.checked,
                                dateFilter
                            });
                        }}
                        >
                        <Input
                            type="text"
                            className="mr-2"
                            placeholder={
                                filterBy === "Invoice"
                                    ? "Search by Invoice id, amount, billed To, Check Number."
                                    : "Search by Ticket ID, Amount."
                            }
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                        />
                        <DropdownList
                            data={["Invoice", "Ticket"]}
                            containerClassName="filter-By"
                            value={filterBy}
                            placeholder="Select"
                            onChange={(e) => setFilterBy(e)}
                        />
                        <Button type="submit" className="mx-2" color="primary">Search</Button>
                        </Form>
                    </Col>
                </Row>
                <FormGroup className="my-2 form-control-label" check>
                  <Label className="form-control-label" check>
                    <Input
                      type="checkbox"
                      id="todayOnly"
                      checked={todayOnly}
                      onChange={(e) => {
                        setTodayOnly(e.target.checked);
                        props.fetchInvoicesListing({
                            page: props.invoices.page,
                            search,
                            sortId,
                            sortOrder,
                            filterBy,
                            todayOnly: e.target.checked,
                            dateFilter
                        });
                      }}
                    />{" "}
                    Today Only
                  </Label>
                </FormGroup>
                <Table className="ticket-table table-listing" responsive bordered>
                    <thead className="thead-light">
                        <tr>
                            <th scope="col">Invoice #</th>
                            <th scope="col">Date</th>
                            <th scope="col">Delivery Date</th>
                            <th scope="col">Billed To</th>
                            <th scope="col">Total</th>
                            <th scope="col">Picked Up?</th>
                            <th scope="col">Action</th>
                        </tr>
                    </thead>
                    <tbody>{renderInvoicesTableData()}</tbody>
                </Table>
                {props.invoices.allInvoices.length > 0 &&
                    props.userType === "admin" && (
                <ReactPaginate
                    previousLabel={<i className="fas fa-chevron-left"></i>}
                    nextLabel={<i className="fas fa-chevron-right"></i>}
                    breakLabel={".."}
                    breakClassName={"break-me"}
                    initialPage={props.invoices.page}
                    pageCount={props.invoices.count / 50}
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={2}
                    onPageChange={(e) => {
                        //run only when page changes
                        if (props.invoices.page !== e.selected) {
                            setChangeInterval(!changeInterval);
                            props.fetchInvoicesListing({
                                page: e.selected,
                                search,
                                sortId,
                                sortOrder,
                                filterBy,
                                todayOnly,
                                dateFilter
                            });
                        }
                    }}
                    containerClassName={"pagination"}
                    subContainerClassName={"pages pagination"}
                    activeClassName={"active"}
                />
              )}
            </CardBody>
            </Card>
           </Container>
           {viewPrintModal && (
                <ViewInvoicePrint
                    show={viewPrintModal}
                    toggle={togglePrintView}
                    invoiceUrl={printUrl}
                    />
            )}
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        invoices: state.invoicesReducer.invoices,
        userType: state.loginUserReducer.userType
    };
  };
  
const mapDispatchToProps = (dispatch) => {
    return {
        fetchInvoicesListing: (params) => dispatch(fetchInvoicesListing(params)),
        updateInvoiceTableAfterUpdate: (invoices) => dispatch(updateInvoiceTableAfterUpdate(invoices)),
        resetState: () => dispatch(resetState())
    };
};
  
export default connect(mapStateToProps, mapDispatchToProps)(Invoice);